/* stylelint-disable */
:root {
  --flix-primary-brand-color: #73d700;
  --flix-secondary-brand-color: #ffad00;
  --flix-ui-primary-color: #31a100;
  --flix-ui-primary-light-color: #5cc500;
  --flix-ui-primary-dark-color: #187d00;
  --flix-grayscale-0-color: #fff;
  --flix-grayscale-10-color: #f7f7f7;
  --flix-grayscale-30-color: #e1e1e1;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #646464;
  --flix-grayscale-90-color: #444;
  --flix-grayscale-100-color: #353535;
  --flix-neutral-color: #016ae7;
  --flix-success-color: #228f00;
  --flix-success-dark-color: #136b00;
  --flix-warning-color: #ff5704;
  --flix-warning-dark-color: #c42d00;
  --flix-danger-color: #dd2828;
  --flix-danger-dark-color: #b31414;
  --flix-bg-primary-color: #fff;
  --flix-bg-primary-transparent-color: rgba(255, 255, 255, 0.88);
  --flix-bg-secondary-color: #f7f7f7;
  --flix-box-bg-color: #fff;
  --flix-highlight-color: #e5f9c0;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #353535;
  --flix-content-secondary-color: #646464;
  --flix-heading-font-color: #353535;
  --flix-link-color: #0047de;
  --flix-line-primary-color: #c8c8c8;
  --flix-icon-primary-color: #8b8b8b;
  --flix-icon-secondary-color: #31a100;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #ffcb46;
  --flix-button-secondary-color: #a2e53f;
  --flix-button-label-color: #353535;
  --flix-header-bg-color: #a2e53f;
  --flix-header-color: #353535;
  --flix-header-nav-bg-color-tablet: #73d700;
  --flix-input-border-color: #8b8b8b;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-input-border-radius: 6px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.9rem;
  --flix-font-size-fineprint: 0.8rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-font-size-button: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.1rem;
  --flix-line-height-fineprint: 0.9rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-icon-arrow-down: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' fill='%238b8b8b' /%3E%3C/svg%3E");
  --flix-icon-arrow-left: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 17.27l-5.28-5.28 5.27-5.26a1 1 0 000-1.41 1 1 0 00-1.41 0l-5.99 5.97a1 1 0 000 1.41l6 5.98a1 1 0 001.42 0 1 1 0 000-1.41z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-arrow-right: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-attention-solid: url("data:image/svg+xml,%3Csvg fill='%23dd2828' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-icon-calendar: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M19 5h-1V4a1 1 0 00-2 0v1H8V4a1 1 0 00-2 0v1H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V8a3 3 0 00-3-3zm1 13c0 .54-.46 1-1 1H5c-.54 0-1-.46-1-1v-7h16v7zM4 9V8c0-.54.46-1 1-1h14c.54 0 1 .46 1 1v1H4zm2 4.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm-10 3a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-checkbox-indeterminate: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,11H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z' style='fill:%2331a100'/%3E%3C/svg%3E");
  --flix-icon-checkmark: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21.7 6.2l-13 13a1 1 0 01-1.4 0l-5-5a1 1 0 111.4-1.4L8 17.08l12.3-12.3a1 1 0 111.4 1.42z' style='fill:%2331a100' /%3E%3C/svg%3E");
  --flix-icon-checkmark-solid: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%23228f00' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z'/%3E%3C/svg%3E");
  --flix-icon-close: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-close-inverted: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-close-white: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-danger: url("data:image/svg+xml,%3Csvg fill='%23dd2828' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm4.95-3.05a1 1 0 01-1.41 0L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54a1 1 0 010 1.41z'/%3E%3C/svg%3E");
  --flix-icon-danger-solid: url("data:image/svg+xml,%3Csvg fill='%23dd2828' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' class='flix-icon crossed-solid'%3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.95 13.54a1 1 0 11-1.41 1.41L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54z'/%3E%3C/svg%3E");
  --flix-icon-header-arrow-down: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' fill='%23353535' /%3E%3C/svg%3E");
  --flix-icon-header-burger: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M2 5a1 1 0 011-1h18a1 1 0 010 2H3a1 1 0 01-1-1zm19 6H3a1 1 0 000 2h18a1 1 0 000-2zm0 7H3a1 1 0 000 2h18a1 1 0 000-2z' fill='%23353535'/%3E%3C/svg%3E");
  --flix-icon-info: url("data:image/svg+xml,%3Csvg fill='%23016ae7' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm1-8.5V17a1 1 0 01-2 0v-5.5a1 1 0 112 0zm.5-4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'/%3E%3C/svg%3E");
  --flix-icon-info-solid: url("data:image/svg+xml,%3Csvg fill='%23016ae7' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm1 15a1 1 0 01-2 0v-5.5a1 1 0 112 0V17zm-1-8a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-icon-minus: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11H3a1 1 0 000 2h18a1 1 0 000-2z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-radio-checkmark: url("data:image/svg+xml,%3Csvg fill='%2331a100' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z'/%3E%3C/svg%3E");
  --flix-icon-plus: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%238b8b8b' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11h-8V3a1 1 0 00-2 0v8H3a1 1 0 000 2h8v8a1 1 0 002 0v-8h8a1 1 0 000-2z'/%3E%3C/svg%3E");
  --flix-icon-success: url("data:image/svg+xml,%3Csvg fill='%23228f00' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M16.89 8.3a1 1 0 010 1.4l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 011.4 0zM22 12a10 10 0 11-20 0 10 10 0 0120 0zm-2 0a8 8 0 00-16 0 8 8 0 0016 0z'/%3E%3C/svg%3E");
  --flix-icon-success-solid: url("data:image/svg+xml,%3Csvg fill='%23228f00' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z'/%3E%3C/svg%3E");
  --flix-icon-time: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm3.2-6.2a1 1 0 11-1.4 1.4l-2.5-2.5a1 1 0 01-.3-.7V7a1 1 0 012 0v4.59l2.2 2.2z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-warning: url("data:image/svg+xml,%3Csvg fill='%23ff5704' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm-1-7.5V7a1 1 0 012 0v5.5a1 1 0 11-2 0zm2.5 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'/%3E%3C/svg%3E");
  --flix-icon-warning-solid: url("data:image/svg+xml,%3Csvg fill='%23ff5704' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 400;
  --flix-font-weight-bold: 700;
}

:root {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
}

/* stylelint-enable */
/* stylelint-disable */
.flix-theme-dark {
  --flix-primary-brand-color: #73d700;
  --flix-secondary-brand-color: #ffad00;
  --flix-ui-primary-color: #73d700;
  --flix-ui-primary-light-color: #44b300;
  --flix-ui-primary-dark-color: #cef38d;
  --flix-grayscale-0-color: #252525;
  --flix-grayscale-10-color: #353535;
  --flix-grayscale-30-color: #444;
  --flix-grayscale-50-color: #646464;
  --flix-grayscale-70-color: #8b8b8b;
  --flix-grayscale-90-color: #c8c8c8;
  --flix-grayscale-100-color: #fff;
  --flix-neutral-color: #039fd5;
  --flix-success-color: #44b300;
  --flix-success-dark-color: #cef38d;
  --flix-warning-color: #ff8206;
  --flix-warning-dark-color: #ffe393;
  --flix-danger-color: #fd5a5a;
  --flix-danger-dark-color: #ffc9ce;
  --flix-bg-primary-color: #252525;
  --flix-bg-primary-transparent-color: rgba(53, 53, 53, 0.88);
  --flix-bg-secondary-color: #202020;
  --flix-box-bg-color: #353535;
  --flix-highlight-color: #136b00;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #fff;
  --flix-content-secondary-color: #c8c8c8;
  --flix-heading-font-color: #fff;
  --flix-link-color: #3bd0de;
  --flix-line-primary-color: #646464;
  --flix-icon-primary-color: #fff;
  --flix-icon-secondary-color: #fff;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #ffcb46;
  --flix-button-secondary-color: #a2e53f;
  --flix-button-label-color: #353535;
  --flix-header-bg-color: #0f5900;
  --flix-header-color: #fff;
  --flix-header-nav-bg-color-tablet: #0b4000;
  --flix-input-border-color: #c8c8c8;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-input-border-radius: 6px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.9rem;
  --flix-font-size-fineprint: 0.8rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-font-size-button: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.1rem;
  --flix-line-height-fineprint: 0.9rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-icon-arrow-down: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' fill='%23ffffff' /%3E%3C/svg%3E");
  --flix-icon-arrow-left: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 17.27l-5.28-5.28 5.27-5.26a1 1 0 000-1.41 1 1 0 00-1.41 0l-5.99 5.97a1 1 0 000 1.41l6 5.98a1 1 0 001.42 0 1 1 0 000-1.41z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-arrow-right: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-attention-solid: url("data:image/svg+xml,%3Csvg fill='%23ff8187' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-icon-calendar: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M19 5h-1V4a1 1 0 00-2 0v1H8V4a1 1 0 00-2 0v1H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V8a3 3 0 00-3-3zm1 13c0 .54-.46 1-1 1H5c-.54 0-1-.46-1-1v-7h16v7zM4 9V8c0-.54.46-1 1-1h14c.54 0 1 .46 1 1v1H4zm2 4.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm-10 3a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-checkbox-indeterminate: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,11H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z' style='fill:%2373d700'/%3E%3C/svg%3E");
  --flix-icon-checkmark: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21.7 6.2l-13 13a1 1 0 01-1.4 0l-5-5a1 1 0 111.4-1.4L8 17.08l12.3-12.3a1 1 0 111.4 1.42z' style='fill:%2373d700' /%3E%3C/svg%3E");
  --flix-icon-checkmark-solid: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%23a2e53f' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z'/%3E%3C/svg%3E");
  --flix-icon-close: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-close-inverted: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-close-white: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-danger: url("data:image/svg+xml,%3Csvg fill='%23ff8187' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm4.95-3.05a1 1 0 01-1.41 0L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54a1 1 0 010 1.41z'/%3E%3C/svg%3E");
  --flix-icon-danger-solid: url("data:image/svg+xml,%3Csvg fill='%23ff8187' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' class='flix-icon crossed-solid'%3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.95 13.54a1 1 0 11-1.41 1.41L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54z'/%3E%3C/svg%3E");
  --flix-icon-header-arrow-down: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' fill='%23ffffff' /%3E%3C/svg%3E");
  --flix-icon-header-burger: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M2 5a1 1 0 011-1h18a1 1 0 010 2H3a1 1 0 01-1-1zm19 6H3a1 1 0 000 2h18a1 1 0 000-2zm0 7H3a1 1 0 000 2h18a1 1 0 000-2z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-info: url("data:image/svg+xml,%3Csvg fill='%236ae2e6' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm1-8.5V17a1 1 0 01-2 0v-5.5a1 1 0 112 0zm.5-4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'/%3E%3C/svg%3E");
  --flix-icon-info-solid: url("data:image/svg+xml,%3Csvg fill='%236ae2e6' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm1 15a1 1 0 01-2 0v-5.5a1 1 0 112 0V17zm-1-8a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-icon-minus: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11H3a1 1 0 000 2h18a1 1 0 000-2z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-radio-checkmark: url("data:image/svg+xml,%3Csvg fill='%2373d700' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' class='flix-icon circle-solid'%3E%3Cpath d='M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z'/%3E%3C/svg%3E");
  --flix-icon-plus: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11h-8V3a1 1 0 00-2 0v8H3a1 1 0 000 2h8v8a1 1 0 002 0v-8h8a1 1 0 000-2z'/%3E%3C/svg%3E");
  --flix-icon-success: url("data:image/svg+xml,%3Csvg fill='%23a2e53f' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M16.89 8.3a1 1 0 010 1.4l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 011.4 0zM22 12a10 10 0 11-20 0 10 10 0 0120 0zm-2 0a8 8 0 00-16 0 8 8 0 0016 0z'/%3E%3C/svg%3E");
  --flix-icon-success-solid: url("data:image/svg+xml,%3Csvg fill='%23a2e53f' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z'/%3E%3C/svg%3E");
  --flix-icon-time: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm3.2-6.2a1 1 0 11-1.4 1.4l-2.5-2.5a1 1 0 01-.3-.7V7a1 1 0 012 0v4.59l2.2 2.2z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-warning: url("data:image/svg+xml,%3Csvg fill='%23ffcb46' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm-1-7.5V7a1 1 0 012 0v5.5a1 1 0 11-2 0zm2.5 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'/%3E%3C/svg%3E");
  --flix-icon-warning-solid: url("data:image/svg+xml,%3Csvg fill='%23ffcb46' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 400;
  --flix-font-weight-bold: 700;
}

.flix-theme-dark {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
}

/* stylelint-enable */
/* stylelint-disable */
.flix-theme-high-contrast {
  --flix-primary-brand-color: #73d700;
  --flix-secondary-brand-color: #ffad00;
  --flix-ui-primary-color: #fff;
  --flix-ui-primary-light-color: #fff;
  --flix-ui-primary-dark-color: #000;
  --flix-grayscale-0-color: #000;
  --flix-grayscale-10-color: #252525;
  --flix-grayscale-30-color: #353535;
  --flix-grayscale-50-color: #444;
  --flix-grayscale-70-color: #fff;
  --flix-grayscale-90-color: #fff;
  --flix-grayscale-100-color: #fff;
  --flix-neutral-color: #ccf7f7;
  --flix-success-color: #e5f9c0;
  --flix-success-dark-color: #e5f9c0;
  --flix-warning-color: #ffefc5;
  --flix-warning-dark-color: #ffefc5;
  --flix-danger-color: #ffe3e6;
  --flix-danger-dark-color: #ffe3e6;
  --flix-bg-primary-color: #000;
  --flix-bg-primary-transparent-color: rgba(0, 0, 0, 0.88);
  --flix-bg-secondary-color: #000;
  --flix-box-bg-color: #000;
  --flix-highlight-color: #252525;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #ff0;
  --flix-content-primary-color: #fff;
  --flix-content-secondary-color: #fff;
  --flix-heading-font-color: #fff;
  --flix-link-color: #ff0;
  --flix-line-primary-color: #fff;
  --flix-icon-primary-color: #fff;
  --flix-icon-secondary-color: #fff;
  --flix-box-shadow-color: rgba(255, 255, 255, 0.18);
  --flix-box-shadow-subtle-color: rgba(255, 255, 255, 0.06);
  --flix-button-primary-color: #fff;
  --flix-button-secondary-color: #fff;
  --flix-button-label-color: #000;
  --flix-header-bg-color: #000;
  --flix-header-color: #fff;
  --flix-header-nav-bg-color-tablet: #000;
  --flix-input-border-color: #fff;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-input-border-radius: 6px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.9rem;
  --flix-font-size-fineprint: 0.8rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-font-size-button: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.1rem;
  --flix-line-height-fineprint: 0.9rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-icon-arrow-down: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' fill='%23ffffff' /%3E%3C/svg%3E");
  --flix-icon-arrow-left: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 17.27l-5.28-5.28 5.27-5.26a1 1 0 000-1.41 1 1 0 00-1.41 0l-5.99 5.97a1 1 0 000 1.41l6 5.98a1 1 0 001.42 0 1 1 0 000-1.41z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-arrow-right: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-attention-solid: url("data:image/svg+xml,%3Csvg fill='%23ffe3e6' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-icon-calendar: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M19 5h-1V4a1 1 0 00-2 0v1H8V4a1 1 0 00-2 0v1H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V8a3 3 0 00-3-3zm1 13c0 .54-.46 1-1 1H5c-.54 0-1-.46-1-1v-7h16v7zM4 9V8c0-.54.46-1 1-1h14c.54 0 1 .46 1 1v1H4zm2 4.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm-10 3a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-checkbox-indeterminate: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,11H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z' style='fill:%2373d700'/%3E%3C/svg%3E");
  --flix-icon-checkmark: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21.7 6.2l-13 13a1 1 0 01-1.4 0l-5-5a1 1 0 111.4-1.4L8 17.08l12.3-12.3a1 1 0 111.4 1.42z' style='fill:%23fff' /%3E%3C/svg%3E");
  --flix-icon-checkmark-solid: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z'/%3E%3C/svg%3E");
  --flix-icon-close: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-close-inverted: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-close-white: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-danger: url("data:image/svg+xml,%3Csvg fill='%23ffe3e6' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm4.95-3.05a1 1 0 01-1.41 0L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54a1 1 0 010 1.41z'/%3E%3C/svg%3E");
  --flix-icon-danger-solid: url("data:image/svg+xml,%3Csvg fill='%23ffe3e6' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' class='flix-icon crossed-solid'%3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.95 13.54a1 1 0 11-1.41 1.41L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54z'/%3E%3C/svg%3E");
  --flix-icon-header-arrow-down: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' fill='%23ffffff' /%3E%3C/svg%3E");
  --flix-icon-header-burger: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M2 5a1 1 0 011-1h18a1 1 0 010 2H3a1 1 0 01-1-1zm19 6H3a1 1 0 000 2h18a1 1 0 000-2zm0 7H3a1 1 0 000 2h18a1 1 0 000-2z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-info: url("data:image/svg+xml,%3Csvg fill='%23ccf7f7' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm1-8.5V17a1 1 0 01-2 0v-5.5a1 1 0 112 0zm.5-4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'/%3E%3C/svg%3E");
  --flix-icon-info-solid: url("data:image/svg+xml,%3Csvg fill='%23ccf7f7' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm1 15a1 1 0 01-2 0v-5.5a1 1 0 112 0V17zm-1-8a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-icon-minus: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11H3a1 1 0 000 2h18a1 1 0 000-2z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-radio-checkmark: url("data:image/svg+xml,%3Csvg fill='%23fff' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' class='flix-icon circle-solid'%3E%3Cpath d='M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z'/%3E%3C/svg%3E");
  --flix-icon-plus: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11h-8V3a1 1 0 00-2 0v8H3a1 1 0 000 2h8v8a1 1 0 002 0v-8h8a1 1 0 000-2z'/%3E%3C/svg%3E");
  --flix-icon-success: url("data:image/svg+xml,%3Csvg fill='%23e5f9c0' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M16.89 8.3a1 1 0 010 1.4l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 011.4 0zM22 12a10 10 0 11-20 0 10 10 0 0120 0zm-2 0a8 8 0 00-16 0 8 8 0 0016 0z'/%3E%3C/svg%3E");
  --flix-icon-success-solid: url("data:image/svg+xml,%3Csvg fill='%23e5f9c0' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z'/%3E%3C/svg%3E");
  --flix-icon-time: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm3.2-6.2a1 1 0 11-1.4 1.4l-2.5-2.5a1 1 0 01-.3-.7V7a1 1 0 012 0v4.59l2.2 2.2z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-warning: url("data:image/svg+xml,%3Csvg fill='%23ffefc5' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm-1-7.5V7a1 1 0 012 0v5.5a1 1 0 11-2 0zm2.5 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'/%3E%3C/svg%3E");
  --flix-icon-warning-solid: url("data:image/svg+xml,%3Csvg fill='%23ffefc5' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: inset -2px -2px 0 #fff, inset 2px 2px 0 #fff;
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 400;
  --flix-font-weight-bold: 700;
}

.flix-theme-high-contrast {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
}

/* stylelint-enable */
/* stylelint-disable */
.flix-theme-kamil {
  --flix-primary-brand-color: #004B8D;
  --flix-secondary-brand-color: #ffb300;
  --flix-ui-primary-color: #082967;
  --flix-ui-primary-light-color: #3eb5f1;
  --flix-ui-primary-dark-color: #00003c;
  --flix-grayscale-0-color: #fff;
  --flix-grayscale-10-color: #f7f7f7;
  --flix-grayscale-30-color: #e1e1e1;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #646464;
  --flix-grayscale-90-color: #444;
  --flix-grayscale-100-color: #353535;
  --flix-neutral-color: #016ae7;
  --flix-success-color: #228f00;
  --flix-success-dark-color: #136b00;
  --flix-warning-color: #ff5704;
  --flix-warning-dark-color: #c42d00;
  --flix-danger-color: #dd2828;
  --flix-danger-dark-color: #b31414;
  --flix-bg-primary-color: #fff;
  --flix-bg-primary-transparent-color: rgba(255, 255, 255, 0.88);
  --flix-bg-secondary-color: #f7f7f7;
  --flix-box-bg-color: #fff;
  --flix-highlight-color: #deeaff;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #353535;
  --flix-content-secondary-color: #646464;
  --flix-heading-font-color: #353535;
  --flix-link-color: #0047de;
  --flix-line-primary-color: #c8c8c8;
  --flix-icon-primary-color: #8b8b8b;
  --flix-icon-secondary-color: #082967;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #d0460a;
  --flix-button-secondary-color: #082967;
  --flix-button-label-color: #fff;
  --flix-header-bg-color: #082967;
  --flix-header-color: #fff;
  --flix-header-nav-bg-color-tablet: #00003c;
  --flix-input-border-color: #8b8b8b;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-input-border-radius: 6px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.9rem;
  --flix-font-size-fineprint: 0.8rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-font-size-button: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.1rem;
  --flix-line-height-fineprint: 0.9rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-icon-arrow-down: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' fill='%238b8b8b' /%3E%3C/svg%3E");
  --flix-icon-arrow-left: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 17.27l-5.28-5.28 5.27-5.26a1 1 0 000-1.41 1 1 0 00-1.41 0l-5.99 5.97a1 1 0 000 1.41l6 5.98a1 1 0 001.42 0 1 1 0 000-1.41z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-arrow-right: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-attention-solid: url("data:image/svg+xml,%3Csvg fill='%23dd2828' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-icon-calendar: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M19 5h-1V4a1 1 0 00-2 0v1H8V4a1 1 0 00-2 0v1H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V8a3 3 0 00-3-3zm1 13c0 .54-.46 1-1 1H5c-.54 0-1-.46-1-1v-7h16v7zM4 9V8c0-.54.46-1 1-1h14c.54 0 1 .46 1 1v1H4zm2 4.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm-10 3a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-checkbox-indeterminate: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,11H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z' style='fill:%23082967'/%3E%3C/svg%3E");
  --flix-icon-checkmark: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21.7 6.2l-13 13a1 1 0 01-1.4 0l-5-5a1 1 0 111.4-1.4L8 17.08l12.3-12.3a1 1 0 111.4 1.42z' style='fill:%23082967' /%3E%3C/svg%3E");
  --flix-icon-checkmark-solid: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%23228f00' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z'/%3E%3C/svg%3E");
  --flix-icon-close: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-close-inverted: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-close-white: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-danger: url("data:image/svg+xml,%3Csvg fill='%23dd2828' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm4.95-3.05a1 1 0 01-1.41 0L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54a1 1 0 010 1.41z'/%3E%3C/svg%3E");
  --flix-icon-danger-solid: url("data:image/svg+xml,%3Csvg fill='%23dd2828' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' class='flix-icon crossed-solid'%3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.95 13.54a1 1 0 11-1.41 1.41L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54z'/%3E%3C/svg%3E");
  --flix-icon-header-arrow-down: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' fill='%23ffffff' /%3E%3C/svg%3E");
  --flix-icon-header-burger: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M2 5a1 1 0 011-1h18a1 1 0 010 2H3a1 1 0 01-1-1zm19 6H3a1 1 0 000 2h18a1 1 0 000-2zm0 7H3a1 1 0 000 2h18a1 1 0 000-2z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-info: url("data:image/svg+xml,%3Csvg fill='%23016ae7' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm1-8.5V17a1 1 0 01-2 0v-5.5a1 1 0 112 0zm.5-4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'/%3E%3C/svg%3E");
  --flix-icon-info-solid: url("data:image/svg+xml,%3Csvg fill='%23016ae7' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm1 15a1 1 0 01-2 0v-5.5a1 1 0 112 0V17zm-1-8a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-icon-minus: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11H3a1 1 0 000 2h18a1 1 0 000-2z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-radio-checkmark: url("data:image/svg+xml,%3Csvg fill='%23082967' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' class='flix-icon circle-solid'%3E%3Cpath d='M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z'/%3E%3C/svg%3E");
  --flix-icon-plus: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%238b8b8b' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11h-8V3a1 1 0 00-2 0v8H3a1 1 0 000 2h8v8a1 1 0 002 0v-8h8a1 1 0 000-2z'/%3E%3C/svg%3E");
  --flix-icon-success: url("data:image/svg+xml,%3Csvg fill='%23228f00' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M16.89 8.3a1 1 0 010 1.4l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 011.4 0zM22 12a10 10 0 11-20 0 10 10 0 0120 0zm-2 0a8 8 0 00-16 0 8 8 0 0016 0z'/%3E%3C/svg%3E");
  --flix-icon-success-solid: url("data:image/svg+xml,%3Csvg fill='%23228f00' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z'/%3E%3C/svg%3E");
  --flix-icon-time: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm3.2-6.2a1 1 0 11-1.4 1.4l-2.5-2.5a1 1 0 01-.3-.7V7a1 1 0 012 0v4.59l2.2 2.2z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-warning: url("data:image/svg+xml,%3Csvg fill='%23ff5704' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm-1-7.5V7a1 1 0 012 0v5.5a1 1 0 11-2 0zm2.5 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'/%3E%3C/svg%3E");
  --flix-icon-warning-solid: url("data:image/svg+xml,%3Csvg fill='%23ff5704' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 400;
  --flix-font-weight-bold: 700;
}

.flix-theme-kamil {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
}

/* stylelint-enable */
/* stylelint-disable */
.flix-theme-legacy {
  --flix-primary-brand-color: #73d700;
  --flix-secondary-brand-color: #ffad00;
  --flix-ui-primary-color: #73d700;
  --flix-ui-primary-light-color: #a6ec55;
  --flix-ui-primary-dark-color: #5cac00;
  --flix-grayscale-0-color: #fff;
  --flix-grayscale-10-color: #f7f7f7;
  --flix-grayscale-30-color: #e1e1e1;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #8b8b8b;
  --flix-grayscale-90-color: #757575;
  --flix-grayscale-100-color: #444;
  --flix-neutral-color: #00b5e2;
  --flix-success-color: #73d700;
  --flix-success-dark-color: #5cac00;
  --flix-warning-color: #ffad00;
  --flix-warning-dark-color: #c68600;
  --flix-danger-color: #ff5700;
  --flix-danger-dark-color: #c64300;
  --flix-bg-primary-color: #fff;
  --flix-bg-primary-transparent-color: rgba(255, 255, 255, 0.88);
  --flix-bg-secondary-color: #f7f7f7;
  --flix-box-bg-color: #fff;
  --flix-highlight-color: #e5f9c0;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #444;
  --flix-content-secondary-color: #757575;
  --flix-heading-font-color: #444;
  --flix-link-color: #00b5e2;
  --flix-line-primary-color: #e1e1e1;
  --flix-icon-primary-color: #8b8b8b;
  --flix-icon-secondary-color: #73d700;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #ffad00;
  --flix-button-secondary-color: #73d700;
  --flix-button-label-color: #fff;
  --flix-header-bg-color: #73d700;
  --flix-header-color: #fff;
  --flix-header-nav-bg-color-tablet: #5cac00;
  --flix-input-border-color: #c8c8c8;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-input-border-radius: 6px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-primary: 16px;
  --flix-font-size-small: 14px;
  --flix-font-size-fineprint: 12px;
  --flix-font-size-h1: 27px;
  --flix-font-size-h2: 21px;
  --flix-font-size-h3: 18px;
  --flix-font-size-h4: 16px;
  --flix-font-size-button: 16px;
  --flix-line-height-primary: 1.5em;
  --flix-line-height-small: 18px;
  --flix-line-height-fineprint: 15px;
  --flix-line-height-h1: 33px;
  --flix-line-height-h2: 27px;
  --flix-line-height-h3: 24px;
  --flix-line-height-h4: 24px;
  --flix-icon-arrow-down: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-arrow-left: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M382 485.3L588.3 279.2A20.8 20.8 0 1 1 617.8 308.6L426.3 500 618 691.7A20.8 20.8 0 0 1 588.5 721.1L382.7 515.3C382.7 515.1 382.3 515.1 382.1 514.9A20.8 20.8 0 0 1 382 485.3Z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-arrow-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M618 514.7L411.7 720.8A20.8 20.8 0 0 1 382.2 691.4L573.7 500 382 308.3A20.8 20.8 0 0 1 411.5 279.2L617.3 485C617.3 485.2 617.7 485.2 618 485.4A20.8 20.8 0 0 1 618 514.7Z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-attention-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-calendar: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M458.3 489.4A41.7 41.7 0 1 1 416.7 447.7 41.7 41.7 0 0 1 458.3 489.4ZM583.3 447.7A41.7 41.7 0 1 0 625 489.4 41.7 41.7 0 0 0 583.3 447.7ZM750 447.7A41.7 41.7 0 1 0 791.7 489.4 41.7 41.7 0 0 0 750 447.7ZM416.7 572.7A41.7 41.7 0 1 0 458.3 614.4 41.7 41.7 0 0 0 416.7 572.7ZM583.3 572.7A41.7 41.7 0 1 0 625 614.4 41.7 41.7 0 0 0 583.3 572.7ZM750 572.7A41.7 41.7 0 1 0 791.7 614.4 41.7 41.7 0 0 0 750 572.7ZM250 572.7A41.7 41.7 0 1 0 291.7 614.4 41.7 41.7 0 0 0 250 572.7ZM416.7 697.7A41.7 41.7 0 1 0 458.3 739.4 41.7 41.7 0 0 0 416.7 697.7ZM250 697.7A41.7 41.7 0 1 0 291.7 739.4 41.7 41.7 0 0 0 250 697.7ZM917.1 738.7A125.4 125.4 0 0 1 792.3 864L209 864.8A125.4 125.4 0 0 1 83.7 740L82.9 323.4A125.4 125.4 0 0 1 207.7 198.1H229.2V156A20.8 20.8 0 0 1 270.8 156V198L729.2 197.4V156A20.8 20.8 0 0 1 770.8 156V197.3H791A125.4 125.4 0 0 1 916.2 322L916.2 362.7C916.2 363.3 916.6 363.8 916.6 364.4S916.3 365.5 916.2 366ZM124.6 343.5H874.6V322.1A83.3 83.3 0 0 0 791.3 238.8H770.8V280.9A20.8 20.8 0 1 1 729.2 280.9V239L270.8 239.7V281A20.8 20.8 0 1 1 229.2 281V239.7H207.7A83.3 83.3 0 0 0 124.4 323.1ZM875.4 738.8L874.7 385.2H124.7L125.4 740A83.3 83.3 0 0 0 208.8 823.3L792.1 822.5A83.3 83.3 0 0 0 875.4 738.8Z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-checkbox-indeterminate: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,11H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z' style='fill:%2373d700'/%3E%3C/svg%3E");
  --flix-icon-checkmark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,4.5a.988.988,0,0,0-.707.293L8,17.086,3.708,12.794A.989.989,0,0,0,3,12.5a1,1,0,0,0-.707,1.707l5,5a1,1,0,0,0,1.414,0l13-13A1,1,0,0,0,21,4.5Zm0,1h0Z' style='fill:%2373d700'/%3E%3C/svg%3E");
  --flix-icon-checkmark-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='%2373d700'/%3E%3C/svg%3E");
  --flix-icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-close-inverted: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-close-white: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M827.2 797.8A20.8 20.8 0 0 1 797.8 827.2L500 529.5 202.3 827.2A20.8 20.8 0 0 1 172.8 797.8H172.8L470.5 500 172.8 202.3A20.8 20.8 0 0 1 202.3 172.8H202.3L500 470.5 797.8 172.7H797.8A20.8 20.8 0 0 1 827.2 202.2L529.5 500 827.2 797.8Z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-danger: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM702.2 327.3L529.5 500 702.2 672.8H702.2A20.8 20.8 0 0 1 672.8 702.2L500 529.5 327.3 702.2A20.8 20.8 0 0 1 297.8 672.8H297.8L470.5 500 297.8 327.3A20.8 20.8 0 0 1 327.3 297.8H327.3L500 470.5 672.8 297.7H672.8A20.8 20.8 0 0 1 702.2 327.2Z' fill='%23ff5700'/%3E%3C/svg%3E");
  --flix-icon-danger-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM702.2 672.8H702.2A20.8 20.8 0 0 1 672.8 702.2L500 529.5 327.3 702.2A20.8 20.8 0 0 1 297.8 672.8H297.8L470.5 500 297.8 327.3A20.8 20.8 0 0 1 327.3 297.8H327.3L500 470.5 672.8 297.7H672.8A20.8 20.8 0 0 1 702.2 327.2L529.5 500Z' fill='%23ff5700'/%3E%3C/svg%3E");
  --flix-icon-header-arrow-down: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M485.3 618L279.2 411.7A20.8 20.8 0 1 1 308.3 382.2L500 573.7 691.7 382A20.8 20.8 0 0 1 721.1 411.5L515.3 617.3C515.1 617.3 515.1 617.7 514.9 617.9A20.8 20.8 0 0 1 485.3 618Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E");
  --flix-icon-header-burger: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M916.7 500A20.8 20.8 0 0 1 895.8 520.8H104.2A20.8 20.8 0 0 1 104.2 479.2H895.8A20.8 20.8 0 0 1 916.7 500ZM104.2 270.8H895.8A20.8 20.8 0 0 0 895.8 229.2H104.2A20.8 20.8 0 0 0 104.2 270.8ZM895.8 729.2H104.2A20.8 20.8 0 0 0 104.2 770.8H895.8A20.8 20.8 0 0 0 895.8 729.2Z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-info: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM458.3 291.7A41.7 41.7 0 1 1 500 333.3 41.7 41.7 0 0 1 458.3 291.7ZM520.8 437.5V729.2A20.8 20.8 0 0 1 479.2 729.2V437.5A20.8 20.8 0 0 1 520.8 437.5Z' fill='%2300b5e2'/%3E%3C/svg%3E");
  --flix-icon-info-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM520.8 729.2A20.8 20.8 0 0 1 479.2 729.2V437.5A20.8 20.8 0 0 1 520.8 437.5ZM500 333.3A41.7 41.7 0 1 1 541.7 291.7 41.7 41.7 0 0 1 500 333.3Z' fill='%2300b5e2'/%3E%3C/svg%3E");
  --flix-icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M916.7 500A20.8 20.8 0 0 1 895.8 520.8H104.2A20.8 20.8 0 0 1 104.2 479.2H895.8A20.8 20.8 0 0 1 916.7 500Z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-radio-checkmark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3Z' fill='%2373d700'/%3E%3C/svg%3E");
  --flix-icon-plus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M916.7 500A20.8 20.8 0 0 1 895.8 520.8H520.8V895.8A20.8 20.8 0 0 1 479.2 895.8V520.8H104.2A20.8 20.8 0 0 1 104.2 479.2H479.2V104.2A20.8 20.8 0 0 1 520.8 104.2V479.2H895.8A20.8 20.8 0 0 1 916.7 500Z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-success: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM729.2 354.2A20.8 20.8 0 0 1 723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 729.2 354.2Z' fill='%2373d700'/%3E%3C/svg%3E");
  --flix-icon-success-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM723.1 368.9L431.4 660.6A21.1 21.1 0 0 1 424.6 665 20.1 20.1 0 0 1 417 666.6L416.7 666.7 416.3 666.7A20 20 0 0 1 408.7 665.1 20.8 20.8 0 0 1 401.9 660.7L276.9 535.7A20.8 20.8 0 0 1 306.4 506.2H306.4L416.7 616.4 693.6 339.4H693.6A20.8 20.8 0 0 1 723.1 368.9Z' fill='%2373d700'/%3E%3C/svg%3E");
  --flix-icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM639.7 610.3A20.8 20.8 0 0 1 610.2 639.8L485.2 514.8 485 514.3A20 20 0 0 1 480.8 508 20.3 20.3 0 0 1 479.2 500V208.3A20.8 20.8 0 0 1 520.8 208.3V491.4L639.7 610.2Z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-warning: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM500 875A375 375 0 1 1 875 500 375.4 375.4 0 0 1 500 875ZM541.7 708.3A41.7 41.7 0 1 1 500 666.7 41.7 41.7 0 0 1 541.7 708.3ZM479.2 562.5V270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5Z' fill='%23ffad00'/%3E%3C/svg%3E");
  --flix-icon-warning-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3ZM479.2 270.8A20.8 20.8 0 0 1 520.8 270.8V562.5A20.8 20.8 0 0 1 479.2 562.5ZM500 750A41.7 41.7 0 1 1 541.7 708.3 41.7 41.7 0 0 1 500 750Z' fill='%23ffad00'/%3E%3C/svg%3E");
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 400;
  --flix-font-weight-bold: 700;
}

.flix-theme-legacy {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
}

/* stylelint-enable */
/* stylelint-disable */
.flix-theme-neptune {
  --flix-primary-brand-color: #004963;
  --flix-secondary-brand-color: #f57812;
  --flix-ui-primary-color: #004963;
  --flix-ui-primary-light-color: #00759c;
  --flix-ui-primary-dark-color: #003547;
  --flix-grayscale-0-color: #fff;
  --flix-grayscale-10-color: #f7f7f7;
  --flix-grayscale-30-color: #e1e1e1;
  --flix-grayscale-50-color: #c8c8c8;
  --flix-grayscale-70-color: #646464;
  --flix-grayscale-90-color: #444;
  --flix-grayscale-100-color: #353535;
  --flix-neutral-color: #016ae7;
  --flix-success-color: #228f00;
  --flix-success-dark-color: #136b00;
  --flix-warning-color: #ff5704;
  --flix-warning-dark-color: #c42d00;
  --flix-danger-color: #dd2828;
  --flix-danger-dark-color: #b31414;
  --flix-bg-primary-color: #fff;
  --flix-bg-primary-transparent-color: rgba(255, 255, 255, 0.88);
  --flix-bg-secondary-color: #f7f7f7;
  --flix-box-bg-color: #fff;
  --flix-highlight-color: #d4ecf5;
  --flix-overlay-bg-color: rgba(0, 0, 0, 0.5);
  --flix-focus-outline-color: #016ae7;
  --flix-content-primary-color: #353535;
  --flix-content-secondary-color: #646464;
  --flix-heading-font-color: #004963;
  --flix-link-color: #bd5604;
  --flix-line-primary-color: #c8c8c8;
  --flix-icon-primary-color: #8b8b8b;
  --flix-icon-secondary-color: #ef782c;
  --flix-box-shadow-color: rgba(0, 0, 0, 0.18);
  --flix-box-shadow-subtle-color: rgba(0, 0, 0, 0.06);
  --flix-button-primary-color: #bd5604;
  --flix-button-secondary-color: #004963;
  --flix-button-label-color: #fff;
  --flix-header-bg-color: #004963;
  --flix-header-color: #fff;
  --flix-header-nav-bg-color-tablet: #003547;
  --flix-input-border-color: #8b8b8b;
  --flix-input-height-mobile: 44px;
  --flix-input-height-desktop: 36px;
  --flix-input-border-radius: 6px;
  --flix-spacing-1: 6px;
  --flix-spacing-2: 12px;
  --flix-spacing-3: 18px;
  --flix-spacing-4: 24px;
  --flix-spacing-5: 30px;
  --flix-spacing-6: 36px;
  --flix-spacing-7: 42px;
  --flix-spacing-8: 48px;
  --flix-spacing-9: 54px;
  --flix-spacing-10: 60px;
  --flix-spacing-11: 66px;
  --flix-spacing-12: 72px;
  --flix-spacing-half: 3px;
  --flix-page-min-width: 320px;
  --flix-page-max-width: 1200px;
  --flix-font-size-primary: 1rem;
  --flix-font-size-small: 0.9rem;
  --flix-font-size-fineprint: 0.8rem;
  --flix-font-size-h1: 1.7rem;
  --flix-font-size-h2: 1.3rem;
  --flix-font-size-h3: 1.1rem;
  --flix-font-size-h4: 1rem;
  --flix-font-size-button: 1rem;
  --flix-line-height-primary: 1.5rem;
  --flix-line-height-small: 1.1rem;
  --flix-line-height-fineprint: 0.9rem;
  --flix-line-height-h1: 2rem;
  --flix-line-height-h2: 1.7rem;
  --flix-line-height-h3: 1.5rem;
  --flix-line-height-h4: 1.5rem;
  --flix-icon-arrow-down: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' fill='%238b8b8b' /%3E%3C/svg%3E");
  --flix-icon-arrow-left: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 17.27l-5.28-5.28 5.27-5.26a1 1 0 000-1.41 1 1 0 00-1.41 0l-5.99 5.97a1 1 0 000 1.41l6 5.98a1 1 0 001.42 0 1 1 0 000-1.41z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-arrow-right: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.7 11.3L9.72 5.32a1 1 0 00-1.42 0 1 1 0 000 1.42L13.58 12 8.3 17.27a1 1 0 000 1.41 1 1 0 001.41 0l5.99-5.97a1 1 0 000-1.41z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-attention-solid: url("data:image/svg+xml,%3Csvg fill='%23dd2828' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-icon-calendar: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M19 5h-1V4a1 1 0 00-2 0v1H8V4a1 1 0 00-2 0v1H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V8a3 3 0 00-3-3zm1 13c0 .54-.46 1-1 1H5c-.54 0-1-.46-1-1v-7h16v7zM4 9V8c0-.54.46-1 1-1h14c.54 0 1 .46 1 1v1H4zm2 4.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm-10 3a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-checkbox-indeterminate: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,11H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z' fill='%23004963'/%3E%3C/svg%3E");
  --flix-icon-checkmark: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21.7 6.2l-13 13a1 1 0 01-1.4 0l-5-5a1 1 0 111.4-1.4L8 17.08l12.3-12.3a1 1 0 111.4 1.42z' style='fill:%23004963' /%3E%3C/svg%3E");
  --flix-icon-checkmark-solid: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%23228f00' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z'/%3E%3C/svg%3E");
  --flix-icon-close: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-close-inverted: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-close-white: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.41 12l6.37-6.36a1 1 0 10-1.42-1.42L12 10.6 5.64 4.22a1 1 0 10-1.42 1.42L10.6 12l-6.37 6.36a1 1 0 101.42 1.42L12 13.4l6.36 6.37a1 1 0 101.42-1.42L13.4 12z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-danger: url("data:image/svg+xml,%3Csvg fill='%23dd2828' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm4.95-3.05a1 1 0 01-1.41 0L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54a1 1 0 010 1.41z'/%3E%3C/svg%3E");
  --flix-icon-danger-solid: url("data:image/svg+xml,%3Csvg fill='%23dd2828' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' class='flix-icon crossed-solid'%3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.95 13.54a1 1 0 11-1.41 1.41L12 13.41l-3.54 3.54a1 1 0 11-1.41-1.41L10.59 12 7.05 8.46a1 1 0 111.41-1.41L12 10.59l3.54-3.54a1 1 0 111.41 1.41L13.41 12l3.54 3.54z'/%3E%3C/svg%3E");
  --flix-icon-header-arrow-down: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M18.68 8.3a1 1 0 00-1.41 0l-5.28 5.28-5.26-5.27a1 1 0 00-1.4 0 1 1 0 000 1.41l5.96 5.99a1 1 0 001.41 0l5.98-6a1 1 0 000-1.42z' fill='%23ffffff' /%3E%3C/svg%3E");
  --flix-icon-header-burger: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M2 5a1 1 0 011-1h18a1 1 0 010 2H3a1 1 0 01-1-1zm19 6H3a1 1 0 000 2h18a1 1 0 000-2zm0 7H3a1 1 0 000 2h18a1 1 0 000-2z' fill='%23ffffff'/%3E%3C/svg%3E");
  --flix-icon-info: url("data:image/svg+xml,%3Csvg fill='%23016ae7' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm1-8.5V17a1 1 0 01-2 0v-5.5a1 1 0 112 0zm.5-4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'/%3E%3C/svg%3E");
  --flix-icon-info-solid: url("data:image/svg+xml,%3Csvg fill='%23016ae7' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm1 15a1 1 0 01-2 0v-5.5a1 1 0 112 0V17zm-1-8a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-icon-minus: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11H3a1 1 0 000 2h18a1 1 0 000-2z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-radio-checkmark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 83.3A416.7 416.7 0 1 0 916.7 500 416.7 416.7 0 0 0 500 83.3Z' fill='%23004963'/%3E%3C/svg%3E");
  --flix-icon-plus: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%238b8b8b' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M21 11h-8V3a1 1 0 00-2 0v8H3a1 1 0 000 2h8v8a1 1 0 002 0v-8h8a1 1 0 000-2z'/%3E%3C/svg%3E");
  --flix-icon-success: url("data:image/svg+xml,%3Csvg fill='%23228f00' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M16.89 8.3a1 1 0 010 1.4l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 011.4 0zM22 12a10 10 0 11-20 0 10 10 0 0120 0zm-2 0a8 8 0 00-16 0 8 8 0 0016 0z'/%3E%3C/svg%3E");
  --flix-icon-success-solid: url("data:image/svg+xml,%3Csvg fill='%23228f00' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm4.89 7.7l-6 6a1 1 0 01-1.42 0l-2.36-2.35a1 1 0 111.42-1.41l1.65 1.65 5.3-5.3a1 1 0 111.4 1.42z'/%3E%3C/svg%3E");
  --flix-icon-time: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm3.2-6.2a1 1 0 11-1.4 1.4l-2.5-2.5a1 1 0 01-.3-.7V7a1 1 0 012 0v4.59l2.2 2.2z' fill='%238b8b8b'/%3E%3C/svg%3E");
  --flix-icon-warning: url("data:image/svg+xml,%3Csvg fill='%23ff5704' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 010-16 8 8 0 010 16zm-1-7.5V7a1 1 0 012 0v5.5a1 1 0 11-2 0zm2.5 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'/%3E%3C/svg%3E");
  --flix-icon-warning-solid: url("data:image/svg+xml,%3Csvg fill='%23ff5704' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M12 2a10 10 0 100 20 10 10 0 000-20zm-1 5a1 1 0 012 0v5.5a1 1 0 11-2 0V7zm1 11a1.5 1.5 0 110-3 1.5 1.5 0 010 3z'/%3E%3C/svg%3E");
  --flix-hover-layer-color: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  --flix-pressed-layer-color: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  --flix-disabled-element-opacity: 0.5;
  --flix-primary-border-radius: 6px;
  --flix-primary-box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
  --flix-font-family-primary: Roboto, Arial, sans-serif;
  --flix-font-weight-normal: 400;
  --flix-font-weight-semibold: 400;
  --flix-font-weight-bold: 700;
}

.flix-theme-neptune {
  color: var(--flix-content-primary-color);
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
}

/* stylelint-enable */
* {
  box-sizing: border-box;
}

[class^=flix-] {
  margin: 0;
  padding: 0;
}
[class^=flix-]::before, [class^=flix-]::after {
  box-sizing: border-box;
}

.flix-accordion {
  display: block;
  border: 1px solid var(--flix-line-primary-color);
  font-family: inherit;
}
.flix-accordion:first-of-type {
  border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
}
.flix-accordion:last-of-type {
  border-radius: 0 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius);
}
.flix-accordion:only-of-type {
  border-radius: var(--flix-primary-border-radius);
}
.flix-accordion__title {
  color: var(--flix-heading-font-color);
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
  display: block;
  position: relative;
  padding: var(--flix-spacing-2) var(--flix-spacing-6) var(--flix-spacing-2) var(--flix-spacing-2);
  outline: 0;
  cursor: pointer;
}
.flix-accordion__title .flix-icon {
  margin-right: var(--flix-spacing-half);
}
.flix-accordion__title .flix-icon::before {
  margin: 0;
}
.flix-accordion__title::-webkit-details-marker {
  display: none;
}
.flix-accordion__title::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  position: absolute;
  top: 50%;
  right: var(--flix-spacing-2);
  transform: translateY(-50%);
  background: var(--flix-icon-arrow-down) no-repeat center;
}
.flix-accordion__title:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-accordion__title:active, .flix-accordion__title:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-accordion__title:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-accordion[open] > .flix-accordion__title::after, .flix-accordion--open > .flix-accordion__title::after {
  transform: translateY(-50%) rotate(180deg);
}
.flix-accordion[open] > .flix-accordion__content, .flix-accordion--open > .flix-accordion__content {
  display: block;
}
.flix-accordion__content {
  display: none;
  padding: var(--flix-spacing-1) var(--flix-spacing-2) var(--flix-spacing-2);
}
.flix-accordion + .flix-accordion {
  border-top: 0;
}

.flix-avatar {
  display: block;
  width: var(--flix-spacing-6);
  height: var(--flix-spacing-6);
}
.flix-avatar__image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.flix-avatar__image svg {
  width: 100%;
  height: 100%;
}
a.flix-avatar:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}

.flix-avatar--sm {
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
}
.flix-avatar--lg {
  width: var(--flix-spacing-8);
  height: var(--flix-spacing-8);
}
.flix-avatar--xl {
  width: calc(var(--flix-spacing-8) * 2);
  height: calc(var(--flix-spacing-8) * 2);
}

.flix-balloon, .flix-tooltip {
  z-index: 10;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 15.75rem;
  min-height: 44px;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  text-decoration: none;
  padding: var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-4);
  position: relative;
}
.flix-balloon:not([hidden]), .flix-tooltip:not([hidden]) {
  display: flex;
}
.flix-balloon::before, .flix-tooltip::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-balloon::before, .flix-tooltip::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: var(--flix-spacing-2);
}
.flix-balloon::after, .flix-tooltip::after {
  position: absolute;
  z-index: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-right: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-left: 0;
  right: auto;
  left: var(--flix-spacing-1);
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}
.flix-balloon--content-fit, .flix-tooltip--content-fit {
  min-width: auto;
  min-height: auto;
  white-space: nowrap;
}
.flix-balloon--medium, .flix-tooltip--medium {
  z-index: 10;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 23.25rem;
  min-height: 44px;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  text-decoration: none;
  padding: var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-4);
}
.flix-balloon--medium:not([hidden]), .flix-tooltip--medium:not([hidden]) {
  display: flex;
}
.flix-balloon--medium::before, .flix-tooltip--medium::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-balloon--medium::before, .flix-tooltip--medium::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: var(--flix-spacing-2);
}
.flix-balloon--large, .flix-tooltip--large {
  z-index: 10;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 30.75rem;
  min-height: 44px;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  text-decoration: none;
  padding: var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-4);
}
.flix-balloon--large:not([hidden]), .flix-tooltip--large:not([hidden]) {
  display: flex;
}
.flix-balloon--large::before, .flix-tooltip--large::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-balloon--large::before, .flix-tooltip--large::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: var(--flix-spacing-2);
}
.flix-balloon--danger, .flix-tooltip--danger {
  color: var(--flix-bg-primary-color);
}
.flix-balloon--danger::before, .flix-tooltip--danger::before {
  background: var(--flix-danger-color);
}
.flix-balloon--danger::after, .flix-tooltip--danger::after {
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-right: calc(var(--flix-spacing-1) + 1px) solid var(--flix-danger-color);
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-left: 0;
}
.flix-balloon--start::after, .flix-tooltip--start::after {
  top: var(--flix-spacing-3);
  transform: none;
}
@supports (top: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--start::after, .flix-tooltip--start::after {
    top: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--end::after, .flix-tooltip--end::after {
  top: auto;
  bottom: var(--flix-spacing-3);
  transform: none;
}
@supports (bottom: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--end::after, .flix-tooltip--end::after {
    bottom: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--left, .flix-tooltip--left {
  padding: var(--flix-spacing-2) var(--flix-spacing-4) var(--flix-spacing-2) var(--flix-spacing-2);
}
.flix-balloon--left::before, .flix-tooltip--left::before {
  top: 0;
  right: var(--flix-spacing-2);
  bottom: 0;
  left: 0;
}
.flix-balloon--left::after, .flix-tooltip--left::after {
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-right: 0;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-left: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  right: var(--flix-spacing-1);
  left: auto;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}
.flix-balloon--left.flix-balloon--danger::after, .flix-balloon--danger.flix-tooltip--left::after, .flix-balloon--left.flix-tooltip--danger::after, .flix-tooltip--danger.flix-tooltip--left::after {
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-right: 0;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-left: calc(var(--flix-spacing-1) + 1px) solid var(--flix-danger-color);
}
.flix-balloon--left.flix-balloon--start::after, .flix-balloon--start.flix-tooltip--left::after, .flix-balloon--left.flix-tooltip--start::after, .flix-tooltip--start.flix-tooltip--left::after {
  top: var(--flix-spacing-3);
  transform: none;
}
@supports (top: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--left.flix-balloon--start::after, .flix-balloon--start.flix-tooltip--left::after, .flix-balloon--left.flix-tooltip--start::after, .flix-tooltip--start.flix-tooltip--left::after {
    top: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--left.flix-balloon--end::after, .flix-balloon--end.flix-tooltip--left::after, .flix-balloon--left.flix-tooltip--end::after, .flix-tooltip--end.flix-tooltip--left::after {
  top: auto;
  bottom: var(--flix-spacing-3);
  transform: none;
}
@supports (bottom: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--left.flix-balloon--end::after, .flix-balloon--end.flix-tooltip--left::after, .flix-balloon--left.flix-tooltip--end::after, .flix-tooltip--end.flix-tooltip--left::after {
    bottom: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--top, .flix-tooltip--top {
  padding: var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-4);
}
.flix-balloon--top::before, .flix-tooltip--top::before {
  top: 0;
  right: 0;
  bottom: var(--flix-spacing-2);
  left: 0;
}
.flix-balloon--top::after, .flix-tooltip--top::after {
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: 0;
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
  top: auto;
  bottom: var(--flix-spacing-1);
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}
.flix-balloon--top.flix-balloon--danger::after, .flix-balloon--danger.flix-tooltip--top::after, .flix-balloon--top.flix-tooltip--danger::after, .flix-tooltip--danger.flix-tooltip--top::after {
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid var(--flix-danger-color);
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: 0;
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
}
.flix-balloon--top.flix-balloon--start::after, .flix-balloon--start.flix-tooltip--top::after, .flix-balloon--top.flix-tooltip--start::after, .flix-tooltip--start.flix-tooltip--top::after {
  left: var(--flix-spacing-3);
  transform: none;
}
@supports (left: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--top.flix-balloon--start::after, .flix-balloon--start.flix-tooltip--top::after, .flix-balloon--top.flix-tooltip--start::after, .flix-tooltip--start.flix-tooltip--top::after {
    left: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--top.flix-balloon--end::after, .flix-balloon--end.flix-tooltip--top::after, .flix-balloon--top.flix-tooltip--end::after, .flix-tooltip--end.flix-tooltip--top::after {
  right: var(--flix-spacing-3);
  left: auto;
  transform: none;
}
@supports (right: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--top.flix-balloon--end::after, .flix-balloon--end.flix-tooltip--top::after, .flix-balloon--top.flix-tooltip--end::after, .flix-tooltip--end.flix-tooltip--top::after {
    right: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--bottom, .flix-tooltip--bottom {
  padding: var(--flix-spacing-4) var(--flix-spacing-2) var(--flix-spacing-2);
}
.flix-balloon--bottom::before, .flix-tooltip--bottom::before {
  top: var(--flix-spacing-2);
  right: 0;
  bottom: 0;
  left: 0;
}
.flix-balloon--bottom::after, .flix-tooltip--bottom::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0;
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
  top: var(--flix-spacing-1);
  bottom: auto;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}
.flix-balloon--bottom.flix-balloon--danger::after, .flix-balloon--danger.flix-tooltip--bottom::after, .flix-balloon--bottom.flix-tooltip--danger::after, .flix-tooltip--danger.flix-tooltip--bottom::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0;
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid var(--flix-danger-color);
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
}
.flix-balloon--bottom.flix-balloon--start::after, .flix-balloon--start.flix-tooltip--bottom::after, .flix-balloon--bottom.flix-tooltip--start::after, .flix-tooltip--start.flix-tooltip--bottom::after {
  left: var(--flix-spacing-3);
  transform: none;
}
@supports (left: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--bottom.flix-balloon--start::after, .flix-balloon--start.flix-tooltip--bottom::after, .flix-balloon--bottom.flix-tooltip--start::after, .flix-tooltip--start.flix-tooltip--bottom::after {
    left: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--bottom.flix-balloon--end::after, .flix-balloon--end.flix-tooltip--bottom::after, .flix-balloon--bottom.flix-tooltip--end::after, .flix-tooltip--end.flix-tooltip--bottom::after {
  right: var(--flix-spacing-3);
  left: auto;
  transform: none;
}
@supports (right: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--bottom.flix-balloon--end::after, .flix-balloon--end.flix-tooltip--bottom::after, .flix-balloon--bottom.flix-tooltip--end::after, .flix-tooltip--end.flix-tooltip--bottom::after {
    right: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon__content, .flix-tooltip__content {
  position: relative;
  z-index: 1;
  flex: auto;
  text-align: left;
}
.flix-balloon--content-fit .flix-balloon__content, .flix-balloon--content-fit .flix-tooltip__content, .flix-tooltip--content-fit .flix-balloon__content, .flix-tooltip--content-fit .flix-tooltip__content {
  margin: calc(-1 * var(--flix-spacing-1));
}
.flix-balloon__close, .flix-tooltip__close {
  position: relative;
  position: relative;
  z-index: 1;
  align-self: flex-start;
}
.flix-balloon__close::before, .flix-tooltip__close::before, .flix-balloon__close::after, .flix-tooltip__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-icon-primary-color);
}
.flix-balloon__close::before, .flix-tooltip__close::before {
  transform: rotate(45deg);
}
.flix-balloon__close::after, .flix-tooltip__close::after {
  transform: rotate(-45deg);
}
.flix-balloon--danger .flix-balloon__close, .flix-balloon--danger .flix-tooltip__close, .flix-tooltip--danger .flix-balloon__close, .flix-tooltip--danger .flix-tooltip__close {
  position: relative;
  color: var(--flix-bg-primary-color);
}
.flix-balloon--danger .flix-balloon__close::before, .flix-balloon--danger .flix-tooltip__close::before, .flix-tooltip--danger .flix-balloon__close::before, .flix-tooltip--danger .flix-tooltip__close::before, .flix-balloon--danger .flix-balloon__close::after, .flix-balloon--danger .flix-tooltip__close::after, .flix-tooltip--danger .flix-balloon__close::after, .flix-tooltip--danger .flix-tooltip__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-bg-primary-color);
}
.flix-balloon--danger .flix-balloon__close::before, .flix-balloon--danger .flix-tooltip__close::before, .flix-tooltip--danger .flix-balloon__close::before, .flix-tooltip--danger .flix-tooltip__close::before {
  transform: rotate(45deg);
}
.flix-balloon--danger .flix-balloon__close::after, .flix-balloon--danger .flix-tooltip__close::after, .flix-tooltip--danger .flix-balloon__close::after, .flix-tooltip--danger .flix-tooltip__close::after {
  transform: rotate(-45deg);
}

.flix-blockquote, .flix-text blockquote {
  position: relative;
  margin: 0 0 var(--flix-spacing-2);
  padding: var(--flix-spacing-2) 0 0 calc(var(--flix-spacing-4) + var(--flix-spacing-2));
  color: var(--flix-content-primary-color);
  font-family: inherit;
}
@media (min-width: 600px) {
  .flix-blockquote, .flix-text blockquote {
    padding: var(--flix-spacing-2) 0 0 calc(var(--flix-spacing-8) + var(--flix-spacing-2));
  }
}
.flix-blockquote::before, .flix-text blockquote::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-2);
  /* stylelint-disable-next-line scss/operator-no-unspaced */
  background: url("data:image/svg+xml,%3Csvg id='Ebene_1' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 159.8 100'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%238c8c8c;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Equote1%3C/title%3E%3Cpath class='cls-1' d='M159.4,62.9A32.2,32.2,0,0,0,129,35.7c3.7-13.8,12.8-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.8-11s-0.2-.9-1.6-0.7c-38,4.3-63.7,33.2-58.9,68.3,4.4,24.3,23.4,33.7,41,31.2a32.4,32.4,0,0,0,27-36.7h0ZM38.1,35.7c3.6-13.8,13-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.6-11S61-.2,59.6,0C21.6,4.1-3.9,33,.5,68.2c4.6,24.5,23.8,33.9,41.2,31.4A32.1,32.1,0,0,0,68.5,62.9,31.7,31.7,0,0,0,38.1,35.7Z'/%3E%3C/svg%3E") no-repeat;
}
@media (min-width: 600px) {
  .flix-blockquote::before, .flix-text blockquote::before {
    width: var(--flix-spacing-8);
    height: var(--flix-spacing-4);
  }
}

.flix-box {
  padding: var(--flix-spacing-4);
  border: 1px solid var(--flix-line-primary-color);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
}
.flix-box:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-box--small {
  padding: var(--flix-spacing-2);
}
.flix-box--dimmed {
  border-color: var(--flix-grayscale-50-color);
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-grayscale-50-color);
}
.flix-box--neutral {
  border-color: var(--flix-neutral-color);
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-neutral-color);
}
.flix-box--warning {
  border-color: var(--flix-warning-color);
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-warning-color);
}
.flix-box--success {
  border-color: var(--flix-success-color);
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-success-color);
}
.flix-box--danger {
  border-color: var(--flix-danger-color);
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-danger-color);
}
.flix-box--highlighted {
  border: 0;
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-box--highlighted.flix-box--dimmed {
  box-shadow: var(--flix-primary-box-shadow), inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-grayscale-50-color);
}
.flix-box--highlighted.flix-box--neutral {
  box-shadow: var(--flix-primary-box-shadow), inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-neutral-color);
}
.flix-box--highlighted.flix-box--warning {
  box-shadow: var(--flix-primary-box-shadow), inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-warning-color);
}
.flix-box--highlighted.flix-box--success {
  box-shadow: var(--flix-primary-box-shadow), inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-success-color);
}
.flix-box--highlighted.flix-box--danger {
  box-shadow: var(--flix-primary-box-shadow), inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-danger-color);
}

.flix-breadcrumbs {
  display: flex;
  align-items: center;
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-semibold);
  line-height: 1.3em;
}
.flix-breadcrumbs__item {
  display: flex;
  align-items: center;
}
.flix-breadcrumbs__item + .flix-breadcrumbs__item::before {
  content: var(--flix-icon-arrow-right);
  display: inline-block;
  width: 1.286em;
  height: 1.286em;
  margin: 0 var(--flix-spacing-half);
}
.flix-breadcrumbs__ellipsis::before {
  content: "...";
}
.flix-breadcrumbs__link {
  display: inline-block;
  position: relative;
  max-width: 150px;
  margin: calc(-1 * var(--flix-spacing-half));
  padding: var(--flix-spacing-half);
  overflow: hidden;
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-content-secondary-color);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flix-breadcrumbs__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-breadcrumbs__link:link:hover, .flix-breadcrumbs__link:visited:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-breadcrumbs__link:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-breadcrumbs__link[aria-current] {
  border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
  color: var(--flix-content-primary-color);
}
.flix-breadcrumbs__link[aria-current]::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0;
  border-bottom: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}

.flix-btn, .flix-skip-link {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  min-width: 142px;
  max-width: 100%;
  height: var(--flix-input-height-mobile);
  padding: 0 var(--flix-spacing-2);
  overflow: hidden;
  column-gap: var(--flix-spacing-half);
  border: 1px solid var(--flix-grayscale-50-color);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-button);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-input-height-mobile);
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: background 250ms ease;
}
.flix-btn:enabled:hover, .flix-skip-link:enabled:hover, .flix-btn[href]:hover, [href].flix-skip-link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-btn:active, .flix-skip-link:active, .flix-btn:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-btn:focus, .flix-skip-link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-btn.flix-btn--loading, .flix-btn--loading.flix-skip-link {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
@media (min-width: 600px) {
  .flix-btn, .flix-skip-link {
    width: auto;
  }
}
@media (min-width: 1200px) {
  .flix-btn, .flix-skip-link {
    height: var(--flix-input-height-desktop);
    line-height: var(--flix-input-height-desktop);
  }
}
.flix-btn + .flix-btn, .flix-skip-link + .flix-btn, .flix-btn + .flix-skip-link, .flix-skip-link + .flix-skip-link {
  margin-top: var(--flix-spacing-2);
}
@media (min-width: 600px) {
  .flix-btn + .flix-btn, .flix-skip-link + .flix-btn, .flix-btn + .flix-skip-link, .flix-skip-link + .flix-skip-link {
    margin-top: 0;
    margin-left: var(--flix-spacing-2);
  }
}
.flix-btn--sm {
  width: auto;
  min-width: auto;
  height: var(--flix-spacing-4);
  font-size: var(--flix-font-size-small);
}
.flix-btn--sm.flix-btn--square {
  width: var(--flix-spacing-4);
  min-width: var(--flix-spacing-4);
}
.flix-btn--md {
  width: auto;
  height: var(--flix-spacing-6);
}
.flix-btn--md.flix-btn--square {
  width: var(--flix-spacing-6);
  min-width: var(--flix-spacing-6);
}
.flix-btn--lg {
  width: auto;
  height: var(--flix-spacing-8);
  padding: 0 var(--flix-spacing-4);
}
.flix-btn--lg.flix-btn--square {
  width: var(--flix-spacing-8);
  min-width: var(--flix-spacing-8);
}
.flix-btn--block {
  display: flex;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}
.flix-btn--primary {
  border-color: var(--flix-button-primary-color);
  background: var(--flix-button-primary-color);
  color: var(--flix-button-label-color);
}
.flix-btn--primary.flix-btn--loading::before {
  border-left-color: var(--flix-button-label-color);
}
.flix-btn--secondary {
  border-color: var(--flix-button-secondary-color);
  background: var(--flix-button-secondary-color);
  color: var(--flix-button-label-color);
}
.flix-btn--secondary.flix-btn--loading::before {
  border-left-color: var(--flix-button-label-color);
}
.flix-btn--tertiary {
  border-color: var(--flix-button-secondary-color);
  background: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
}
.flix-btn--tertiary.flix-btn--loading::before {
  border-left-color: var(--flix-content-primary-color);
}
.flix-btn--danger {
  border-color: var(--flix-danger-color);
  background: var(--flix-danger-color);
  color: var(--flix-grayscale-0-color);
}
.flix-btn--danger.flix-btn--loading::before {
  border-left-color: var(--flix-grayscale-0-color);
}
.flix-btn:disabled, .flix-skip-link:disabled, .flix-btn--disabled {
  opacity: var(--flix-disabled-element-opacity);
  cursor: not-allowed;
}
.flix-btn--loading::before {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  margin-right: var(--flix-spacing-half);
  margin-left: var(--flix-spacing-half);
  animation: btn-spinner-rotate 1s infinite linear, btn-spinner-grow 250ms;
  border: var(--flix-spacing-half) solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  border-left-color: var(--flix-content-primary-color);
}
@keyframes btn-spinner-grow {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  100% {
    width: var(--flix-spacing-4);
    height: var(--flix-spacing-4);
    opacity: 1;
  }
}
@keyframes btn-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.flix-btn--loading .flix-btn__icon {
  display: none;
}
.flix-btn--link {
  width: auto;
  min-width: auto;
  padding: 0 var(--flix-spacing-half);
  border-color: transparent;
  background: transparent;
  color: var(--flix-content-primary-color);
}
.flix-btn--link + .flix-btn--link {
  margin-top: 0;
  margin-left: var(--flix-spacing-2);
}
.flix-btn--link.flix-btn--loading::before {
  margin-left: 0;
}
.flix-btn--square {
  width: var(--flix-input-height-mobile);
  min-width: var(--flix-input-height-mobile);
  padding: 0;
}
@media (min-width: 1200px) {
  .flix-btn--square {
    width: var(--flix-input-height-desktop);
    min-width: var(--flix-input-height-desktop);
  }
}
.flix-btn--square + .flix-btn--square {
  margin-top: 0;
  margin-left: var(--flix-spacing-2);
}

.flix-btn-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--flix-spacing-2);
}
.flix-btn-group:not(:last-child) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-btn-group--half-gap {
  gap: var(--flix-spacing-half);
}
.flix-btn-group--1-gap {
  gap: var(--flix-spacing-1);
}
.flix-btn-group--horizontal {
  flex-direction: row;
}
.flix-btn-group--vertical {
  flex-direction: column;
}
.flix-btn-group--align-center {
  align-items: center;
  justify-content: center;
}
.flix-btn-group--align-end {
  align-items: flex-end;
  justify-content: flex-end;
}
@media (min-width: 600px) {
  .flix-btn-group:not(.flix-btn-group--vertical, .flix-btn-group--horizontal) {
    flex-direction: row;
  }
}
.flix-btn-group > .flix-btn + .flix-btn, .flix-btn-group > .flix-skip-link + .flix-btn, .flix-btn-group > .flix-btn + .flix-skip-link, .flix-btn-group > .flix-skip-link + .flix-skip-link {
  margin-top: 0;
  margin-left: 0;
}

.flix-checkbox {
  position: relative;
}
.flix-checkbox--sm .flix-checkbox__label {
  min-height: var(--flix-spacing-3);
  border-radius: var(--flix-primary-border-radius);
}
.flix-checkbox--sm .flix-checkbox__label:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-checkbox--sm .flix-checkbox__label::before {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
  margin-top: 2px;
}
.flix-checkbox--sm .flix-checkbox__input {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
}
.flix-checkbox--sm .flix-checkbox__input:checked + .flix-checkbox__label::before {
  background-size: 10px;
}
.flix-checkbox__label {
  display: inline-flex;
  align-items: center;
  min-height: var(--flix-spacing-5);
  margin: calc(-1 * var(--flix-spacing-half));
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  vertical-align: middle;
  cursor: pointer;
}
@supports (column-gap: normal) {
  .flix-checkbox__label {
    column-gap: var(--flix-spacing-2);
  }
}
.flix-checkbox__label::before {
  content: "";
  display: block;
  flex-shrink: 0;
  align-self: baseline;
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  margin-right: var(--flix-spacing-2);
  border: 2px solid var(--flix-input-border-color);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-icon-checkmark) no-repeat center;
  background-size: 0;
  transition: background-size 250ms ease;
}
@supports (column-gap: normal) {
  .flix-checkbox__label::before {
    margin-right: 0;
  }
}
.flix-checkbox__input {
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
}
.flix-checkbox__input:checked + .flix-checkbox__label::before {
  background-size: 24px;
}
.flix-checkbox__info:not(:empty) {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-checkbox__info:not(:empty) ~ .flix-checkbox__info {
  margin-top: var(--flix-spacing-half);
}
.flix-checkbox--error .flix-checkbox__info {
  color: var(--flix-danger-dark-color);
}
.flix-checkbox__info ~ .flix-checkbox__info {
  color: var(--flix-content-primary-color);
}
.flix-checkbox__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.flix-checkbox__input:focus + .flix-checkbox__label::before {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-checkbox__input:enabled + .flix-checkbox__label:hover, .flix-checkbox__input:enabled:hover + .flix-checkbox__label {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-checkbox__input:checked + .flix-checkbox__label::before {
  border-color: var(--flix-ui-primary-color);
}
.flix-checkbox--disabled .flix-checkbox__label {
  opacity: var(--flix-disabled-element-opacity);
  cursor: not-allowed;
}
.flix-checkbox--error .flix-checkbox__label::before {
  border-color: var(--flix-danger-color);
}
.flix-checkbox--error .flix-checkbox__input:not(:checked) + .flix-checkbox__label::before {
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-danger-color);
  transition: box-shadow 250ms ease;
}
.flix-checkbox--error .flix-checkbox__input:focus:not(:checked) + .flix-checkbox__label::before {
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-danger-color), 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-checkbox__input:indeterminate + .flix-checkbox__label::before, .flix-checkbox--indeterminate > .flix-checkbox__label::before {
  border-color: var(--flix-ui-primary-color);
  background: var(--flix-icon-checkbox-indeterminate) no-repeat center;
}
.flix-checkbox--sm .flix-checkbox__label::before {
  border-radius: 3px;
}

.flix-choice-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 var(--flix-spacing-4);
  border: 0;
}
.flix-choice-wrapper__info:not(:empty) {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-choice-wrapper__info:not(:empty) ~ .flix-choice-wrapper__info {
  margin-top: var(--flix-spacing-half);
}
.flix-choice-wrapper--error .flix-choice-wrapper__info {
  color: var(--flix-danger-dark-color);
}
.flix-choice-wrapper__info ~ .flix-choice-wrapper__info {
  color: var(--flix-content-primary-color);
}
.flix-choice-wrapper__fieldset {
  border: 0;
}
.flix-choice-wrapper__item {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  border: solid var(--flix-line-primary-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-choice-wrapper__item:first-child:nth-last-of-type(2), .flix-choice-wrapper__item:first-child:nth-last-of-type(2) ~ .flix-choice-wrapper__item {
  min-width: 50%;
}
.flix-choice-wrapper__item:first-child:nth-last-of-type(3), .flix-choice-wrapper__item:first-child:nth-last-of-type(3) ~ .flix-choice-wrapper__item {
  min-width: 33.3333%;
}
.flix-choice-wrapper__item:first-child:nth-last-of-type(4), .flix-choice-wrapper__item:first-child:nth-last-of-type(4) ~ .flix-choice-wrapper__item {
  min-width: 25%;
}
.flix-choice-wrapper--error .flix-choice-wrapper__item {
  border-color: var(--flix-danger-color);
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-choice-wrapper--error .flix-choice-wrapper__info:first-of-type {
  color: var(--flix-danger-dark-color);
}
.flix-choice-wrapper .flix-radio,
.flix-choice-wrapper .flix-checkbox {
  flex-grow: 1;
}
.flix-choice-wrapper .flix-radio__label,
.flix-choice-wrapper .flix-checkbox__label {
  display: block;
  height: 100%;
  min-height: calc(var(--flix-spacing-5) + var(--flix-spacing-2) * 2);
  margin: 0;
  padding: var(--flix-spacing-2);
  padding-left: calc(var(--flix-spacing-5) + var(--flix-spacing-2) * 2);
}
.flix-choice-wrapper .flix-radio__label::before,
.flix-choice-wrapper .flix-checkbox__label::before {
  margin-left: calc(-1 * var(--flix-spacing-5) - var(--flix-spacing-2));
  float: left;
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item {
  border-width: 1px 1px 1px 0;
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:first-of-type {
  border-left-width: 1px;
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:first-of-type:not(:only-of-type) {
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
  border-right-color: var(--flix-line-primary-color);
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:first-of-type:not(:only-of-type) .flix-radio__label,
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:first-of-type:not(:only-of-type) .flix-checkbox__label {
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:last-of-type:not(:only-of-type) {
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
  border-left-color: var(--flix-line-primary-color);
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:last-of-type:not(:only-of-type) .flix-radio__label,
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:last-of-type:not(:only-of-type) .flix-checkbox__label {
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
  border-right-color: var(--flix-line-primary-color);
  border-left-color: var(--flix-line-primary-color);
}
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) .flix-radio__label,
.flix-choice-wrapper:not(.flix-choice-wrapper--column) .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) .flix-checkbox__label {
  border-radius: 0;
}
.flix-choice-wrapper--column {
  flex-direction: column;
}
.flix-choice-wrapper--column .flix-choice-wrapper__item {
  flex-basis: auto;
  min-width: 100%;
  border-width: 0 1px 1px;
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:first-of-type {
  border-top-width: 1px;
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:first-of-type:not(:only-of-type) {
  border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
  border-bottom-color: var(--flix-line-primary-color);
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:first-of-type:not(:only-of-type) .flix-radio__label,
.flix-choice-wrapper--column .flix-choice-wrapper__item:first-of-type:not(:only-of-type) .flix-checkbox__label {
  border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:last-of-type:not(:only-of-type) {
  border-radius: 0 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius);
  border-top-color: var(--flix-line-primary-color);
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:last-of-type:not(:only-of-type) .flix-radio__label,
.flix-choice-wrapper--column .flix-choice-wrapper__item:last-of-type:not(:only-of-type) .flix-checkbox__label {
  border-radius: 0 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius);
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
  border-top-color: var(--flix-line-primary-color);
  border-bottom-color: var(--flix-line-primary-color);
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) .flix-radio__label,
.flix-choice-wrapper--column .flix-choice-wrapper__item:not(:first-of-type):not(:last-of-type) .flix-checkbox__label {
  border-radius: 0;
}
.flix-choice-wrapper--column .flix-choice-wrapper__item:not(:first-of-type) {
  margin-top: 0;
}
.flix-choice-wrapper__content {
  display: none;
  margin: 0 var(--flix-spacing-2) var(--flix-spacing-2);
}
.flix-choice-wrapper__content--expanded {
  display: block;
}
.flix-choice-wrapper__info {
  flex-basis: 100%;
  flex-shrink: 0;
}

.flix-connection__title {
  color: var(--flix-heading-font-color);
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
  margin-bottom: var(--flix-spacing-1);
  text-align: left;
}
.flix-connection__title-arrival {
  font-weight: var(--flix-font-weight-normal);
}
.flix-connection__day {
  font-size: 0.65em;
  font-weight: var(--flix-font-weight-normal);
  line-height: 1;
  vertical-align: text-top;
}
.flix-connection__icon {
  display: none;
  position: absolute;
  z-index: 2;
  top: calc(-1 * var(--flix-spacing-half));
  left: calc(-1 * (var(--flix-spacing-6) + var(--flix-spacing-half)));
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  padding: var(--flix-spacing-half);
  animation-name: connection-marker-pulsing-outline;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  border: 3px solid var(--flix-grayscale-0-color);
  border-radius: 100%;
  background-color: var(--flix-icon-primary-color);
  background-repeat: no-repeat;
  background-position: center;
  background-size: var(--flix-spacing-3);
  box-shadow: 0 0 0 1px var(--flix-icon-primary-color);
  color: var(--flix-grayscale-0-color);
}
@keyframes connection-marker-pulsing-outline {
  100% {
    box-shadow: 0 0 0 var(--flix-spacing-1) rgba(255, 255, 255, 0);
  }
}
.flix-connection__icon:only-child {
  top: calc(-1 * var(--flix-spacing-2));
}
.flix-connection__item {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: var(--flix-spacing-2) 0 0 calc(var(--flix-spacing-7));
}
.flix-connection__item:first-child {
  margin: 0 0 0 calc(var(--flix-spacing-7));
}
.flix-connection__item:first-child + .flix-connection__item:last-child {
  margin: 0 0 0 calc(var(--flix-spacing-7));
}
.flix-connection__item:first-child::after {
  top: var(--flix-spacing-2);
}
.flix-connection__item:last-child::after {
  bottom: calc(100% - var(--flix-spacing-2));
}
.flix-connection__item::before {
  content: "";
  position: absolute;
  z-index: 2;
  top: calc(var(--flix-line-height-primary) / 2);
  left: calc(-1 * var(--flix-spacing-4));
  width: var(--flix-spacing-1);
  height: 2px;
  background-color: var(--flix-icon-primary-color);
  vertical-align: middle;
}
.flix-connection__item:not(.flix-connection__item--current):first-child::before, .flix-connection__item:not(.flix-connection__item--current):last-child::before {
  display: inline-block;
  top: var(--flix-spacing-2);
  left: calc(-1 * var(--flix-spacing-5));
  width: var(--flix-spacing-2);
  height: var(--flix-spacing-2);
  margin-top: calc(-1 * var(--flix-spacing-1));
  border: 2px solid var(--flix-icon-primary-color);
  border-radius: 50%;
  background: var(--flix-bg-primary-color);
}
.flix-connection__item::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: calc(-1 * var(--flix-line-height-primary) / 2);
  bottom: calc(-1 * var(--flix-line-height-primary) / 2);
  left: calc(-1 * var(--flix-spacing-4) - 1px);
  width: 2px;
  background-color: var(--flix-icon-primary-color);
}
.flix-connection__item--slot:not(.flix-connection__item--current)::before {
  display: none;
}
.flix-connection__item--current .flix-connection__icon {
  display: initial;
}
@keyframes connection-marker-pulsing-outline {
  100% {
    box-shadow: 0 0 0 var(--flix-spacing-1) rgba(255, 255, 255, 0);
  }
}
.flix-connection__item--current::after {
  background-color: var(--flix-icon-primary-color);
}
.flix-connection--success .flix-connection__item--current .flix-connection__icon {
  box-shadow: 0 0 0 1px var(--flix-success-color);
}
.flix-connection--success .flix-connection__item--current::after {
  border-top: var(--flix-spacing-2) solid var(--flix-success-color);
}
.flix-connection--success .flix-connection__item::before, .flix-connection--success .flix-connection__item::after, .flix-connection--success .flix-connection__item--current:first-child::before, .flix-connection--success .flix-connection__item--current:last-child::before,
.flix-connection--success .flix-connection__item .flix-connection__icon {
  background-color: var(--flix-success-color);
}
.flix-connection--success .flix-connection__item:not(.flix-connection__item--current):first-child::before, .flix-connection--success .flix-connection__item:not(.flix-connection__item--current):last-child::before {
  border-color: var(--flix-success-color);
  background-color: var(--flix-bg-primary-color);
}
.flix-connection--warning .flix-connection__item--current .flix-connection__icon {
  box-shadow: 0 0 0 1px var(--flix-warning-color);
}
.flix-connection--warning .flix-connection__item--current::after {
  border-top: var(--flix-spacing-2) solid var(--flix-warning-color);
}
.flix-connection--warning .flix-connection__item::before, .flix-connection--warning .flix-connection__item::after, .flix-connection--warning .flix-connection__item--current:first-child::before, .flix-connection--warning .flix-connection__item--current:last-child::before,
.flix-connection--warning .flix-connection__item .flix-connection__icon {
  background-color: var(--flix-warning-color);
}
.flix-connection--warning .flix-connection__item:not(.flix-connection__item--current):first-child::before, .flix-connection--warning .flix-connection__item:not(.flix-connection__item--current):last-child::before {
  border-color: var(--flix-warning-color);
  background-color: var(--flix-bg-primary-color);
}
.flix-connection--danger .flix-connection__item--current .flix-connection__icon {
  box-shadow: 0 0 0 1px var(--flix-danger-color);
}
.flix-connection--danger .flix-connection__item--current::after {
  border-top: var(--flix-spacing-2) solid var(--flix-danger-color);
}
.flix-connection--danger .flix-connection__item::before, .flix-connection--danger .flix-connection__item::after, .flix-connection--danger .flix-connection__item--current:first-child::before, .flix-connection--danger .flix-connection__item--current:last-child::before,
.flix-connection--danger .flix-connection__item .flix-connection__icon {
  background-color: var(--flix-danger-color);
}
.flix-connection--danger .flix-connection__item:not(.flix-connection__item--current):first-child::before, .flix-connection--danger .flix-connection__item:not(.flix-connection__item--current):last-child::before {
  border-color: var(--flix-danger-color);
  background-color: var(--flix-bg-primary-color);
}
.flix-connection--success .flix-connection__item--current:not(.flix-connection__item:last-child)::after, .flix-connection--warning .flix-connection__item--current:not(.flix-connection__item:last-child)::after, .flix-connection--danger .flix-connection__item--current:not(.flix-connection__item:last-child)::after {
  background-color: var(--flix-icon-primary-color);
}
.flix-connection .flix-connection__item--current ~ .flix-connection__item::before, .flix-connection .flix-connection__item--current ~ .flix-connection__item::after {
  background-color: var(--flix-icon-primary-color);
}
.flix-connection .flix-connection__item--current ~ .flix-connection__item:first-child::before, .flix-connection .flix-connection__item--current ~ .flix-connection__item:last-child::before {
  border-color: var(--flix-icon-primary-color);
  background-color: var(--flix-bg-primary-color);
}
.flix-connection__station {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  flex: 1;
}
.flix-connection__time {
  margin-left: var(--flix-spacing-2);
  text-align: right;
}
.flix-connection__time ins {
  text-decoration: none;
}
.flix-connection__time > time,
.flix-connection__time > ins,
.flix-connection__time > del {
  display: block;
}
.flix-connection__time del + ins {
  margin-left: var(--flix-spacing-1);
}
.flix-connection__content {
  width: 100%;
}

.flix-data-table {
  position: relative;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}
.flix-data-table caption {
  padding: var(--flix-spacing-2);
  caption-side: bottom;
  border-top: 1px solid var(--flix-line-primary-color);
  color: var(--flix-secondary-content-color);
  text-align: center;
}
.flix-data-table-controls {
  text-align: center;
}
.flix-data-table-controls > .flix-pager {
  margin-top: var(--flix-spacing-3);
}
.flix-data-table-wrapper {
  max-width: 100%;
  height: 100%;
  border: 1px solid var(--flix-line-primary-color);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
}
.flix-data-table-wrapper--responsive {
  overflow-x: auto;
}
@media (min-width: 600px) {
  .flix-data-table-wrapper--responsive {
    overflow-x: visible;
  }
}
.flix-data-table-wrapper--collapsed {
  display: inline-block;
}
.flix-data-table-wrapper--scrollable {
  overflow-y: auto;
}
.flix-data-table__header td,
.flix-data-table__header th {
  padding: var(--flix-spacing-2) var(--flix-spacing-2);
  border: solid var(--flix-line-primary-color);
  border-width: 0 0 1px;
  text-align: left;
  color: var(--flix-heading-font-color);
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
  white-space: nowrap;
}
.flix-data-table__header-btn {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: inherit;
}
.flix-data-table__footer td,
.flix-data-table__footer th {
  padding: var(--flix-spacing-2) var(--flix-spacing-2);
  border: solid var(--flix-line-primary-color);
  border-width: 0 0 1px;
  text-align: left;
  color: var(--flix-heading-font-color);
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
  border-width: 1px 0 0;
}
.flix-data-table > tbody > tr td,
.flix-data-table > tbody > tr th {
  padding: var(--flix-spacing-2) var(--flix-spacing-2);
  border: solid var(--flix-line-primary-color);
  border-width: 0 0 1px;
  text-align: left;
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
}
.flix-data-table > tbody > tr:last-child td,
.flix-data-table > tbody > tr:last-child th {
  border-width: 0;
}
.flix-data-table > tbody > tr:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-data-table > tbody > tr.flix-data-table__row--selected, .flix-data-table > tbody > tr.flix-data-table__row--selected:hover {
  background: var(--flix-highlight-color);
}
.flix-data-table:not(.flix-data-table--plain) > tbody > tr:nth-of-type(odd) {
  background: var(--flix-grayscale-10-color);
}
.flix-data-table:not(.flix-data-table--plain) > tbody > tr:nth-of-type(odd):hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-data-table th:first-of-type > .flix-checkbox .flix-checkbox__label::before,
.flix-data-table td:first-of-type > .flix-checkbox .flix-checkbox__label::before {
  margin-right: 0;
}
.flix-data-table--sticky-header .flix-data-table__header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  top: 0;
  background: var(--flix-box-bg-color);
}
.flix-data-table--sticky-header .flix-data-table__header > tr > th, .flix-data-table--sticky-header .flix-data-table__header > tr > td {
  border-bottom: var(--flix-spacing-half) solid var(--flix-line-primary-color);
}
.flix-data-table--small caption {
  padding: var(--flix-spacing-1);
}
.flix-data-table--small .flix-data-table__header td,
.flix-data-table--small .flix-data-table__header th, .flix-data-table--small .flix-data-table__row td,
.flix-data-table--small .flix-data-table__row th, .flix-data-table--small .flix-data-table__footer td,
.flix-data-table--small .flix-data-table__footer th {
  padding: var(--flix-spacing-1);
}

.flix-divider {
  height: 1px;
  border-style: none;
  background-color: var(--flix-line-primary-color);
}

.flix-dropdown {
  display: inline-block;
  position: relative;
  font-family: inherit;
  cursor: pointer;
}
.flix-dropdown__items {
  z-index: 10;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 15.75rem;
  min-height: 44px;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  text-decoration: none;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column;
  padding: var(--flix-spacing-4) var(--flix-spacing-2) var(--flix-spacing-2);
  top: 100%;
  bottom: auto;
  position: absolute;
  margin: calc(-1 * var(--flix-spacing-1)) 0 0;
  display: none;
}
.flix-dropdown__items:not([hidden]) {
  display: flex;
}
.flix-dropdown__items::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-dropdown__items::before {
  top: var(--flix-spacing-2);
  right: 0;
  bottom: 0;
  left: 0;
}
.flix-dropdown__items::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0;
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
  top: var(--flix-spacing-1);
  bottom: auto;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
.flix-dropdown--active .flix-dropdown__items {
  display: block;
}
.flix-dropdown-item, .flix-header-nav-subnav__item {
  margin: 0 calc(-1 * var(--flix-spacing-2));
  padding: var(--flix-spacing-half) var(--flix-spacing-4);
  line-height: var(--flix-line-height-primary);
  list-style: none;
  text-align: left;
}
.flix-dropdown-item__link, .flix-header-nav-subnav__link {
  display: flex;
  position: relative;
  padding: var(--flix-spacing-half);
  color: var(--flix-content-secondary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  text-decoration: none;
  gap: var(--flix-spacing-1);
  cursor: pointer;
}
.flix-dropdown-item__link:hover, .flix-header-nav-subnav__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
  border-radius: var(--flix-primary-border-radius);
}
.flix-dropdown-item__link:active, .flix-header-nav-subnav__link:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
  border-radius: var(--flix-primary-border-radius);
}
.flix-dropdown-item__link:focus, .flix-header-nav-subnav__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
}
.flix-dropdown-item__link[aria-current], [aria-current].flix-header-nav-subnav__link {
  color: var(--flix-content-primary-color);
}
.flix-dropdown-item__link[aria-current]::before, [aria-current].flix-header-nav-subnav__link::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(-1 * var(--flix-spacing-4));
  border-left: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-dropdown-item__link .flix-icon, .flix-header-nav-subnav__link .flix-icon {
  vertical-align: middle;
}
.flix-dropdown-item__link .flix-icon::before, .flix-header-nav-subnav__link .flix-icon::before {
  margin: 0;
}
.flix-dropdown--top .flix-dropdown__items {
  padding: var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-4);
  top: auto;
  bottom: 100%;
  margin: 0 0 calc(-1 * var(--flix-spacing-1));
}
.flix-dropdown--top .flix-dropdown__items::before {
  top: 0;
  right: 0;
  bottom: var(--flix-spacing-2);
  left: 0;
}
.flix-dropdown--top .flix-dropdown__items::after {
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: 0;
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
  top: auto;
  bottom: var(--flix-spacing-1);
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}
.flix-dropdown--left .flix-dropdown__items {
  right: calc(-1 * var(--flix-spacing-1));
  left: auto;
  transform: none;
}
.flix-dropdown--left .flix-dropdown__items::after {
  right: var(--flix-spacing-3);
  left: auto;
  transform: none;
}
@supports (right: min(25%, var(--flix-spacing-3))) {
  .flix-dropdown--left .flix-dropdown__items::after {
    right: min(25%, var(--flix-spacing-3));
  }
}
.flix-dropdown--right .flix-dropdown__items {
  right: auto;
  left: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-dropdown--right .flix-dropdown__items::after {
  left: var(--flix-spacing-3);
  transform: none;
}
@supports (left: min(25%, var(--flix-spacing-3))) {
  .flix-dropdown--right .flix-dropdown__items::after {
    left: min(25%, var(--flix-spacing-3));
  }
}

.flix-fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
.flix-fieldset__info:not(:empty) {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-fieldset__info:not(:empty) ~ .flix-fieldset__info {
  margin-top: var(--flix-spacing-half);
}
.flix-fieldset--error .flix-fieldset__info {
  color: var(--flix-danger-dark-color);
}
.flix-fieldset__info ~ .flix-fieldset__info {
  color: var(--flix-content-primary-color);
}
.flix-fieldset:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-4);
}
.flix-fieldset__item:where(:not(:first-of-type)) {
  margin-top: var(--flix-spacing-2);
}
.flix-fieldset__item--space-half {
  margin-top: var(--flix-spacing-half);
}
.flix-fieldset__item--space-1 {
  margin-top: var(--flix-spacing-1);
}
.flix-fieldset__item--space-2 {
  margin-top: var(--flix-spacing-2);
}
.flix-fieldset__item--space-3 {
  margin-top: var(--flix-spacing-3);
}
.flix-fieldset__item--space-4 {
  margin-top: var(--flix-spacing-4);
}
.flix-fieldset__item--space-5 {
  margin-top: var(--flix-spacing-5);
}
.flix-fieldset__item--space-6 {
  margin-top: var(--flix-spacing-6);
}
.flix-fieldset__item--space-7 {
  margin-top: var(--flix-spacing-7);
}
.flix-fieldset__item--space-8 {
  margin-top: var(--flix-spacing-8);
}
.flix-fieldset__item--space-9 {
  margin-top: var(--flix-spacing-9);
}
.flix-fieldset__item--space-10 {
  margin-top: var(--flix-spacing-10);
}
.flix-fieldset__item--space-11 {
  margin-top: var(--flix-spacing-11);
}
.flix-fieldset__item--space-12 {
  margin-top: var(--flix-spacing-12);
}
.flix-fieldset__item--space-flush {
  margin-top: 0;
}
.flix-fieldset--horizontal::after {
  content: "";
  display: table;
  clear: both;
}
.flix-fieldset--horizontal .flix-fieldset__item {
  margin-top: 0;
  float: left;
}
.flix-fieldset--horizontal .flix-fieldset__item:where(:not(:first-of-type)) {
  margin-left: var(--flix-spacing-4);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-half {
  margin-left: var(--flix-spacing-half);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-1 {
  margin-left: var(--flix-spacing-1);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-2 {
  margin-left: var(--flix-spacing-2);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-3 {
  margin-left: var(--flix-spacing-3);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-4 {
  margin-left: var(--flix-spacing-4);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-5 {
  margin-left: var(--flix-spacing-5);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-6 {
  margin-left: var(--flix-spacing-6);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-7 {
  margin-left: var(--flix-spacing-7);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-8 {
  margin-left: var(--flix-spacing-8);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-9 {
  margin-left: var(--flix-spacing-9);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-10 {
  margin-left: var(--flix-spacing-10);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-11 {
  margin-left: var(--flix-spacing-11);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-12 {
  margin-left: var(--flix-spacing-12);
}
.flix-fieldset--horizontal .flix-fieldset__item--space-flush {
  margin-left: 0;
}
.flix-fieldset__info {
  width: 100%;
  float: left;
  clear: both;
}

.flix-sr-only, .flix-range__label--hidden, .flix-sr-only-focusable:not(:focus), .flix-skip-link:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  border-width: 0 !important;
  opacity: 0 !important;
  white-space: nowrap !important;
  -webkit-clip-path: inset(50%) !important;
          clip-path: inset(50%) !important;
}

.flix-sr-only-focusable, .flix-skip-link {
  transition: opacity 250ms ease;
  opacity: 1 !important;
}
.flix-has-text-centered {
  text-align: center;
}
.flix-has-text-left {
  text-align: left;
}
.flix-has-text-right {
  text-align: right;
}

.flix-hidden {
  display: none;
}

@media (min-width: 0) and (max-width: 389px) {
  .flix-hidden-zero {
    display: none;
  }
}
@media (min-width: 390px) and (max-width: 599px) {
  .flix-hidden-xs {
    display: none;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  .flix-hidden-sm {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .flix-hidden-md {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .flix-hidden-lg {
    display: none;
  }
}
@media (min-width: 1200px) {
  .flix-hidden-xl {
    display: none;
  }
}
.flix-reset-btn {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-reset-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.flix-reset-list--inline {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.flix-reset-list--inline > li {
  display: inline-block;
}

.flix-space-half-top {
  margin-top: var(--flix-spacing-half);
}
.flix-space-half-bottom {
  margin-bottom: var(--flix-spacing-half);
}
.flix-space-half-left {
  margin-left: var(--flix-spacing-half);
}
.flix-space-half-right {
  margin-right: var(--flix-spacing-half);
}
.flix-space-1-top {
  margin-top: var(--flix-spacing-1);
}
.flix-space-1-bottom {
  margin-bottom: var(--flix-spacing-1);
}
.flix-space-1-left {
  margin-left: var(--flix-spacing-1);
}
.flix-space-1-right {
  margin-right: var(--flix-spacing-1);
}
.flix-space-2-top {
  margin-top: var(--flix-spacing-2);
}
.flix-space-2-bottom {
  margin-bottom: var(--flix-spacing-2);
}
.flix-space-2-left {
  margin-left: var(--flix-spacing-2);
}
.flix-space-2-right {
  margin-right: var(--flix-spacing-2);
}
.flix-space-3-top {
  margin-top: var(--flix-spacing-3);
}
.flix-space-3-bottom {
  margin-bottom: var(--flix-spacing-3);
}
.flix-space-3-left {
  margin-left: var(--flix-spacing-3);
}
.flix-space-3-right {
  margin-right: var(--flix-spacing-3);
}
.flix-space-4-top {
  margin-top: var(--flix-spacing-4);
}
.flix-space-4-bottom {
  margin-bottom: var(--flix-spacing-4);
}
.flix-space-4-left {
  margin-left: var(--flix-spacing-4);
}
.flix-space-4-right {
  margin-right: var(--flix-spacing-4);
}
.flix-space-5-top {
  margin-top: var(--flix-spacing-5);
}
.flix-space-5-bottom {
  margin-bottom: var(--flix-spacing-5);
}
.flix-space-5-left {
  margin-left: var(--flix-spacing-5);
}
.flix-space-5-right {
  margin-right: var(--flix-spacing-5);
}
.flix-space-6-top {
  margin-top: var(--flix-spacing-6);
}
.flix-space-6-bottom {
  margin-bottom: var(--flix-spacing-6);
}
.flix-space-6-left {
  margin-left: var(--flix-spacing-6);
}
.flix-space-6-right {
  margin-right: var(--flix-spacing-6);
}
.flix-space-7-top {
  margin-top: var(--flix-spacing-7);
}
.flix-space-7-bottom {
  margin-bottom: var(--flix-spacing-7);
}
.flix-space-7-left {
  margin-left: var(--flix-spacing-7);
}
.flix-space-7-right {
  margin-right: var(--flix-spacing-7);
}
.flix-space-8-top {
  margin-top: var(--flix-spacing-8);
}
.flix-space-8-bottom {
  margin-bottom: var(--flix-spacing-8);
}
.flix-space-8-left {
  margin-left: var(--flix-spacing-8);
}
.flix-space-8-right {
  margin-right: var(--flix-spacing-8);
}
.flix-space-9-top {
  margin-top: var(--flix-spacing-9);
}
.flix-space-9-bottom {
  margin-bottom: var(--flix-spacing-9);
}
.flix-space-9-left {
  margin-left: var(--flix-spacing-9);
}
.flix-space-9-right {
  margin-right: var(--flix-spacing-9);
}
.flix-space-10-top {
  margin-top: var(--flix-spacing-10);
}
.flix-space-10-bottom {
  margin-bottom: var(--flix-spacing-10);
}
.flix-space-10-left {
  margin-left: var(--flix-spacing-10);
}
.flix-space-10-right {
  margin-right: var(--flix-spacing-10);
}
.flix-space-11-top {
  margin-top: var(--flix-spacing-11);
}
.flix-space-11-bottom {
  margin-bottom: var(--flix-spacing-11);
}
.flix-space-11-left {
  margin-left: var(--flix-spacing-11);
}
.flix-space-11-right {
  margin-right: var(--flix-spacing-11);
}
.flix-space-12-top {
  margin-top: var(--flix-spacing-12);
}
.flix-space-12-bottom {
  margin-bottom: var(--flix-spacing-12);
}
.flix-space-12-left {
  margin-left: var(--flix-spacing-12);
}
.flix-space-12-right {
  margin-right: var(--flix-spacing-12);
}
.flix-space-flush-top {
  margin-top: 0;
}
.flix-space-flush-bottom {
  margin-bottom: 0;
}
.flix-space-flush-left {
  margin-left: 0;
}
.flix-space-flush-right {
  margin-right: 0;
}

small.flix-fineprint {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-fineprint);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-fineprint);
  display: inline-block;
  margin: 0 0 var(--flix-spacing-2);
}
small.flix-fineprint--space-flush {
  margin-bottom: 0;
}

.flix-footer {
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  max-width: var(--flix-page-max-width);
  margin: 0 auto;
  padding: 0 var(--flix-spacing-2);
  overflow: hidden;
}
.flix-footer--fullwidth {
  max-width: none;
}
.flix-footer__img {
  display: block;
  position: relative;
  top: 0;
  left: 50%;
  width: 100%;
  min-height: 240px;
  margin-top: var(--flix-spacing-2);
  transform: translateX(-50%);
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 600px) {
  .flix-footer__img {
    display: none;
  }
}
@media (min-width: 1024px) {
  .flix-footer__img {
    left: 0;
    width: 100%;
    transform: none;
  }
}
.flix-footer__img--desktop {
  display: none;
}
@media (min-width: 600px) {
  .flix-footer__img--desktop {
    display: block;
  }
}

.flix-footer-nav {
  position: relative;
  border-bottom: 1px solid var(--flix-line-primary-color);
  font-family: inherit;
}
@media (min-width: 600px) {
  .flix-footer-nav {
    border-bottom: 0;
  }
}
.flix-footer-nav--first {
  border-top: 1px solid var(--flix-line-primary-color);
}
@media (min-width: 600px) {
  .flix-footer-nav--first {
    border-top: 0;
  }
}
.flix-footer-nav__items {
  padding-right: var(--flix-spacing-2);
  padding-left: var(--flix-spacing-2);
  list-style: none;
}
.flix-footer-nav__title ~ .flix-footer-nav__items:not(.flix-footer-nav__items--open) {
  display: none;
}
@media (min-width: 600px) {
  .flix-footer-nav__title ~ .flix-footer-nav__items:not(.flix-footer-nav__items--open) {
    display: block;
  }
}

@media (min-width: 600px) {
  .flix-footer-nav__items {
    display: block;
    padding-right: 0;
    padding-left: 0;
  }
}
.flix-footer-nav__items:first-child {
  padding-top: var(--flix-spacing-2);
}
.flix-footer-nav__items--open {
  display: block;
}
.flix-footer-nav__item {
  padding-bottom: var(--flix-spacing-2);
}
.flix-footer-nav__link {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  margin-left: calc(-1 * var(--flix-spacing-half));
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  font-weight: var(--flix-font-weight-semibold);
  text-decoration: none;
}
.flix-footer-nav__link .flix-icon:first-child {
  margin-right: var(--flix-spacing-half);
}
.flix-footer-nav__link .flix-icon:last-child {
  margin-left: var(--flix-spacing-half);
}
.flix-footer-nav__link .flix-icon::before {
  margin: 0;
}
.flix-footer-nav__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-footer-nav__link:active, .flix-footer-nav__link:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-footer-nav__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-footer-nav__title {
  color: var(--flix-heading-font-color);
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
  position: relative;
  padding: var(--flix-spacing-2);
}
.flix-footer-nav__title .flix-icon:first-child {
  margin-right: var(--flix-spacing-half);
}
.flix-footer-nav__title .flix-icon:last-child {
  margin-left: var(--flix-spacing-half);
}
.flix-footer-nav__title .flix-icon::before {
  margin: 0;
}
.flix-footer-nav__title:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-footer-nav__title::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  position: absolute;
  top: 50%;
  right: var(--flix-spacing-2);
  margin-top: -11px;
  background: var(--flix-icon-arrow-down) no-repeat center;
}
@media (min-width: 600px) {
  .flix-footer-nav__title {
    padding-right: 0;
    padding-left: 0;
  }
  .flix-footer-nav__title::after {
    display: none;
  }
  .flix-footer-nav__title:hover {
    background: transparent;
  }
}
.flix-footer-nav__title--open::after {
  transform: rotate(180deg);
}

.flix-form-row {
  display: block;
  position: relative;
  width: 100%;
}
.flix-form-row:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-4);
}
.flix-form-row--space-half {
  margin-bottom: var(--flix-spacing-half);
}
.flix-form-row--space-1 {
  margin-bottom: var(--flix-spacing-1);
}
.flix-form-row--space-2 {
  margin-bottom: var(--flix-spacing-2);
}
.flix-form-row--space-3 {
  margin-bottom: var(--flix-spacing-3);
}
.flix-form-row--space-4 {
  margin-bottom: var(--flix-spacing-4);
}
.flix-form-row--space-5 {
  margin-bottom: var(--flix-spacing-5);
}
.flix-form-row--space-6 {
  margin-bottom: var(--flix-spacing-6);
}
.flix-form-row--space-7 {
  margin-bottom: var(--flix-spacing-7);
}
.flix-form-row--space-8 {
  margin-bottom: var(--flix-spacing-8);
}
.flix-form-row--space-9 {
  margin-bottom: var(--flix-spacing-9);
}
.flix-form-row--space-10 {
  margin-bottom: var(--flix-spacing-10);
}
.flix-form-row--space-11 {
  margin-bottom: var(--flix-spacing-11);
}
.flix-form-row--space-12 {
  margin-bottom: var(--flix-spacing-12);
}
.flix-form-row--space-flush {
  margin-bottom: 0;
}
.flix-form-row--small {
  width: 50%;
}

.flix-grid-container {
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid-container--gutter-4 {
  padding-right: 12px;
  padding-left: 12px;
}
.flix-grid {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 12px);
  margin-right: -6px;
  margin-left: -6px;
}
.flix-grid--align-bottom {
  align-items: flex-end;
}
.flix-grid--align-top {
  align-items: flex-start;
}
.flix-grid--align-center {
  align-items: center;
}
.flix-grid--justify-center {
  justify-content: center;
}
.flix-grid--justify-left {
  justify-content: flex-start;
}
.flix-grid--justify-right {
  justify-content: flex-end;
}
.flix-grid--justify-space-between {
  justify-content: space-between;
}
.flix-grid--gutter-4 {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 24px);
  margin-right: -12px;
  margin-left: -12px;
}
.flix-col-1 {
  width: 8.3333333333%;
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid--gutter-4 .flix-col-1 {
  width: 8.3333333333%;
  padding-right: 12px;
  padding-left: 12px;
}
.flix-push-1 {
  margin-left: 8.3333333333%;
}
.flix-col-2 {
  width: 16.6666666667%;
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid--gutter-4 .flix-col-2 {
  width: 16.6666666667%;
  padding-right: 12px;
  padding-left: 12px;
}
.flix-push-2 {
  margin-left: 16.6666666667%;
}
.flix-col-3 {
  width: 25%;
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid--gutter-4 .flix-col-3 {
  width: 25%;
  padding-right: 12px;
  padding-left: 12px;
}
.flix-push-3 {
  margin-left: 25%;
}
.flix-col-4 {
  width: 33.3333333333%;
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid--gutter-4 .flix-col-4 {
  width: 33.3333333333%;
  padding-right: 12px;
  padding-left: 12px;
}
.flix-push-4 {
  margin-left: 33.3333333333%;
}
.flix-col-5 {
  width: 41.6666666667%;
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid--gutter-4 .flix-col-5 {
  width: 41.6666666667%;
  padding-right: 12px;
  padding-left: 12px;
}
.flix-push-5 {
  margin-left: 41.6666666667%;
}
.flix-col-6 {
  width: 50%;
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid--gutter-4 .flix-col-6 {
  width: 50%;
  padding-right: 12px;
  padding-left: 12px;
}
.flix-push-6 {
  margin-left: 50%;
}
.flix-col-7 {
  width: 58.3333333333%;
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid--gutter-4 .flix-col-7 {
  width: 58.3333333333%;
  padding-right: 12px;
  padding-left: 12px;
}
.flix-push-7 {
  margin-left: 58.3333333333%;
}
.flix-col-8 {
  width: 66.6666666667%;
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid--gutter-4 .flix-col-8 {
  width: 66.6666666667%;
  padding-right: 12px;
  padding-left: 12px;
}
.flix-push-8 {
  margin-left: 66.6666666667%;
}
.flix-col-9 {
  width: 75%;
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid--gutter-4 .flix-col-9 {
  width: 75%;
  padding-right: 12px;
  padding-left: 12px;
}
.flix-push-9 {
  margin-left: 75%;
}
.flix-col-10 {
  width: 83.3333333333%;
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid--gutter-4 .flix-col-10 {
  width: 83.3333333333%;
  padding-right: 12px;
  padding-left: 12px;
}
.flix-push-10 {
  margin-left: 83.3333333333%;
}
.flix-col-11 {
  width: 91.6666666667%;
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid--gutter-4 .flix-col-11 {
  width: 91.6666666667%;
  padding-right: 12px;
  padding-left: 12px;
}
.flix-push-11 {
  margin-left: 91.6666666667%;
}
.flix-col-12 {
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
}
.flix-grid--gutter-4 .flix-col-12 {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.flix-push-12 {
  margin-left: 100%;
}
@media (min-width: 390px) {
  .flix-col-1-xs {
    width: 8.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-1-xs {
    width: 8.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-1-xs {
    margin-left: 8.3333333333%;
  }
  .flix-col-2-xs {
    width: 16.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-2-xs {
    width: 16.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-2-xs {
    margin-left: 16.6666666667%;
  }
  .flix-col-3-xs {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-3-xs {
    width: 25%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-3-xs {
    margin-left: 25%;
  }
  .flix-col-4-xs {
    width: 33.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-4-xs {
    width: 33.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-4-xs {
    margin-left: 33.3333333333%;
  }
  .flix-col-5-xs {
    width: 41.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-5-xs {
    width: 41.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-5-xs {
    margin-left: 41.6666666667%;
  }
  .flix-col-6-xs {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-6-xs {
    width: 50%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-6-xs {
    margin-left: 50%;
  }
  .flix-col-7-xs {
    width: 58.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-7-xs {
    width: 58.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-7-xs {
    margin-left: 58.3333333333%;
  }
  .flix-col-8-xs {
    width: 66.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-8-xs {
    width: 66.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-8-xs {
    margin-left: 66.6666666667%;
  }
  .flix-col-9-xs {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-9-xs {
    width: 75%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-9-xs {
    margin-left: 75%;
  }
  .flix-col-10-xs {
    width: 83.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-10-xs {
    width: 83.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-10-xs {
    margin-left: 83.3333333333%;
  }
  .flix-col-11-xs {
    width: 91.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-11-xs {
    width: 91.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-11-xs {
    margin-left: 91.6666666667%;
  }
  .flix-col-12-xs {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-12-xs {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-12-xs {
    margin-left: 100%;
  }
}
@media (min-width: 600px) {
  .flix-col-1-sm {
    width: 8.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-1-sm {
    width: 8.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-1-sm {
    margin-left: 8.3333333333%;
  }
  .flix-col-2-sm {
    width: 16.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-2-sm {
    width: 16.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-2-sm {
    margin-left: 16.6666666667%;
  }
  .flix-col-3-sm {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-3-sm {
    width: 25%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-3-sm {
    margin-left: 25%;
  }
  .flix-col-4-sm {
    width: 33.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-4-sm {
    width: 33.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-4-sm {
    margin-left: 33.3333333333%;
  }
  .flix-col-5-sm {
    width: 41.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-5-sm {
    width: 41.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-5-sm {
    margin-left: 41.6666666667%;
  }
  .flix-col-6-sm {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-6-sm {
    width: 50%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-6-sm {
    margin-left: 50%;
  }
  .flix-col-7-sm {
    width: 58.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-7-sm {
    width: 58.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-7-sm {
    margin-left: 58.3333333333%;
  }
  .flix-col-8-sm {
    width: 66.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-8-sm {
    width: 66.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-8-sm {
    margin-left: 66.6666666667%;
  }
  .flix-col-9-sm {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-9-sm {
    width: 75%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-9-sm {
    margin-left: 75%;
  }
  .flix-col-10-sm {
    width: 83.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-10-sm {
    width: 83.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-10-sm {
    margin-left: 83.3333333333%;
  }
  .flix-col-11-sm {
    width: 91.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-11-sm {
    width: 91.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-11-sm {
    margin-left: 91.6666666667%;
  }
  .flix-col-12-sm {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-12-sm {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-12-sm {
    margin-left: 100%;
  }
}
@media (min-width: 768px) {
  .flix-col-1-md {
    width: 8.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-1-md {
    width: 8.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-1-md {
    margin-left: 8.3333333333%;
  }
  .flix-col-2-md {
    width: 16.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-2-md {
    width: 16.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-2-md {
    margin-left: 16.6666666667%;
  }
  .flix-col-3-md {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-3-md {
    width: 25%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-3-md {
    margin-left: 25%;
  }
  .flix-col-4-md {
    width: 33.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-4-md {
    width: 33.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-4-md {
    margin-left: 33.3333333333%;
  }
  .flix-col-5-md {
    width: 41.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-5-md {
    width: 41.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-5-md {
    margin-left: 41.6666666667%;
  }
  .flix-col-6-md {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-6-md {
    width: 50%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-6-md {
    margin-left: 50%;
  }
  .flix-col-7-md {
    width: 58.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-7-md {
    width: 58.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-7-md {
    margin-left: 58.3333333333%;
  }
  .flix-col-8-md {
    width: 66.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-8-md {
    width: 66.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-8-md {
    margin-left: 66.6666666667%;
  }
  .flix-col-9-md {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-9-md {
    width: 75%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-9-md {
    margin-left: 75%;
  }
  .flix-col-10-md {
    width: 83.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-10-md {
    width: 83.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-10-md {
    margin-left: 83.3333333333%;
  }
  .flix-col-11-md {
    width: 91.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-11-md {
    width: 91.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-11-md {
    margin-left: 91.6666666667%;
  }
  .flix-col-12-md {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-12-md {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-12-md {
    margin-left: 100%;
  }
}
@media (min-width: 1024px) {
  .flix-col-1-lg {
    width: 8.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-1-lg {
    width: 8.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-1-lg {
    margin-left: 8.3333333333%;
  }
  .flix-col-2-lg {
    width: 16.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-2-lg {
    width: 16.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-2-lg {
    margin-left: 16.6666666667%;
  }
  .flix-col-3-lg {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-3-lg {
    width: 25%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-3-lg {
    margin-left: 25%;
  }
  .flix-col-4-lg {
    width: 33.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-4-lg {
    width: 33.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-4-lg {
    margin-left: 33.3333333333%;
  }
  .flix-col-5-lg {
    width: 41.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-5-lg {
    width: 41.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-5-lg {
    margin-left: 41.6666666667%;
  }
  .flix-col-6-lg {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-6-lg {
    width: 50%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-6-lg {
    margin-left: 50%;
  }
  .flix-col-7-lg {
    width: 58.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-7-lg {
    width: 58.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-7-lg {
    margin-left: 58.3333333333%;
  }
  .flix-col-8-lg {
    width: 66.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-8-lg {
    width: 66.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-8-lg {
    margin-left: 66.6666666667%;
  }
  .flix-col-9-lg {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-9-lg {
    width: 75%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-9-lg {
    margin-left: 75%;
  }
  .flix-col-10-lg {
    width: 83.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-10-lg {
    width: 83.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-10-lg {
    margin-left: 83.3333333333%;
  }
  .flix-col-11-lg {
    width: 91.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-11-lg {
    width: 91.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-11-lg {
    margin-left: 91.6666666667%;
  }
  .flix-col-12-lg {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-12-lg {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-12-lg {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .flix-col-1-xl {
    width: 8.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-1-xl {
    width: 8.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-1-xl {
    margin-left: 8.3333333333%;
  }
  .flix-col-2-xl {
    width: 16.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-2-xl {
    width: 16.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-2-xl {
    margin-left: 16.6666666667%;
  }
  .flix-col-3-xl {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-3-xl {
    width: 25%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-3-xl {
    margin-left: 25%;
  }
  .flix-col-4-xl {
    width: 33.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-4-xl {
    width: 33.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-4-xl {
    margin-left: 33.3333333333%;
  }
  .flix-col-5-xl {
    width: 41.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-5-xl {
    width: 41.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-5-xl {
    margin-left: 41.6666666667%;
  }
  .flix-col-6-xl {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-6-xl {
    width: 50%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-6-xl {
    margin-left: 50%;
  }
  .flix-col-7-xl {
    width: 58.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-7-xl {
    width: 58.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-7-xl {
    margin-left: 58.3333333333%;
  }
  .flix-col-8-xl {
    width: 66.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-8-xl {
    width: 66.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-8-xl {
    margin-left: 66.6666666667%;
  }
  .flix-col-9-xl {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-9-xl {
    width: 75%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-9-xl {
    margin-left: 75%;
  }
  .flix-col-10-xl {
    width: 83.3333333333%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-10-xl {
    width: 83.3333333333%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-10-xl {
    margin-left: 83.3333333333%;
  }
  .flix-col-11-xl {
    width: 91.6666666667%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-11-xl {
    width: 91.6666666667%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-11-xl {
    margin-left: 91.6666666667%;
  }
  .flix-col-12-xl {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px;
  }
  .flix-grid--gutter-4 .flix-col-12-xl {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
  }
  .flix-push-12-xl {
    margin-left: 100%;
  }
}
.flix-col {
  flex: 1;
  margin: 0 6px;
}
.flix-col--inline {
  flex-basis: auto;
  flex-grow: 0;
}
.flix-grid--gutter-4 .flix-col {
  margin: 0 12px;
}

.flix-header-bar {
  z-index: 1000;
  background: var(--flix-header-bg-color);
  -webkit-font-smoothing: subpixel-antialiased;
  text-align: center;
}
.flix-header-bar--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.flix-header-bar__content {
  display: inline-flex;
  position: relative;
  align-items: center;
  width: 100%;
  min-width: var(--flix-page-min-width);
  max-width: var(--flix-page-max-width);
  height: var(--flix-spacing-10);
  padding: 0 var(--flix-spacing-2);
  text-align: left;
}
.flix-header-bar--fullwidth .flix-header-bar__content {
  max-width: none;
}

.flix-header-brand {
  display: flex;
  grid-column: 1/span 12;
  grid-row: 1;
  align-items: center;
  justify-content: center;
  height: var(--flix-spacing-10);
}
@media (min-width: 1200px) {
  .flix-header-brand {
    grid-column: 1/span 2;
    justify-content: flex-start;
  }
}
.flix-header-brand__link {
  width: -moz-fit-content;
  width: fit-content;
  padding: var(--flix-spacing-1);
  text-align: center;
}
@media (min-width: 1200px) {
  .flix-header-brand__link {
    text-align: left;
  }
}
.flix-header-brand__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-header-brand__img {
  width: auto;
  height: var(--flix-spacing-4);
  vertical-align: middle;
}
.flix-header-brand--tall .flix-header-brand__img {
  height: var(--flix-spacing-6);
}
.flix-header-brand--square .flix-header-brand__img {
  width: var(--flix-spacing-6);
  height: var(--flix-spacing-6);
}

.flix-btn, .flix-skip-link {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  min-width: 142px;
  max-width: 100%;
  height: var(--flix-input-height-mobile);
  padding: 0 var(--flix-spacing-2);
  overflow: hidden;
  column-gap: var(--flix-spacing-half);
  border: 1px solid var(--flix-grayscale-50-color);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-button);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-input-height-mobile);
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: background 250ms ease;
}
.flix-btn:enabled:hover, .flix-skip-link:enabled:hover, .flix-btn[href]:hover, [href].flix-skip-link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-btn:active, .flix-skip-link:active, .flix-btn:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-btn:focus, .flix-skip-link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-btn.flix-btn--loading, .flix-btn--loading.flix-skip-link {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
@media (min-width: 600px) {
  .flix-btn, .flix-skip-link {
    width: auto;
  }
}
@media (min-width: 1200px) {
  .flix-btn, .flix-skip-link {
    height: var(--flix-input-height-desktop);
    line-height: var(--flix-input-height-desktop);
  }
}
.flix-btn + .flix-btn, .flix-skip-link + .flix-btn, .flix-btn + .flix-skip-link, .flix-skip-link + .flix-skip-link {
  margin-top: var(--flix-spacing-2);
}
@media (min-width: 600px) {
  .flix-btn + .flix-btn, .flix-skip-link + .flix-btn, .flix-btn + .flix-skip-link, .flix-skip-link + .flix-skip-link {
    margin-top: 0;
    margin-left: var(--flix-spacing-2);
  }
}
.flix-btn--sm {
  width: auto;
  min-width: auto;
  height: var(--flix-spacing-4);
  font-size: var(--flix-font-size-small);
}
.flix-btn--sm.flix-btn--square {
  width: var(--flix-spacing-4);
  min-width: var(--flix-spacing-4);
}
.flix-btn--md {
  width: auto;
  height: var(--flix-spacing-6);
}
.flix-btn--md.flix-btn--square {
  width: var(--flix-spacing-6);
  min-width: var(--flix-spacing-6);
}
.flix-btn--lg {
  width: auto;
  height: var(--flix-spacing-8);
  padding: 0 var(--flix-spacing-4);
}
.flix-btn--lg.flix-btn--square {
  width: var(--flix-spacing-8);
  min-width: var(--flix-spacing-8);
}
.flix-btn--block {
  display: flex;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}
.flix-btn--primary {
  border-color: var(--flix-button-primary-color);
  background: var(--flix-button-primary-color);
  color: var(--flix-button-label-color);
}
.flix-btn--primary.flix-btn--loading::before {
  border-left-color: var(--flix-button-label-color);
}
.flix-btn--secondary {
  border-color: var(--flix-button-secondary-color);
  background: var(--flix-button-secondary-color);
  color: var(--flix-button-label-color);
}
.flix-btn--secondary.flix-btn--loading::before {
  border-left-color: var(--flix-button-label-color);
}
.flix-btn--tertiary {
  border-color: var(--flix-button-secondary-color);
  background: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
}
.flix-btn--tertiary.flix-btn--loading::before {
  border-left-color: var(--flix-content-primary-color);
}
.flix-btn--danger {
  border-color: var(--flix-danger-color);
  background: var(--flix-danger-color);
  color: var(--flix-grayscale-0-color);
}
.flix-btn--danger.flix-btn--loading::before {
  border-left-color: var(--flix-grayscale-0-color);
}
.flix-btn:disabled, .flix-skip-link:disabled, .flix-btn--disabled {
  opacity: var(--flix-disabled-element-opacity);
  cursor: not-allowed;
}
.flix-btn--loading::before {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  margin-right: var(--flix-spacing-half);
  margin-left: var(--flix-spacing-half);
  animation: btn-spinner-rotate 1s infinite linear, btn-spinner-grow 250ms;
  border: var(--flix-spacing-half) solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  border-left-color: var(--flix-content-primary-color);
}
@keyframes btn-spinner-grow {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  100% {
    width: var(--flix-spacing-4);
    height: var(--flix-spacing-4);
    opacity: 1;
  }
}
@keyframes btn-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.flix-btn--loading .flix-btn__icon {
  display: none;
}
.flix-btn--link {
  width: auto;
  min-width: auto;
  padding: 0 var(--flix-spacing-half);
  border-color: transparent;
  background: transparent;
  color: var(--flix-content-primary-color);
}
.flix-btn--link + .flix-btn--link {
  margin-top: 0;
  margin-left: var(--flix-spacing-2);
}
.flix-btn--link.flix-btn--loading::before {
  margin-left: 0;
}
.flix-btn--square {
  width: var(--flix-input-height-mobile);
  min-width: var(--flix-input-height-mobile);
  padding: 0;
}
@media (min-width: 1200px) {
  .flix-btn--square {
    width: var(--flix-input-height-desktop);
    min-width: var(--flix-input-height-desktop);
  }
}
.flix-btn--square + .flix-btn--square {
  margin-top: 0;
  margin-left: var(--flix-spacing-2);
}

.flix-header-burger-menu {
  grid-column: 1/span 2;
  grid-row: 1;
  align-self: center;
  line-height: var(--flix-spacing-10);
}
.flix-header-burger-menu__toggle, .flix-header-burger-menu__widgets, .flix-header-burger-menu__nav {
  line-height: 1;
}
@media (min-width: 1200px) {
  .flix-header-burger-menu {
    position: absolute;
    left: var(--flix-spacing-2);
  }
}
.flix-header-burger-menu__overlay {
  display: none;
}
.flix-header-burger-menu > .flix-header-burger-menu__toggle::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  background: var(--flix-icon-header-burger) no-repeat center;
}
.flix-header-burger-menu__panel {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: -252px;
  width: 252px;
  overflow-y: auto;
  background: var(--flix-box-bg-color);
  color: var(--flix-content-secondary-color);
  -webkit-overflow-scrolling: touch;
  transition: left 250ms ease;
}
.flix-header-burger-menu__panel > .flix-header-burger-menu__toggle {
  position: relative;
  margin-left: var(--flix-spacing-2);
}
.flix-header-burger-menu__panel > .flix-header-burger-menu__toggle::before, .flix-header-burger-menu__panel > .flix-header-burger-menu__toggle::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-icon-primary-color);
}
.flix-header-burger-menu__panel > .flix-header-burger-menu__toggle::before {
  transform: rotate(45deg);
}
.flix-header-burger-menu__panel > .flix-header-burger-menu__toggle::after {
  transform: rotate(-45deg);
}
.flix-header-burger-menu__toggle[aria-expanded=true] + .flix-header-burger-menu__panel:not([hidden]) {
  left: 0;
}
.flix-header-burger-menu__toggle[aria-expanded=true] ~ .flix-header-burger-menu__overlay {
  display: block;
  z-index: 999;
}
.flix-header-burger-menu__nav {
  position: initial;
  width: 100%;
}
.flix-header-burger-menu__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.flix-header-burger-menu__item {
  position: relative;
  margin: -1px var(--flix-spacing-4) 0;
  border-top: 1px solid var(--flix-line-primary-color);
}
.flix-header-burger-menu__link, .flix-header-burger-menu-subnav__link {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: var(--flix-spacing-10);
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-content-secondary-color);
  line-height: var(--flix-spacing-10);
  text-align: left;
  text-decoration: none;
}
.flix-header-burger-menu__link .flix-icon:first-child, .flix-header-burger-menu-subnav__link .flix-icon:first-child {
  margin-right: var(--flix-spacing-half);
}
.flix-header-burger-menu__link .flix-icon:last-child, .flix-header-burger-menu-subnav__link .flix-icon:last-child {
  margin-left: var(--flix-spacing-half);
}
.flix-header-burger-menu__link .flix-icon::before, .flix-header-burger-menu-subnav__link .flix-icon::before {
  margin: 0;
}
.flix-header-burger-menu__link[aria-expanded]::after, [aria-expanded].flix-header-burger-menu-subnav__link::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  background: var(--flix-icon-header-arrow-down) no-repeat center;
}
.flix-header-burger-menu__link[aria-expanded=true]::after, [aria-expanded=true].flix-header-burger-menu-subnav__link::after {
  transform: rotateX(180deg);
}
.flix-header-burger-menu__link[aria-expanded=true] + .flix-header-burger-menu-subnav:not([hidden]), [aria-expanded=true].flix-header-burger-menu-subnav__link + .flix-header-burger-menu-subnav:not([hidden]),
.flix-header-burger-menu__link[aria-expanded=true] + .flix-header-nav-subnav:not([hidden]),
[aria-expanded=true].flix-header-burger-menu-subnav__link + .flix-header-nav-subnav:not([hidden]) {
  display: block;
}
.flix-header-burger-menu__link:hover, .flix-header-burger-menu-subnav__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-header-burger-menu__link:active, .flix-header-burger-menu-subnav__link:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-header-burger-menu__link:focus, .flix-header-burger-menu-subnav__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-header-burger-menu__link[aria-current], [aria-current].flix-header-burger-menu-subnav__link {
  color: var(--flix-content-primary-color);
}
.flix-header-burger-menu__link[aria-current]::before, [aria-current].flix-header-burger-menu-subnav__link::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(-1 * var(--flix-spacing-4));
  width: var(--flix-spacing-half);
  background-color: var(--flix-ui-primary-color);
}
.flix-header-burger-menu__link[aria-expanded]::after, [aria-expanded].flix-header-burger-menu-subnav__link::after {
  background: var(--flix-icon-arrow-down) no-repeat center;
}
.flix-header-burger-menu__text {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flix-header-burger-menu__widgets--has-container {
  margin: 0 var(--flix-spacing-4);
  padding: var(--flix-spacing-2) 0;
  border-top: 1px solid var(--flix-line-primary-color);
}

.flix-header-burger-menu-subnav {
  display: none;
}
.flix-header-burger-menu-subnav__item {
  margin-bottom: var(--flix-spacing-2);
  list-style: none;
}
.flix-header-burger-menu-subnav__link {
  height: auto;
  padding: var(--flix-spacing-half);
  border: 0;
  line-height: var(--flix-line-height-primary);
}

.flix-dropdown {
  display: inline-block;
  position: relative;
  font-family: inherit;
  cursor: pointer;
}
.flix-dropdown__items {
  z-index: 10;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 15.75rem;
  min-height: 44px;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  text-decoration: none;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column;
  padding: var(--flix-spacing-4) var(--flix-spacing-2) var(--flix-spacing-2);
  top: 100%;
  bottom: auto;
  position: absolute;
  margin: calc(-1 * var(--flix-spacing-1)) 0 0;
  display: none;
}
.flix-dropdown__items:not([hidden]) {
  display: flex;
}
.flix-dropdown__items::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-dropdown__items::before {
  top: var(--flix-spacing-2);
  right: 0;
  bottom: 0;
  left: 0;
}
.flix-dropdown__items::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0;
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
  top: var(--flix-spacing-1);
  bottom: auto;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
.flix-dropdown--active .flix-dropdown__items {
  display: block;
}
.flix-dropdown-item, .flix-header-nav-subnav__item {
  margin: 0 calc(-1 * var(--flix-spacing-2));
  padding: var(--flix-spacing-half) var(--flix-spacing-4);
  line-height: var(--flix-line-height-primary);
  list-style: none;
  text-align: left;
}
.flix-dropdown-item__link, .flix-header-nav-subnav__link {
  display: flex;
  position: relative;
  padding: var(--flix-spacing-half);
  color: var(--flix-content-secondary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  text-decoration: none;
  gap: var(--flix-spacing-1);
  cursor: pointer;
}
.flix-dropdown-item__link:hover, .flix-header-nav-subnav__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
  border-radius: var(--flix-primary-border-radius);
}
.flix-dropdown-item__link:active, .flix-header-nav-subnav__link:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
  border-radius: var(--flix-primary-border-radius);
}
.flix-dropdown-item__link:focus, .flix-header-nav-subnav__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
}
.flix-dropdown-item__link[aria-current], [aria-current].flix-header-nav-subnav__link {
  color: var(--flix-content-primary-color);
}
.flix-dropdown-item__link[aria-current]::before, [aria-current].flix-header-nav-subnav__link::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(-1 * var(--flix-spacing-4));
  border-left: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-dropdown-item__link .flix-icon, .flix-header-nav-subnav__link .flix-icon {
  vertical-align: middle;
}
.flix-dropdown-item__link .flix-icon::before, .flix-header-nav-subnav__link .flix-icon::before {
  margin: 0;
}
.flix-dropdown--top .flix-dropdown__items {
  padding: var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-4);
  top: auto;
  bottom: 100%;
  margin: 0 0 calc(-1 * var(--flix-spacing-1));
}
.flix-dropdown--top .flix-dropdown__items::before {
  top: 0;
  right: 0;
  bottom: var(--flix-spacing-2);
  left: 0;
}
.flix-dropdown--top .flix-dropdown__items::after {
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: 0;
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
  top: auto;
  bottom: var(--flix-spacing-1);
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}
.flix-dropdown--left .flix-dropdown__items {
  right: calc(-1 * var(--flix-spacing-1));
  left: auto;
  transform: none;
}
.flix-dropdown--left .flix-dropdown__items::after {
  right: var(--flix-spacing-3);
  left: auto;
  transform: none;
}
@supports (right: min(25%, var(--flix-spacing-3))) {
  .flix-dropdown--left .flix-dropdown__items::after {
    right: min(25%, var(--flix-spacing-3));
  }
}
.flix-dropdown--right .flix-dropdown__items {
  right: auto;
  left: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-dropdown--right .flix-dropdown__items::after {
  left: var(--flix-spacing-3);
  transform: none;
}
@supports (left: min(25%, var(--flix-spacing-3))) {
  .flix-dropdown--right .flix-dropdown__items::after {
    left: min(25%, var(--flix-spacing-3));
  }
}

.flix-header-nav {
  grid-column: 1/span 12;
  grid-row: 2;
  height: var(--flix-spacing-8);
  margin: 0 calc(var(--flix-spacing-2) * -1);
  background: var(--flix-header-nav-bg-color-tablet);
}
@media (min-width: 1200px) {
  .flix-header-nav {
    grid-column: 3/span 10;
    grid-row: 1;
    height: var(--flix-spacing-10);
    margin: 0;
    background: none;
  }
}
.flix-header-nav__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  min-width: calc(var(--flix-page-min-width) - var(--flix-spacing-2));
  max-width: calc(var(--flix-page-max-width) - var(--flix-spacing-2));
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 1200px) {
  .flix-header-nav__list {
    max-width: none;
    margin: 0;
    text-align: left;
  }
}
.flix-header-nav__item {
  display: inline-block;
  position: relative;
}
.flix-header-nav__link {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: inline-flex;
  align-items: baseline;
  height: var(--flix-spacing-8);
  padding: 0 var(--flix-spacing-2);
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-header-color);
  line-height: var(--flix-spacing-8);
  text-decoration: none;
}
.flix-header-nav__link .flix-icon:first-child {
  margin-right: var(--flix-spacing-half);
}
.flix-header-nav__link .flix-icon:last-child {
  margin-left: var(--flix-spacing-half);
}
.flix-header-nav__link .flix-icon::before {
  margin: 0;
}
.flix-header-nav__link[aria-expanded]::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  background: var(--flix-icon-header-arrow-down) no-repeat center;
}
.flix-header-nav__link[aria-expanded=true]::after {
  transform: rotateX(180deg);
}
.flix-header-nav__link[aria-expanded=true] + .flix-header-burger-menu-subnav:not([hidden]),
.flix-header-nav__link[aria-expanded=true] + .flix-header-nav-subnav:not([hidden]) {
  display: block;
}
.flix-header-nav__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-header-nav__link:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-header-nav__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
@media (min-width: 1200px) {
  .flix-header-nav__link {
    height: var(--flix-spacing-10);
    line-height: var(--flix-spacing-10);
  }
}
.flix-header-nav__link[aria-current]::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: var(--flix-spacing-half);
  background-color: var(--flix-header-color);
}
.flix-header-nav__link[aria-expanded]::after {
  align-self: stretch;
  height: auto;
}

.flix-header-nav-subnav {
  z-index: 10;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 15.75rem;
  min-height: 44px;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  text-decoration: none;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column;
  padding: var(--flix-spacing-4) var(--flix-spacing-2) var(--flix-spacing-2);
  top: 100%;
  bottom: auto;
  position: absolute;
  margin: calc(-1 * var(--flix-spacing-1)) 0 0;
  top: 75%;
}
.flix-header-nav-subnav:not([hidden]) {
  display: flex;
}
.flix-header-nav-subnav::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-header-nav-subnav::before {
  top: var(--flix-spacing-2);
  right: 0;
  bottom: 0;
  left: 0;
}
.flix-header-nav-subnav::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0;
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
  top: var(--flix-spacing-1);
  bottom: auto;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
.flix-header-nav-subnav--left {
  right: calc(-1 * var(--flix-spacing-1));
  left: auto;
  transform: none;
}
.flix-header-nav-subnav--left::after {
  right: var(--flix-spacing-3);
  left: auto;
  transform: none;
}
@supports (right: min(25%, var(--flix-spacing-3))) {
  .flix-header-nav-subnav--left::after {
    right: min(25%, var(--flix-spacing-3));
  }
}
.flix-header-nav-subnav--right {
  right: auto;
  left: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-header-nav-subnav--right::after {
  left: var(--flix-spacing-3);
  transform: none;
}
@supports (left: min(25%, var(--flix-spacing-3))) {
  .flix-header-nav-subnav--right::after {
    left: min(25%, var(--flix-spacing-3));
  }
}
.flix-header-nav-subnav__link {
  padding: var(--flix-spacing-half);
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  text-align: left;
}

.flix-header-skip-links {
  position: relative;
  z-index: 1000;
  top: calc(var(--flix-spacing-10) - var(--flix-spacing-4));
  grid-column: 1/span 12;
  grid-row: 1;
  line-height: var(--flix-spacing-10);
  text-align: center;
}
@supports ((width: -moz-fit-content) or (width: fit-content)) {
  .flix-header-skip-links {
    width: 0;
    height: 0;
  }
  .flix-header-skip-links:focus-within {
    width: auto;
    height: auto;
  }
}
.flix-header-skip-links > .flix-skip-link {
  width: auto;
}
@media (min-width: 768px) {
  .flix-header-skip-links {
    top: auto;
    grid-column: 1/span 4;
    text-align: left;
  }
  @supports ((width: -moz-fit-content) or (width: fit-content)) {
    .flix-header-skip-links:focus-within {
      width: -moz-fit-content;
      width: fit-content;
    }
  }
}
@media (min-width: 1200px) {
  .flix-header-skip-links {
    top: calc(var(--flix-spacing-10) - var(--flix-spacing-4));
  }
}

.flix-header-widgets {
  grid-column: 10/span 3;
  grid-row: 1;
  align-self: center;
  justify-self: end;
}
@media (min-width: 1200px) {
  .flix-header-widgets {
    grid-column: 11/span 2;
  }
}
.flix-header-widgets:not(.flix-header-widgets--no-responsive-rendering) {
  display: none;
}
@media (min-width: 768px) {
  .flix-header-widgets:not(.flix-header-widgets--no-responsive-rendering) {
    display: block;
  }
}

.flix-header-user-widget {
  display: flex;
  position: relative;
  align-items: center;
  height: var(--flix-spacing-10);
}
.flix-header-user-widget__toggle {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: flex;
  align-items: center;
  padding: var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
  white-space: nowrap;
  margin-left: var(--flix-spacing-1);
  color: var(--flix-header-color);
}
.flix-header-user-widget__toggle[aria-expanded]::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  background: var(--flix-icon-header-arrow-down) no-repeat center;
}
.flix-header-user-widget__toggle[aria-expanded=true]::after {
  transform: rotateX(180deg);
}
.flix-header-user-widget__toggle[aria-expanded=true] + .flix-header-burger-menu-subnav:not([hidden]),
.flix-header-user-widget__toggle[aria-expanded=true] + .flix-header-nav-subnav:not([hidden]) {
  display: block;
}
.flix-header-user-widget__toggle:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-header-user-widget__toggle:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-header-user-widget__toggle:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}

.flix-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  height: auto;
  min-height: var(--flix-spacing-10);
  padding: 0 var(--flix-spacing-2);
  background: var(--flix-header-bg-color);
  color: var(--flix-header-color);
}
.flix-header__inner {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: var(--flix-spacing-10) auto;
  margin: 0 auto;
  column-gap: var(--flix-spacing-2);
}
@media (min-width: 1200px) {
  .flix-header__inner {
    min-width: calc(var(--flix-page-min-width) - var(--flix-spacing-2));
    max-width: calc(var(--flix-page-max-width) - var(--flix-spacing-2));
  }
}
.flix-header--fullwidth .flix-header__inner {
  max-width: none;
}
.flix-header--unfixed {
  position: static;
}
.flix-header-nav {
  display: none;
}
.flix-header-burger-menu {
  display: block;
}
@media (min-width: 768px) {
  .flix-header-nav {
    display: block;
  }
  .flix-header-burger-menu {
    display: none;
  }
}

.flix-h1, .flix-text h1, .flix-h2, .flix-text h2, .flix-h3, .flix-text h3, .flix-h4, .flix-text h4 {
  margin: 0;
  padding: var(--flix-spacing-2) 0;
}
strong.flix-h1,
strong.flix-h2,
strong.flix-h3,
strong.flix-h4 {
  display: block;
}

.flix-h1, .flix-text h1 {
  color: var(--flix-heading-font-color);
  font-size: var(--flix-font-size-h1);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h1);
  padding: 0 0 var(--flix-spacing-4);
}
.flix-h2, .flix-text h2 {
  color: var(--flix-heading-font-color);
  font-size: var(--flix-font-size-h2);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h2);
}
.flix-h3, .flix-text h3 {
  color: var(--flix-heading-font-color);
  font-size: var(--flix-font-size-h3);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h3);
}
.flix-h4, .flix-text h4 {
  color: var(--flix-heading-font-color);
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
}

.flix-h1--section-header, .flix-h1--space-flush-top, .flix-h2--section-header, .flix-h2--space-flush-top, .flix-h3--section-header, .flix-h3--space-flush-top, .flix-h4--section-header, .flix-h4--space-flush-top {
  padding-top: 0;
}

.flix-h1--space-flush-bottom, .flix-h2--space-flush-bottom, .flix-h3--space-flush-bottom, .flix-h4--space-flush-bottom {
  padding-bottom: 0;
}

.flix-h1--space-flush, .flix-h2--space-flush, .flix-h3--space-flush, .flix-h4--space-flush {
  padding-top: 0;
  padding-bottom: 0;
}

.flix-img-box {
  display: block;
  overflow: hidden;
  border-radius: var(--flix-primary-border-radius);
  font-family: inherit;
  text-decoration: none;
}
.flix-img-box:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
a.flix-img-box:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}

.flix-img-box__img-wrapper {
  position: relative;
  min-height: 198px;
  overflow: hidden;
  border-radius: var(--flix-primary-border-radius);
}
@media (min-width: 1200px) {
  .flix-img-box__img-wrapper {
    min-height: 168px;
  }
}
.flix-img-box__img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.flix-img-box--sm .flix-img-box__img {
  width: auto;
  height: 100%;
}
@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .flix-img-box--sm .flix-img-box__img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .flix-img-box__img {
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.flix-img-box__content {
  position: relative;
}
.flix-img-box--outlined .flix-img-box__content, a.flix-img-box .flix-img-box__content {
  padding: var(--flix-spacing-2) var(--flix-spacing-6) var(--flix-spacing-2) var(--flix-spacing-4);
  background: var(--flix-box-bg-color);
}
.flix-img-box--sm .flix-img-box__content, a.flix-img-box--sm .flix-img-box__content {
  padding-left: var(--flix-spacing-2);
}
a.flix-img-box .flix-img-box__content::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  position: absolute;
  top: 50%;
  right: var(--flix-spacing-2);
  margin-top: -11px;
  background: var(--flix-icon-arrow-right) no-repeat center;
}
a.flix-img-box:hover .flix-img-box__content {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
a.flix-img-box:active .flix-img-box__content {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-img-box--outlined,
a.flix-img-box {
  border: 1px solid var(--flix-line-primary-color);
}
.flix-img-box--outlined .flix-img-box__img-wrapper,
a.flix-img-box .flix-img-box__img-wrapper {
  border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
}

.flix-img-box--sm {
  position: relative;
  min-height: 98px;
}
.flix-img-box--sm .flix-img-box__img-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 96px;
  min-height: 96px;
}
a.flix-img-box--sm .flix-img-box__img-wrapper, .flix-img-box--sm.flix-img-box--outlined .flix-img-box__img-wrapper {
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
}

.flix-img-box--sm .flix-img-box__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 96px);
  min-height: 96px;
  margin-left: 96px;
}

.flix-infobox {
  display: flex;
  align-items: center;
  padding: var(--flix-spacing-4);
  border-radius: var(--flix-primary-border-radius);
  background-color: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
}
.flix-infobox:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-infobox--small {
  padding: var(--flix-spacing-2);
}
.flix-infobox::before {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-8);
  height: var(--flix-spacing-8);
  background-size: var(--flix-spacing-8);
  vertical-align: middle;
  align-self: flex-start;
  min-width: var(--flix-spacing-8);
  margin-right: var(--flix-spacing-2);
  background: var(--flix-icon-info) no-repeat center;
  color: var(--flix-link-color);
}
.flix-infobox--success::before {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-8);
  height: var(--flix-spacing-8);
  background-size: var(--flix-spacing-8);
  vertical-align: middle;
  background: var(--flix-icon-success) no-repeat center;
}
.flix-infobox--warning::before {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-8);
  height: var(--flix-spacing-8);
  background-size: var(--flix-spacing-8);
  vertical-align: middle;
  background: var(--flix-icon-warning) no-repeat center;
}
.flix-infobox--danger::before {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-8);
  height: var(--flix-spacing-8);
  background-size: var(--flix-spacing-8);
  vertical-align: middle;
  background: var(--flix-icon-danger) no-repeat center;
}

.flix-input {
  position: relative;
}
.flix-input__info:not(:empty) {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-input__info:not(:empty) ~ .flix-input__info {
  margin-top: var(--flix-spacing-half);
}
.flix-input--error .flix-input__info {
  color: var(--flix-danger-dark-color);
}
.flix-input__info ~ .flix-input__info {
  color: var(--flix-content-primary-color);
}
.flix-input--loading .flix-input__feedback-icon, .flix-input--error .flix-input__feedback-icon, .flix-input--valid .flix-input__feedback-icon {
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: var(--flix-spacing-4) var(--flix-spacing-4);
}
.flix-input--loading .flix-input__feedback-icon {
  animation: spinner-rotate 1s infinite linear;
  border: var(--flix-spacing-half) solid var(--flix-ui-primary-light-color);
  border-radius: 50%;
  border-left-color: var(--flix-ui-primary-dark-color);
}
@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.flix-input--error .flix-input__feedback-icon {
  background-image: var(--flix-icon-attention-solid);
}
.flix-input--valid .flix-input__feedback-icon {
  background-image: var(--flix-icon-checkmark-solid);
}
.flix-input--error .flix-input__field {
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-input--error .flix-input__field:enabled:hover {
  background-image: var(--flix-hover-layer-color), linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-input--loading .flix-input__field[type=search]::-webkit-search-cancel-button, .flix-input--error .flix-input__field[type=search]::-webkit-search-cancel-button, .flix-input--valid .flix-input__field[type=search]::-webkit-search-cancel-button {
  margin-right: var(--flix-spacing-5);
}
.flix-input--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-input__container {
  position: relative;
  height: var(--flix-input-height-mobile);
  border: 1px solid var(--flix-input-border-color);
  border-radius: var(--flix-input-border-radius);
  background-color: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
  display: flex;
  z-index: 0;
  align-items: center;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .flix-input__container {
    height: var(--flix-input-height-desktop);
  }
}
.flix-input--error .flix-input__container {
  border-color: var(--flix-danger-color);
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-input--active .flix-input__container, .flix-input__container:focus-within {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-input__field {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 var(--flix-spacing-2);
  border: 0;
  background: transparent;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  text-overflow: ellipsis;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  order: 3;
}
.flix-input__field::placeholder {
  opacity: 1;
  color: var(--flix-content-secondary-color);
  font-style: italic;
}
.flix-input__field[type=time]::-webkit-inner-spin-button, .flix-input__field[type=date]::-webkit-inner-spin-button, .flix-input__field[type=datetime-local]::-webkit-inner-spin-button, .flix-input__field[type=month]::-webkit-inner-spin-button, .flix-input__field[type=week]::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none;
}
.flix-input__field[type=time]::-webkit-calendar-picker-indicator, .flix-input__field[type=date]::-webkit-calendar-picker-indicator, .flix-input__field[type=datetime-local]::-webkit-calendar-picker-indicator, .flix-input__field[type=month]::-webkit-calendar-picker-indicator, .flix-input__field[type=week]::-webkit-calendar-picker-indicator {
  display: block !important;
  order: -1;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  margin-right: var(--flix-spacing-1);
  margin-left: 0;
  padding: 0;
  opacity: 1;
  color: transparent;
}
.flix-input__field[type=time]::-webkit-clear-button, .flix-input__field[type=date]::-webkit-clear-button, .flix-input__field[type=datetime-local]::-webkit-clear-button, .flix-input__field[type=month]::-webkit-clear-button, .flix-input__field[type=week]::-webkit-clear-button {
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background: var(--flix-icon-close);
  background-size: var(--flix-spacing-4) var(--flix-spacing-4);
  -webkit-appearance: none;
          appearance: none;
}
.flix-input__field[type=search]::-webkit-search-cancel-button {
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  opacity: 1;
  background: var(--flix-icon-close);
  background-size: var(--flix-spacing-4) var(--flix-spacing-4);
  cursor: pointer;
  -webkit-appearance: none;
          appearance: none;
}
.flix-input__field[type=date]::-webkit-calendar-picker-indicator, .flix-input__field[type=datetime-local]::-webkit-calendar-picker-indicator, .flix-input__field[type=month]::-webkit-calendar-picker-indicator, .flix-input__field[type=week]::-webkit-calendar-picker-indicator {
  background: var(--flix-icon-calendar);
  background-size: var(--flix-spacing-4) var(--flix-spacing-4);
}
.flix-input__field[type=time]::-webkit-calendar-picker-indicator {
  background: var(--flix-icon-time);
  background-size: var(--flix-spacing-4) var(--flix-spacing-4);
}
.flix-input__field:enabled:hover, .flix-input__field[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-input__field:focus {
  outline: none;
}
.flix-input--disabled .flix-input__field {
  cursor: not-allowed;
}
.flix-input__icon ~ .flix-input__field {
  margin-left: calc(-1 * var(--flix-spacing-7));
  padding-left: var(--flix-spacing-7);
}
.flix-input--has-icon-right .flix-input__field {
  margin-right: calc(-1 * var(--flix-spacing-7));
  padding-right: var(--flix-spacing-7);
}
.flix-input__feedback-icon {
  z-index: 0;
  flex-shrink: 0;
  order: 3;
  pointer-events: none;
}
.flix-input--loading .flix-input__feedback-icon, .flix-input--error .flix-input__feedback-icon, .flix-input--valid .flix-input__feedback-icon {
  margin: 0 var(--flix-spacing-2) 0 calc(-1 * var(--flix-spacing-6));
}
.flix-input__inline-label {
  position: relative;
  z-index: 1;
  align-self: stretch;
  order: 1;
  padding: 0 var(--flix-spacing-2);
  border-right: 1px solid var(--flix-input-border-color);
  line-height: var(--flix-input-height-mobile);
}
.flix-input__field ~ .flix-input__inline-label {
  order: 5;
  border-right: 0;
  border-left: 1px solid var(--flix-input-border-color);
}
@media (min-width: 1200px) {
  .flix-input__inline-label {
    line-height: var(--flix-input-height-desktop);
  }
}
.flix-input__icon, .flix-input__icon.flix-icon {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  order: 2;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  margin-right: var(--flix-spacing-1);
  margin-left: var(--flix-spacing-2);
  line-height: var(--flix-spacing-4);
  pointer-events: none;
}
.flix-input__field ~ .flix-input__icon, .flix-input__field ~ .flix-input__icon.flix-icon {
  order: 4;
  margin-right: var(--flix-spacing-2);
  margin-left: var(--flix-spacing-1);
}
.flix-input button.flix-input__icon {
  pointer-events: auto;
}
.flix-input button.flix-input__icon .flix-icon {
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  line-height: var(--flix-spacing-4);
}
.flix-input button.flix-input__icon .flix-icon::before {
  margin-right: 0;
  margin-left: 0;
}

.flix-label {
  display: block;
  position: absolute;
  z-index: 1;
  top: -0.5rem;
  left: var(--flix-spacing-1);
  padding: 1px var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-bg-primary-color);
  color: var(--flix-content-secondary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: 1rem;
  text-align: left;
}
@media (min-width: 1200px) {
  .flix-label {
    position: static;
    top: auto;
    left: auto;
    padding: 0 0 var(--flix-spacing-half);
    background: transparent;
    font-size: var(--flix-font-size-primary);
    line-height: var(--flix-line-height-primary);
  }
}

.flix-language-switcher {
  display: flex;
  align-items: center;
  height: 100%;
}
.flix-language-switcher__toggle {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: flex;
  align-items: center;
  padding: var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
  white-space: nowrap;
  color: inherit;
}
.flix-language-switcher__toggle[aria-expanded]::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  background: var(--flix-icon-header-arrow-down) no-repeat center;
}
.flix-language-switcher__toggle[aria-expanded=true]::after {
  transform: rotateX(180deg);
}
.flix-language-switcher__toggle[aria-expanded=true] + .flix-header-burger-menu-subnav:not([hidden]),
.flix-language-switcher__toggle[aria-expanded=true] + .flix-header-nav-subnav:not([hidden]) {
  display: block;
}
.flix-language-switcher__toggle:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-language-switcher__toggle:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-language-switcher__toggle:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-header-burger-menu__widgets .flix-language-switcher__toggle {
  width: 100%;
  height: var(--flix-input-height-mobile);
}
.flix-language-switcher__flag {
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  margin-right: var(--flix-spacing-1);
  border-radius: 100%;
}
.flix-language-switcher-popup {
  display: none;
  position: fixed;
  z-index: 1100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: var(--flix-page-min-width);
  max-width: var(--flix-page-max-width);
  margin: 0 auto;
  padding: 0 var(--flix-spacing-2);
}
.flix-language-switcher-popup:not([hidden]) {
  display: grid;
  grid-column-gap: var(--flix-spacing-2);
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 100vh auto;
  align-items: center;
}
@media (min-width: 1024px) {
  .flix-language-switcher-popup:not([hidden]) {
    grid-template-columns: repeat(12, 1fr);
  }
}
.flix-language-switcher-popup__body {
  display: grid;
  position: relative;
  z-index: 1101;
  grid-column: 1/span 4;
  grid-template-columns: calc(100% - 24px) 24px;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 80vh;
  padding: var(--flix-spacing-2);
  overflow-y: auto;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
}
@media (min-width: 768px) {
  .flix-language-switcher-popup__body {
    padding: var(--flix-spacing-6);
  }
}
@media (min-width: 1024px) {
  .flix-language-switcher-popup__body {
    grid-column: 2/span 10;
  }
}
.flix-language-switcher-popup__region {
  grid-column: 1;
}
.flix-language-switcher-popup__region:not(:last-child) {
  margin-bottom: var(--flix-spacing-2);
  padding-bottom: var(--flix-spacing-2);
  border-bottom: 1px solid var(--flix-line-primary-color);
}
.flix-language-switcher-popup__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--flix-spacing-1);
}
.flix-language-switcher-popup__list:not(:last-child) {
  margin-bottom: var(--flix-spacing-2);
}
@media (min-width: 768px) {
  .flix-language-switcher-popup__list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.flix-language-switcher-popup__link {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: flex;
  position: relative;
  align-items: center;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  margin: var(--flix-spacing-half) 0;
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-content-secondary-color);
  font-family: inherit;
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  text-decoration: none;
  cursor: pointer;
}
.flix-language-switcher-popup__link .flix-icon:first-child {
  margin-right: var(--flix-spacing-half);
}
.flix-language-switcher-popup__link .flix-icon:last-child {
  margin-left: var(--flix-spacing-half);
}
.flix-language-switcher-popup__link .flix-icon::before {
  margin: 0;
}
.flix-language-switcher-popup__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-language-switcher-popup__link:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-language-switcher-popup__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  outline-offset: 0;
  text-decoration: none;
}
.flix-language-switcher-popup__link[aria-current] {
  padding-left: var(--flix-spacing-2);
  color: var(--flix-content-primary-color);
  text-decoration: none;
}
.flix-language-switcher-popup__link[aria-current]::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-language-switcher-popup__close {
  position: relative;
  top: 0;
  right: 0;
  grid-column: 2;
  grid-row: 1;
}
.flix-language-switcher-popup__close.flix-language-switcher-popup__close {
  position: -webkit-sticky;
  position: sticky;
}
.flix-language-switcher-popup__close::before, .flix-language-switcher-popup__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-icon-primary-color);
}
.flix-language-switcher-popup__close::before {
  transform: rotate(45deg);
}
.flix-language-switcher-popup__close::after {
  transform: rotate(-45deg);
}

.flix-legend {
  display: block;
  width: 100%;
  padding-bottom: var(--flix-spacing-half);
  color: var(--flix-content-secondary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
}

.flix-link, .flix-text a {
  background-color: transparent;
  color: var(--flix-link-color);
  font-weight: var(--flix-font-weight-semibold);
  text-decoration: underline;
}
.flix-link:hover, .flix-text a:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-link-color);
  text-decoration: underline;
}
.flix-link:active, .flix-text a:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
  color: var(--flix-link-color);
}
.flix-link:focus, .flix-text a:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-link-color);
}

.flix-list-description__details, .flix-text dl > dd {
  margin-left: var(--flix-spacing-4);
}
.flix-list-description__details + .flix-list-description__term, .flix-text dl > dd + .flix-list-description__term, .flix-text dl > .flix-list-description__details + dt, .flix-text dl > dd + dt {
  margin-top: var(--flix-spacing-2);
}

.flix-text ol, .flix-list-numbered {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin: 0 0 var(--flix-spacing-2);
  padding-left: var(--flix-spacing-2);
  counter-reset: numbered-list-counter;
}

.flix-text ol > li, .flix-list-numbered__item {
  display: table;
  position: relative;
  color: var(--flix-content-primary-color);
}
.flix-text ol > li::before, .flix-list-numbered__item::before {
  content: counters(numbered-list-counter, ".") ".";
  display: table-cell;
  padding-right: var(--flix-spacing-1);
  font-weight: var(--flix-font-weight-bold);
  counter-increment: numbered-list-counter;
}

.flix-text ol ol, .flix-list-numbered .flix-list-numbered {
  width: 100%;
  margin: 0;
  padding-left: 0;
}

.flix-text ol ol > li::before, .flix-list-numbered .flix-list-numbered .flix-list-numbered__item::before {
  content: counters(numbered-list-counter, ".");
  font-weight: var(--flix-font-weight-normal);
}

.flix-list-wrapper {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.flix-list-wrapper__item {
  display: flex;
  position: relative;
  align-items: stretch;
  min-height: var(--flix-spacing-8);
  border: solid var(--flix-line-primary-color);
  border-width: 0 1px 1px;
  background-color: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  line-height: var(--flix-line-height-primary);
}
.flix-list-wrapper__item:first-of-type {
  border-top-width: 1px;
}
.flix-list-wrapper__item:first-of-type, .flix-list-wrapper__item:first-of-type .flix-list-wrapper__content {
  border-top-left-radius: var(--flix-primary-border-radius);
  border-top-right-radius: var(--flix-primary-border-radius);
}
.flix-list-wrapper__item:last-of-type, .flix-list-wrapper__item:last-of-type .flix-list-wrapper__content {
  border-bottom-right-radius: var(--flix-primary-border-radius);
  border-bottom-left-radius: var(--flix-primary-border-radius);
}
.flix-list-wrapper__item--error {
  border-width: 1px;
  border-color: var(--flix-danger-color);
}
.flix-list-wrapper__item--error + .flix-list-wrapper__item--error {
  border-width: 0 1px 1px;
}
.flix-list-wrapper__item--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-list-wrapper__content {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: block;
  width: 100%;
  padding: var(--flix-spacing-2);
  color: var(--flix-content-primary-color);
  font-weight: var(--flix-font-weight-semibold);
}
.flix-list-wrapper__item--disabled .flix-list-wrapper__content {
  cursor: not-allowed;
}
.flix-list-wrapper a.flix-list-wrapper__content, .flix-list-wrapper button.flix-list-wrapper__content {
  padding-right: var(--flix-spacing-6);
  text-decoration: none;
  cursor: pointer;
}
.flix-list-wrapper a.flix-list-wrapper__content::after, .flix-list-wrapper button.flix-list-wrapper__content::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  position: absolute;
  top: 50%;
  right: var(--flix-spacing-2);
  margin-top: -11px;
  background: var(--flix-icon-arrow-right) no-repeat center;
}
.flix-list-wrapper button.flix-list-wrapper__content {
  text-align: left;
}
a.flix-list-wrapper__content:not(.flix-list-wrapper__item--disabled a):hover,
button.flix-list-wrapper__content:not(.flix-list-wrapper__item--disabled button):hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
a.flix-list-wrapper__content:not(.flix-list-wrapper__item--disabled a):active,
button.flix-list-wrapper__content:not(.flix-list-wrapper__item--disabled button):active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
a.flix-list-wrapper__content:not(.flix-list-wrapper__item--disabled a):focus,
button.flix-list-wrapper__content:not(.flix-list-wrapper__item--disabled button):focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
  z-index: 1;
  text-decoration: none;
}

.flix-list-wrapper--small .flix-list-wrapper__item {
  min-height: var(--flix-spacing-6);
}
.flix-list-wrapper--small .flix-list-wrapper__content {
  padding: var(--flix-spacing-1);
  font-weight: var(--flix-font-weight-normal);
}

.flix-text ul, .flix-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin: 0 0 var(--flix-spacing-2);
  padding-left: var(--flix-spacing-2);
}

.flix-text ul ul, .flix-list .flix-list {
  margin: 0;
  padding-left: 0;
}

.flix-list__item, .flix-text ul li {
  position: relative;
  padding-left: calc(var(--flix-spacing-2) + var(--flix-spacing-half));
  color: var(--flix-content-primary-color);
  line-height: var(--flix-line-height-primary);
}
.flix-list__item::before, .flix-text ul li::before {
  content: "";
  display: block;
  position: absolute;
  top: calc(var(--flix-line-height-primary) / 2);
  left: 0;
  width: var(--flix-spacing-half);
  height: var(--flix-spacing-half);
  margin-top: -2px;
  margin-right: var(--flix-spacing-2);
  border-radius: 50%;
  background: var(--flix-content-primary-color);
}

.flix-main-wrapper {
  position: relative;
  min-height: 100%;
  margin-top: 0;
  padding-bottom: 0;
  background: var(--flix-bg-primary-color);
}
.flix-main-wrapper--full {
  min-height: 100vh;
}

.flix-nav-horizontal {
  margin: 0;
  margin-bottom: var(--flix-spacing-4);
  padding: 0;
}
.flix-nav-horizontal--centered {
  text-align: center;
}
.flix-nav-horizontal--right {
  text-align: right;
}
.flix-nav-horizontal--left {
  text-align: left;
}
.flix-nav-horizontal--stretch .flix-nav-horizontal__item {
  flex: 1 1 0;
}
.flix-nav-horizontal--stretch .flix-nav-horizontal__link {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}
.flix-nav-horizontal__items {
  display: flex;
  align-items: flex-end;
  margin: 0;
  padding: 0;
}
.flix-nav-horizontal__item {
  position: relative;
  max-width: 50%;
  list-style-type: none;
  text-align: center;
  white-space: nowrap;
}
.flix-nav-horizontal__item:not(:first-child)::before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: var(--flix-spacing-2);
  left: 0;
  height: var(--flix-spacing-3);
  border-left: 1px solid var(--flix-line-primary-color);
}
.flix-nav-horizontal__link {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: inline-block;
  margin: var(--flix-spacing-1) var(--flix-spacing-2);
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-content-secondary-color);
  font-family: inherit;
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  text-decoration: none;
  cursor: pointer;
}
.flix-nav-horizontal__link .flix-icon:first-child {
  margin-right: var(--flix-spacing-half);
}
.flix-nav-horizontal__link .flix-icon:last-child {
  margin-left: var(--flix-spacing-half);
}
.flix-nav-horizontal__link .flix-icon::before {
  margin: 0;
}
.flix-nav-horizontal__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-nav-horizontal__link:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-nav-horizontal__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-nav-horizontal__link[aria-current], .flix-nav-horizontal__link[aria-selected=true] {
  color: var(--flix-content-primary-color);
  text-decoration: none;
}
.flix-nav-horizontal__link[aria-current]::before, .flix-nav-horizontal__link[aria-selected=true]::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0;
  border-bottom: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-nav-horizontal__panel:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}

.flix-nav-side, .flix-nav-side-subnav {
  position: relative;
  margin: 0;
  padding: 0;
}
.flix-nav-side--centered {
  text-align: center;
}
.flix-nav-side__item, .flix-nav-side-subnav__item {
  display: block;
  position: relative;
}
.flix-nav-side__link, .flix-nav-side-subnav__link {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: flex;
  position: relative;
  align-items: center;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  margin: var(--flix-spacing-half) 0;
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-content-secondary-color);
  font-family: inherit;
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  text-decoration: none;
  cursor: pointer;
  margin: var(--flix-spacing-half) 0;
}
.flix-nav-side__link .flix-icon:first-child, .flix-nav-side-subnav__link .flix-icon:first-child {
  margin-right: var(--flix-spacing-half);
}
.flix-nav-side__link .flix-icon:last-child, .flix-nav-side-subnav__link .flix-icon:last-child {
  margin-left: var(--flix-spacing-half);
}
.flix-nav-side__link .flix-icon::before, .flix-nav-side-subnav__link .flix-icon::before {
  margin: 0;
}
.flix-nav-side__link:hover, .flix-nav-side-subnav__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-nav-side__link:active, .flix-nav-side-subnav__link:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-nav-side__link:focus, .flix-nav-side-subnav__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  outline-offset: 0;
  text-decoration: none;
}
.flix-nav-side__link[aria-current], [aria-current].flix-nav-side-subnav__link {
  padding-left: var(--flix-spacing-2);
  color: var(--flix-content-primary-color);
  text-decoration: none;
}
.flix-nav-side__link[aria-current]::before, [aria-current].flix-nav-side-subnav__link::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-nav-side__link--has-subnav::after, .flix-nav-side__link[aria-expanded]::after, [aria-expanded].flix-nav-side-subnav__link::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  background: var(--flix-icon-arrow-down) no-repeat center;
}
.flix-nav-side__link--has-subnav:hover::after, .flix-nav-side__link--has-subnav:active::after, .flix-nav-side__link--has-subnav:focus::after, .flix-nav-side__link--has-subnav[aria-current]::after, .flix-nav-side__link[aria-expanded]:hover::after, [aria-expanded].flix-nav-side-subnav__link:hover::after, .flix-nav-side__link[aria-expanded]:active::after, [aria-expanded].flix-nav-side-subnav__link:active::after, .flix-nav-side__link[aria-expanded]:focus::after, [aria-expanded].flix-nav-side-subnav__link:focus::after, .flix-nav-side__link[aria-expanded][aria-current]::after, [aria-expanded][aria-current].flix-nav-side-subnav__link::after {
  background: var(--flix-icon-arrow-down) no-repeat center;
}
.flix-nav-side__link--has-subnav-open::after, .flix-nav-side__link[aria-expanded=true]::after, [aria-expanded=true].flix-nav-side-subnav__link::after {
  transform: rotate(180deg);
}
.flix-nav-side__link--has-subnav-open:hover::after, .flix-nav-side__link--has-subnav-open:active::after, .flix-nav-side__link--has-subnav-open:focus::after, .flix-nav-side__link[aria-expanded=true]:hover::after, [aria-expanded=true].flix-nav-side-subnav__link:hover::after, .flix-nav-side__link[aria-expanded=true]:active::after, [aria-expanded=true].flix-nav-side-subnav__link:active::after, .flix-nav-side__link[aria-expanded=true]:focus::after, [aria-expanded=true].flix-nav-side-subnav__link:focus::after {
  transform: rotate(180deg);
}
.flix-nav-side-subnav {
  padding-left: var(--flix-spacing-2);
}
.flix-nav-tab-bar {
  display: flex;
  position: fixed;
  z-index: 1000;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 var(--flix-spacing-1);
  background-color: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-nav-tab-bar__tab {
  color: var(--flix-content-secondary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  flex: 1;
  padding: var(--flix-spacing-1);
  overflow: hidden;
  border-radius: var(--flix-primary-border-radius);
  text-align: center;
  text-decoration: none;
}
.flix-nav-tab-bar__tab:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-nav-tab-bar__tab:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-nav-tab-bar__tab:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  outline-offset: 0;
}
.flix-nav-tab-bar__tab[aria-current] {
  position: relative;
  color: var(--flix-content-primary-color);
}
.flix-nav-tab-bar__tab[aria-current]::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: var(--flix-spacing-half);
  left: var(--flix-spacing-half);
  height: var(--flix-spacing-half);
  background-color: var(--flix-ui-primary-color);
}
.flix-nav-tab-bar__icon {
  display: block;
  fill: currentcolor;
  color: inherit;
  font-size: var(--flix-spacing-4);
  line-height: var(--flix-spacing-4);
}
.flix-nav-tab-bar__label {
  display: block;
  margin: 0;
  padding-top: var(--flix-spacing-half);
  overflow: hidden;
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flix-notification {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  padding: var(--flix-spacing-2);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-bold);
}
.flix-notification:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-notification::before {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  align-self: flex-start;
  min-width: var(--flix-spacing-4);
  margin-right: var(--flix-spacing-2);
  background: var(--flix-icon-info-solid) no-repeat center;
}
.flix-notification--danger::before {
  background: var(--flix-icon-danger-solid) no-repeat center;
}
.flix-notification--warning::before {
  background: var(--flix-icon-warning-solid) no-repeat center;
}
.flix-notification--success::before {
  background: var(--flix-icon-success-solid) no-repeat center;
}
.flix-notification--toast {
  z-index: 1;
  animation: 4s sneak-peek-notification-top;
  opacity: 0;
  background: var(--flix-grayscale-100-color);
  color: var(--flix-grayscale-0-color);
}
.flix-notification--toast .flix-notification__content * {
  color: var(--flix-grayscale-0-color);
}
.flix-notification--toast .flix-notification__close {
  position: relative;
}
.flix-notification--toast .flix-notification__close::before, .flix-notification--toast .flix-notification__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-grayscale-0-color);
}
.flix-notification--toast .flix-notification__close::before {
  transform: rotate(45deg);
}
.flix-notification--toast .flix-notification__close::after {
  transform: rotate(-45deg);
}
.flix-notification-container--bottom-left .flix-notification--toast, .flix-notification-container--bottom-right .flix-notification--toast {
  animation: 4s sneak-peek-notification-bottom;
}
.flix-notification__content {
  flex-grow: 1;
}
.flix-notification__close {
  position: relative;
  align-self: flex-start;
  margin-left: var(--flix-spacing-2);
}
.flix-notification__close::before, .flix-notification__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-grayscale-100-color);
}
.flix-notification__close::before {
  transform: rotate(45deg);
}
.flix-notification__close::after {
  transform: rotate(-45deg);
}
.flix-notification-container {
  position: fixed;
  z-index: 1209;
  width: 100%;
}
.flix-notification-container:not(:empty) {
  padding: 0 var(--flix-spacing-2);
}
@media (min-width: 600px) {
  .flix-notification-container {
    width: auto;
  }
  .flix-notification-container:not(:empty) {
    padding: var(--flix-spacing-4) var(--flix-spacing-6);
  }
}
.flix-notification-container, .flix-notification-container--top-right {
  top: 0;
  right: 0;
}
.flix-notification-container--top-left {
  top: 0;
  right: auto;
  left: 0;
}
.flix-notification-container--bottom-left {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}
.flix-notification-container--bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
}

@keyframes sneak-peek-notification-top {
  0% {
    transform: translateY(-var(--flix-spacing-4));
    opacity: 0;
  }
  7.5% {
    transform: translateY(0);
    opacity: 1;
  }
  92.5% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-var(--flix-spacing-4));
    opacity: 0;
  }
}
@keyframes sneak-peek-notification-bottom {
  0% {
    transform: translateY(var(--flix-spacing-4));
    opacity: 0;
  }
  7.5% {
    transform: translateY(0);
    opacity: 1;
  }
  92.5% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(var(--flix-spacing-4));
    opacity: 0;
  }
}
.flix-overlay {
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--flix-overlay-bg-color);
}

.flix-page-container {
  min-width: var(--flix-page-min-width);
  max-width: var(--flix-page-max-width);
  margin: 0 auto;
  padding: 0 var(--flix-spacing-2);
}
.flix-page-container--has-fixed-header, .flix-page-container--has-fixed-header-no-nav {
  padding-top: var(--flix-spacing-10);
}
@media (min-width: 768px) and (max-width: 1199px) {
  .flix-page-container--has-fixed-header {
    padding-top: calc(var(--flix-spacing-10) + var(--flix-spacing-8));
  }
}

.flix-pager__list {
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 100%;
  list-style: none;
}
.flix-pager__item {
  min-width: var(--flix-spacing-8);
  height: var(--flix-spacing-8);
  margin: -1px -1px 0 0;
  border: 1px solid var(--flix-input-border-color);
  text-align: center;
}
@media (min-width: 1200px) {
  .flix-pager__item {
    min-width: var(--flix-spacing-6);
    height: var(--flix-spacing-6);
  }
}
.flix-pager__item:first-child {
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
}
.flix-pager__item:last-child {
  margin-right: 0;
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
}
.flix-pager__link {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 var(--flix-spacing-1);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-line-height-primary);
  text-decoration: none;
}
.flix-pager__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
  text-decoration: none;
}
.flix-pager__link:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
  text-decoration: none;
}
.flix-pager__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-pager__link[aria-current] {
  border-bottom: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
  background-color: var(--flix-highlight-color);
}
.flix-pager__link--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.flix-pager__link--disabled:hover {
  background: transparent;
  color: var(--flix-content-primary-color);
}
.flix-pager__link--prev, .flix-pager__link--next {
  font-size: 0;
}
.flix-pager__link--prev::after,
.flix-pager__link--prev .flix-pager__link--disabled:hover::after, .flix-pager__link--next::after,
.flix-pager__link--next .flix-pager__link--disabled:hover::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  background: var(--flix-icon-arrow-left) no-repeat center;
}
.flix-pager__link--next::after,
.flix-pager__link--next .flix-pager__link--disabled:hover::after {
  transform: rotate(180deg);
}
.flix-pager__link--next:hover::after {
  transform: rotate(180deg);
}
.flix-pager--content-fit .flix-pager__item {
  height: auto;
}
.flix-pager--content-fit .flix-pager__link {
  padding: var(--flix-spacing-1) var(--flix-spacing-2);
}
.flix-pager--content-fit .flix-pager__link--prev, .flix-pager--content-fit .flix-pager__link--next {
  padding: var(--flix-spacing-1);
}

.flix-panel {
  position: fixed;
  z-index: 1209;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  transition: transform 62.5ms 62.5ms, opacity 125ms;
  opacity: 0;
}
.flix-panel--active {
  transform: translateY(0);
  transition: transform 125ms, opacity 62.5ms 62.5ms;
  opacity: 1;
}
.flix-panel__body {
  display: flex;
  position: absolute;
  z-index: 1209;
  top: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: stretch;
  width: 85%;
  max-width: 23.75em;
  overflow: hidden;
  transform: translate(100%, 0);
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
  transition: transform 250ms ease;
}
.flix-panel--left .flix-panel__body {
  right: auto;
  left: 0;
  transform: translate(-100%, 0);
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
}
.flix-panel--bottom .flix-panel__body {
  top: auto;
  width: 100%;
  max-width: 100%;
  max-height: 85vh;
  transform: translate(0, 100%);
  border-radius: var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0 0;
}
.flix-panel--full .flix-panel__body {
  width: 100%;
  max-width: 100%;
  border-radius: 0;
}
.flix-panel--full.flix-panel--bottom .flix-panel__body {
  height: 100vh;
}
.flix-panel--active .flix-panel__body {
  transform: translate(0, 0);
  transition: transform 250ms 250ms ease;
}
.flix-panel__header {
  display: flex;
  z-index: 1000;
  padding: var(--flix-spacing-2);
  border-bottom: 1px solid var(--flix-line-primary-color);
}
@media (min-width: 600px) {
  .flix-panel__header {
    padding: var(--flix-spacing-2) var(--flix-spacing-4);
  }
}
.flix-panel__title {
  flex: 1;
  align-self: center;
}
.flix-panel__back, .flix-panel__close {
  flex: 0;
}
.flix-panel__back {
  margin: 0 var(--flix-spacing-2) 0 calc(-1 * var(--flix-spacing-1));
}
.flix-panel__close {
  position: relative;
  margin: 0 calc(-1 * var(--flix-spacing-1)) 0 var(--flix-spacing-2);
}
.flix-panel__close::before, .flix-panel__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-icon-primary-color);
}
.flix-panel__close::before {
  transform: rotate(45deg);
}
.flix-panel__close::after {
  transform: rotate(-45deg);
}
.flix-panel__content {
  flex-basis: 100%;
  padding: var(--flix-spacing-2);
  overflow-y: auto;
  background: linear-gradient(var(--flix-box-bg-color), rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), var(--flix-box-bg-color)), linear-gradient(var(--flix-box-shadow-subtle-color), rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), var(--flix-box-shadow-subtle-color));
  background-attachment: local, local, scroll, scroll;
  background-repeat: no-repeat;
  background-position: 0 0, 0 100%, 0 0, 0 100%;
  background-size: 100% var(--flix-spacing-4), 100% var(--flix-spacing-4), 100% var(--flix-spacing-1), 100% var(--flix-spacing-1);
}
.flix-panel--bottom .flix-panel__content {
  flex-basis: auto;
}
.flix-panel--full.flix-panel--bottom .flix-panel__content {
  flex-basis: 100%;
}
@media (min-width: 600px) {
  .flix-panel__content {
    padding: var(--flix-spacing-4);
  }
}
.flix-panel__footer {
  display: flex;
  flex-shrink: 0;
  align-items: baseline;
  justify-content: space-between;
  min-height: var(--flix-input-height-mobile);
  padding: var(--flix-spacing-2) var(--flix-spacing-2);
  gap: var(--flix-spacing-2);
}
.flix-panel__footer--justify-start {
  justify-content: flex-start;
}
.flix-panel__footer--justify-end {
  justify-content: flex-end;
}
.flix-panel__footer--justify-center {
  justify-content: center;
}
@media (min-width: 600px) {
  .flix-panel__footer {
    min-height: var(--flix-input-height-desktop);
    padding: var(--flix-spacing-2) var(--flix-spacing-4);
  }
}
.flix-panel__footer .flix-btn:not(.flix-panel__footer .flix-btn--block), .flix-panel__footer .flix-skip-link:not(.flix-panel__footer .flix-btn--block) {
  width: auto;
}
.flix-panel__footer > .flix-btn + .flix-btn, .flix-panel__footer > .flix-skip-link + .flix-btn, .flix-panel__footer > .flix-btn + .flix-skip-link, .flix-panel__footer > .flix-skip-link + .flix-skip-link {
  margin-top: 0;
  margin-left: var(--flix-spacing-2);
}
.flix-panel__footer-column {
  flex-basis: 100%;
  text-align: center;
}
.flix-panel__footer-column--narrow {
  flex-basis: auto;
}
.flix-panel__overlay {
  position: absolute;
}

.flix-popup {
  display: none;
  position: fixed;
  z-index: 1100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.flix-popup--active {
  display: block;
}
.flix-popup__body {
  position: fixed;
  z-index: 1101;
  top: 50%;
  left: 50%;
  width: 330px;
  max-width: 100%;
  max-height: 80vh;
  padding: var(--flix-spacing-6);
  overflow-y: auto;
  transform: translateY(-50%) translateX(-50%);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  text-align: center;
}
@media (min-width: 600px) {
  .flix-popup__body {
    width: 600px;
    padding: var(--flix-spacing-6);
  }
}
.flix-popup__icon {
  padding-bottom: var(--flix-spacing-2);
}
.flix-popup__title {
  color: var(--flix-heading-font-color);
  font-size: var(--flix-font-size-h1);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h1);
  margin: 0;
  padding-bottom: var(--flix-spacing-2);
}
.flix-popup__content {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  padding-bottom: var(--flix-spacing-2);
}
.flix-popup__actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: var(--flix-spacing-2);
}
.flix-popup__actions .flix-btn + .flix-btn, .flix-popup__actions .flix-skip-link + .flix-btn, .flix-popup__actions .flix-btn + .flix-skip-link, .flix-popup__actions .flix-skip-link + .flix-skip-link {
  margin-top: 0;
  margin-left: 0;
}

.flix-progress-tracker {
  display: flex;
  justify-content: center;
  list-style-type: none;
  counter-reset: hc-progress-tracker-counter;
}
.flix-progress-tracker__item {
  position: relative;
  height: var(--flix-spacing-6);
  border: solid var(--flix-ui-primary-color);
  border-width: 1px 0;
  background: var(--flix-highlight-color);
  color: var(--flix-content-primary-color);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-spacing-6);
  text-align: center;
  counter-increment: hc-progress-tracker-counter;
}
.flix-progress-tracker__item:first-child {
  border-left-width: 1px;
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
}
.flix-progress-tracker__item:last-child {
  border-right-width: 1px;
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
}
.flix-progress-tracker__item[aria-current]::before {
  content: "";
  position: absolute;
  z-index: 2;
  right: calc(-1 * var(--flix-spacing-half));
  bottom: 0;
  left: 0;
  height: var(--flix-spacing-half);
  background: var(--flix-ui-primary-color);
  -webkit-clip-path: polygon(var(--flix-spacing-half) 0, 100% 0, calc(100% - var(--flix-spacing-half)) 100%, 0 100%);
          clip-path: polygon(var(--flix-spacing-half) 0, 100% 0, calc(100% - var(--flix-spacing-half)) 100%, 0 100%);
}
.flix-progress-tracker__item[aria-current]:first-child::before {
  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - var(--flix-spacing-half)) 100%, var(--flix-spacing-half) 100%);
          clip-path: polygon(0 0, 100% 0, calc(100% - var(--flix-spacing-half)) 100%, var(--flix-spacing-half) 100%);
}
.flix-progress-tracker__item[aria-current]:last-child::before {
  right: 0;
}
.flix-progress-tracker__item[aria-current], .flix-progress-tracker__item[aria-current] ~ .flix-progress-tracker__item {
  background: var(--flix-bg-primary-color);
}
.flix-progress-tracker__item[aria-current] .flix-progress-tracker__link::after, .flix-progress-tracker__item[aria-current] ~ .flix-progress-tracker__item .flix-progress-tracker__link::after {
  background-color: var(--flix-bg-primary-color);
}
.flix-progress-tracker__item[aria-current] ~ .flix-progress-tracker__item {
  border-color: var(--flix-line-primary-color);
  color: var(--flix-content-secondary-color);
}
.flix-progress-tracker__item[aria-current] ~ .flix-progress-tracker__item .flix-progress-tracker__link::after {
  border-color: var(--flix-line-primary-color);
}
.flix-progress-tracker__link {
  display: flex;
  justify-content: center;
  min-width: calc(var(--flix-spacing-7) * 2);
  height: 100%;
  padding: 0 var(--flix-spacing-3);
  overflow: hidden;
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flix-progress-tracker__link::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 4px;
  right: calc(-1 * var(--flix-spacing-2) - 1px);
  width: calc(var(--flix-spacing-5) - 4px);
  height: calc(var(--flix-spacing-5) - 4px);
  transform: rotate(45deg);
  border: solid var(--flix-ui-primary-color);
  border-width: 1px 1px 0 0;
  background-color: var(--flix-highlight-color);
  -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
          clip-path: polygon(100% 0, 0 0, 100% 100%);
}
@media (min-width: 600px) {
  .flix-progress-tracker__link {
    min-width: calc(var(--flix-spacing-12) * 2);
  }
}
a.flix-progress-tracker__link, button.flix-progress-tracker__link {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: var(--flix-font-family-primary);
  font-size: var(--flix-font-size-primary);
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
a.flix-progress-tracker__link:hover, button.flix-progress-tracker__link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
a.flix-progress-tracker__link:active, a.flix-progress-tracker__link:enabled:active, button.flix-progress-tracker__link:active, button.flix-progress-tracker__link:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
a.flix-progress-tracker__link:focus, button.flix-progress-tracker__link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
a.flix-progress-tracker__link:hover::after, button.flix-progress-tracker__link:hover::after {
  background-image: var(--flix-hover-layer-color);
}
a.flix-progress-tracker__link:active::after, button.flix-progress-tracker__link:active::after {
  background-image: var(--flix-pressed-layer-color);
}

.flix-progress-tracker__item:last-child .flix-progress-tracker__link::after {
  display: none;
}
.flix-progress-tracker__item:not(:first-child) .flix-progress-tracker__link {
  padding-left: var(--flix-spacing-5);
}
.flix-progress-tracker__text {
  display: flex;
  align-items: center;
  overflow: hidden;
  column-gap: var(--flix-spacing-half);
  font-size: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 600px) {
  .flix-progress-tracker__text {
    align-items: center;
    font-size: var(--flix-font-size-primary);
  }
}
.flix-progress-tracker__text::before {
  content: counter(hc-progress-tracker-counter);
  font-size: var(--flix-font-size-primary);
}
@media (min-width: 600px) {
  .flix-progress-tracker__text::before {
    content: counter(hc-progress-tracker-counter) ".";
  }
}
.flix-progress-tracker__item--completed .flix-progress-tracker__text::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
  background: var(--flix-icon-checkmark) no-repeat center;
  vertical-align: middle;
}

.flix-quantity__info:not(:empty) {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-quantity__info:not(:empty) ~ .flix-quantity__info {
  margin-top: var(--flix-spacing-half);
}
.flix-quantity--error .flix-quantity__info {
  color: var(--flix-danger-dark-color);
}
.flix-quantity__info ~ .flix-quantity__info {
  color: var(--flix-content-primary-color);
}
.flix-quantity__label {
  display: block;
  margin-bottom: var(--flix-spacing-half);
  color: var(--flix-content-primary-color);
}
@media (min-width: 1200px) {
  .flix-quantity__label {
    color: var(--flix-content-secondary-color);
  }
}
.flix-quantity--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-quantity--disabled .flix-quantity__input,
.flix-quantity--disabled .flix-quantity__button {
  cursor: not-allowed;
}
.flix-quantity--error .flix-quantity__input {
  border: 1px solid var(--flix-danger-color);
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-quantity--error .flix-quantity__input:enabled:hover {
  background-image: var(--flix-hover-layer-color), linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-quantity--inline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.flix-quantity--inline .flix-quantity__label {
  align-self: center;
  margin-bottom: 0;
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-primary);
}
.flix-quantity--inline .flix-quantity__label small {
  display: block;
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
}
.flix-quantity--inline .flix-quantity__info {
  width: 100%;
  text-align: right;
}
.flix-quantity__picker {
  display: flex;
  align-items: stretch;
}
.flix-quantity__picker + .flix-quantity__info {
  margin-top: var(--flix-spacing-1);
}
.flix-quantity__input {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 var(--flix-spacing-2);
  border: 0;
  background: transparent;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  text-overflow: ellipsis;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: relative;
  height: var(--flix-input-height-mobile);
  border: 1px solid var(--flix-input-border-color);
  border-radius: var(--flix-input-border-radius);
  background-color: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
  align-self: center;
  width: 60px;
  border-radius: 0;
  text-align: center;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield;
}
.flix-quantity__input:enabled:hover, .flix-quantity__input[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-quantity__input::placeholder {
  opacity: 1;
  color: var(--flix-content-secondary-color);
  font-style: italic;
}
@media (min-width: 1200px) {
  .flix-quantity__input {
    height: var(--flix-input-height-desktop);
  }
}
.flix-quantity__input::-webkit-inner-spin-button, .flix-quantity__input::-webkit-outer-spin-button {
  margin: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.flix-quantity--active .flix-quantity__input, .flix-quantity__input:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
  z-index: 2;
}
.flix-quantity__input[readonly] {
  border: 0;
  font-weight: var(--flix-font-weight-bold);
}
.flix-quantity__input[readonly]:focus {
  outline: none;
}
.flix-quantity .flix-quantity__button {
  z-index: 1;
  padding: 0;
  font-size: 0;
  white-space: normal;
}
.flix-quantity .flix-quantity__button::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
}
.flix-quantity .flix-quantity__button:first-of-type, .flix-quantity .flix-quantity__button--minus {
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
}
.flix-quantity .flix-quantity__button:first-of-type::after, .flix-quantity .flix-quantity__button--minus::after {
  background: var(--flix-icon-minus) no-repeat center;
}
.flix-quantity .flix-quantity__button:last-of-type, .flix-quantity .flix-quantity__button--plus {
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
}
.flix-quantity .flix-quantity__button:last-of-type::after, .flix-quantity .flix-quantity__button--plus::after {
  background: var(--flix-icon-plus) no-repeat center;
}

.flix-radio {
  position: relative;
}
.flix-radio--sm .flix-radio__label {
  min-height: var(--flix-spacing-3);
  border-radius: var(--flix-primary-border-radius);
}
.flix-radio--sm .flix-radio__label:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-radio--sm .flix-radio__label::before {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
  margin-top: 2px;
}
.flix-radio--sm .flix-radio__input {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
}
.flix-radio--sm .flix-radio__input:checked + .flix-radio__label::before {
  background-size: 10px;
}
.flix-radio__label {
  display: inline-flex;
  align-items: center;
  min-height: var(--flix-spacing-5);
  margin: calc(-1 * var(--flix-spacing-half));
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-primary-border-radius);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  vertical-align: middle;
  cursor: pointer;
}
@supports (column-gap: normal) {
  .flix-radio__label {
    column-gap: var(--flix-spacing-2);
  }
}
.flix-radio__label::before {
  content: "";
  display: block;
  flex-shrink: 0;
  align-self: baseline;
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  margin-right: var(--flix-spacing-2);
  border: 2px solid var(--flix-input-border-color);
  border-radius: 100%;
  background: var(--flix-icon-radio-checkmark) no-repeat center;
  background-size: 0;
  transition: background-size 250ms ease;
}
@supports (column-gap: normal) {
  .flix-radio__label::before {
    margin-right: 0;
  }
}
.flix-radio__input {
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
}
.flix-radio__input:checked + .flix-radio__label::before {
  background-size: 20px;
}
.flix-radio__info:not(:empty) {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-radio__info:not(:empty) ~ .flix-radio__info {
  margin-top: var(--flix-spacing-half);
}
.flix-radio--error .flix-radio__info {
  color: var(--flix-danger-dark-color);
}
.flix-radio__info ~ .flix-radio__info {
  color: var(--flix-content-primary-color);
}
.flix-radio__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.flix-radio__input:focus + .flix-radio__label::before {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-radio__input:enabled + .flix-radio__label:hover, .flix-radio__input:enabled:hover + .flix-radio__label {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-radio__input:checked + .flix-radio__label::before {
  border-color: var(--flix-ui-primary-color);
}
.flix-radio--disabled .flix-radio__label {
  opacity: var(--flix-disabled-element-opacity);
  cursor: not-allowed;
}
.flix-radio--error .flix-radio__label::before {
  border-color: var(--flix-danger-color);
}
.flix-radio--error .flix-radio__input:not(:checked) + .flix-radio__label::before {
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-danger-color);
  transition: box-shadow 250ms ease;
}
.flix-radio--error .flix-radio__input:focus:not(:checked) + .flix-radio__label::before {
  box-shadow: inset 0 0 0 100vh var(--flix-bg-primary-transparent-color), inset 0 0 0 100vh var(--flix-danger-color), 0 0 0 4px var(--flix-grayscale-0-color);
}

.flix-range {
  position: relative;
  font-size: 0;
}
.flix-range__info:not(:empty) {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-range__info:not(:empty) ~ .flix-range__info {
  margin-top: var(--flix-spacing-half);
}
.flix-range--error .flix-range__info {
  color: var(--flix-danger-dark-color);
}
.flix-range__info ~ .flix-range__info {
  color: var(--flix-content-primary-color);
}
.flix-range__label {
  display: block;
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  word-break: break-all;
}
@media (min-width: 1200px) {
  .flix-range__label {
    color: var(--flix-content-secondary-color);
  }
}
.flix-range__value {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: inline-block;
  width: 50%;
  margin-bottom: var(--flix-spacing-half);
  word-break: break-all;
}
.flix-range__value--max {
  text-align: right;
}
.flix-range__field {
  position: relative;
  z-index: 1;
  height: var(--flix-spacing-5);
  overflow: visible;
}
.flix-range__field::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  top: 50%;
  right: calc(var(--flix-spacing-5) / 2);
  left: calc(var(--flix-spacing-5) / 2);
  height: 2px;
  margin-top: -1px;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-input-border-color);
}
.flix-range [type=range] {
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--flix-spacing-5);
  margin: 0;
  padding: 0;
  overflow: visible;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-range [type=range]::-webkit-slider-runnable-track {
  border: 0;
  background: transparent;
  border: 0 solid var(--flix-ui-primary-color);
}
.flix-range [type=range]::-webkit-slider-thumb {
  box-sizing: border-box;
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  -webkit-transition: border 250ms ease, background 250ms ease;
  transition: border 250ms ease, background 250ms ease;
  border: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
  border-radius: 100%;
  background: var(--flix-box-bg-color);
  cursor: pointer;
  position: relative;
  z-index: 5;
  outline: 0;
  pointer-events: all;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.flix-range [type=range]::-moz-range-track {
  border: 0;
  background: transparent;
}
.flix-range [type=range]::-moz-range-thumb {
  box-sizing: border-box;
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  -moz-transition: border 250ms ease, background 250ms ease;
  transition: border 250ms ease, background 250ms ease;
  border: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
  border-radius: 100%;
  background: var(--flix-box-bg-color);
  cursor: pointer;
  pointer-events: all;
}
.flix-range [type=range]::-moz-focus-outer {
  border: 0;
}
.flix-range [type=range]::-ms-track {
  border: 0;
  background: transparent;
  color: transparent;
}
.flix-range [type=range]::-ms-fill-lower {
  border: 0;
  background: transparent;
}
.flix-range [type=range]::-ms-fill-upper {
  border: 0;
  background: transparent;
}
.flix-range [type=range]::-ms-thumb {
  box-sizing: border-box;
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  -ms-transition: border 250ms ease, background 250ms ease;
  transition: border 250ms ease, background 250ms ease;
  border: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
  border-radius: 100%;
  background: var(--flix-box-bg-color);
  cursor: pointer;
  margin-top: 0;
}
.flix-range [type=range]:enabled:focus::-webkit-slider-thumb {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-range [type=range]:enabled:focus::-moz-range-thumb {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-range [type=range]:enabled:focus::-ms-thumb {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-range [type=range]:enabled:focus ~ .flix-range__steps {
  opacity: 1;
}
.flix-range [type=range]:enabled:active::-webkit-slider-thumb {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-range [type=range]:enabled:active::-moz-range-thumb {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-range [type=range]:enabled:active::-ms-thumb {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-range [type=range]:enabled:active ~ .flix-range__steps {
  opacity: 1;
}
.flix-range [type=range]:enabled:hover::-webkit-slider-thumb {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-range [type=range]:enabled:hover::-moz-range-thumb {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-range [type=range]:enabled:hover::-ms-thumb {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-range [type=range]:enabled:hover ~ .flix-range__steps {
  opacity: 1;
}
.flix-range__steps {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 0;
  right: calc(var(--flix-spacing-5) / 2);
  bottom: 0;
  left: calc(var(--flix-spacing-5) / 2);
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 250ms ease;
}
.flix-range__step {
  width: var(--flix-spacing-1);
  height: var(--flix-spacing-1);
  border-radius: 100%;
  background-color: var(--flix-input-border-color);
}
.flix-range__step--active {
  background-color: var(--flix-ui-primary-color);
}
.flix-range__track-range {
  position: absolute;
  z-index: 1;
  top: calc(var(--flix-spacing-5) / 2);
  height: 2px;
  margin-left: calc(var(--flix-spacing-5) / 2);
  transform: translateY(-1px);
  background: var(--flix-ui-primary-color);
}
.flix-range--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-range--disabled [type=range]::-moz-range-thumb,
.flix-range--disabled [type=range]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}
.flix-range--disabled [type=range]::-ms-thumb,
.flix-range--disabled [type=range]:disabled::-ms-thumb {
  cursor: not-allowed;
}
.flix-range--disabled [type=range]::-webkit-slider-thumb,
.flix-range--disabled [type=range]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}
.flix-range--double {
  pointer-events: none;
}
.flix-range--small .flix-range__field {
  height: var(--flix-spacing-3);
}
.flix-range--small .flix-range__field::before {
  right: calc(var(--flix-spacing-3) / 2);
  left: calc(var(--flix-spacing-3) / 2);
}
.flix-range--small [type=range] {
  height: var(--flix-spacing-3);
}
.flix-range--small [type=range]::-webkit-slider-thumb {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
}
.flix-range--small [type=range]::-moz-range-thumb {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
}
.flix-range--small [type=range]::-ms-thumb {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
}
.flix-range--small .flix-range__track-range {
  top: calc(var(--flix-spacing-3) / 2);
  margin-left: calc(var(--flix-spacing-3) / 2);
  transform: translateY(-1px);
}
.flix-range--small .flix-range__steps {
  right: calc(var(--flix-spacing-3) / 2);
  left: calc(var(--flix-spacing-3) / 2);
  height: var(--flix-spacing-3);
}

.flix-select-group {
  position: relative;
  border: 0;
}
.flix-select-group__info:not(:empty) {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-select-group__info:not(:empty) ~ .flix-select-group__info {
  margin-top: var(--flix-spacing-half);
}
.flix-select-group--error .flix-select-group__info {
  color: var(--flix-danger-dark-color);
}
.flix-select-group__info ~ .flix-select-group__info {
  color: var(--flix-content-primary-color);
}
.flix-select-group--error .flix-select-group__item {
  border-color: var(--flix-danger-color);
}
.flix-select-group--error .flix-select-group__item label {
  background-image: linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-select-group--error .flix-select-group__item label:hover {
  background-image: var(--flix-hover-layer-color), linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-select-group--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-select-group--disabled .flix-select-group__item label,
.flix-select-group--disabled .flix-select-group__item input {
  cursor: not-allowed;
}
.flix-select-group__items {
  display: inline-flex;
  position: relative;
  flex-wrap: wrap;
  padding-top: 1px;
  list-style-type: none;
}
.flix-select-group__label {
  z-index: 2;
}
.flix-select-group__item {
  min-width: var(--flix-input-height-mobile);
  height: var(--flix-input-height-mobile);
  margin-top: -1px;
  margin-left: -1px;
  border: 1px solid var(--flix-input-border-color);
  line-height: var(--flix-input-height-mobile);
  text-align: center;
}
@media (min-width: 1200px) {
  .flix-select-group__item {
    min-width: var(--flix-input-height-desktop);
    height: var(--flix-input-height-desktop);
    line-height: var(--flix-input-height-desktop);
  }
}
.flix-select-group__item:first-of-type, .flix-select-group__item:first-of-type label {
  border-radius: var(--flix-primary-border-radius) 0 0 var(--flix-primary-border-radius);
}
.flix-select-group__item:last-of-type, .flix-select-group__item:last-of-type label {
  border-radius: 0 var(--flix-primary-border-radius) var(--flix-primary-border-radius) 0;
}
.flix-select-group__item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.flix-select-group__item label {
  display: block;
  height: 100%;
  padding: 0 var(--flix-spacing-3);
  background: var(--flix-box-bg-color);
  font-weight: var(--flix-font-weight-semibold);
  cursor: pointer;
}
.flix-select-group__item label:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-select-group__item input:focus + label {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
  position: relative;
  z-index: 1;
}
.flix-select-group__item input:active + label {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-select-group__item input:checked + label {
  padding: 0 var(--flix-spacing-2);
  border-bottom: var(--flix-spacing-half) solid var(--flix-ui-primary-color);
}
.flix-select-group__item input:checked + label::before {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-2);
  height: var(--flix-spacing-2);
  margin-right: 0.2em;
  margin-left: -0.2em;
  background: var(--flix-icon-checkmark) no-repeat center;
}
.flix-select-group__item--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-select-group__item--disabled label,
.flix-select-group__item--disabled input {
  cursor: not-allowed;
}

.flix-select {
  position: relative;
}
.flix-select::after {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  background-size: var(--flix-spacing-4);
  vertical-align: middle;
  position: absolute;
  top: 10px;
  right: var(--flix-spacing-2);
  background: var(--flix-icon-arrow-down) no-repeat center;
  pointer-events: none;
}
@media (min-width: 1200px) {
  .flix-select::after {
    top: 33px;
  }
}
.flix-select__info:not(:empty) {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-select__info:not(:empty) ~ .flix-select__info {
  margin-top: var(--flix-spacing-half);
}
.flix-select--error .flix-select__info {
  color: var(--flix-danger-dark-color);
}
.flix-select__info ~ .flix-select__info {
  color: var(--flix-content-primary-color);
}
.flix-select__field {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 var(--flix-spacing-2);
  border: 0;
  background: transparent;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  text-overflow: ellipsis;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: relative;
  height: var(--flix-input-height-mobile);
  border: 1px solid var(--flix-input-border-color);
  border-radius: var(--flix-input-border-radius);
  background-color: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
  padding-right: var(--flix-spacing-6);
  background: transparent;
}
.flix-select__field::placeholder {
  opacity: 1;
  color: var(--flix-content-secondary-color);
  font-style: italic;
}
@media (min-width: 1200px) {
  .flix-select__field {
    height: var(--flix-input-height-desktop);
  }
}
.flix-select__field:enabled:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-select__field:enabled:active, .flix-select__field:enabled:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-select__field::-ms-expand {
  display: none;
}
.flix-select__field[disabled] {
  cursor: not-allowed;
}
.flix-select__field:not([multiple]) option, .flix-select__field:not([multiple]) optgroup {
  background: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
}
.flix-select--error::after, .flix-select--valid::after {
  right: var(--flix-spacing-7);
}
.flix-select--error .flix-select__field, .flix-select--valid .flix-select__field {
  padding-right: var(--flix-spacing-12);
  background-repeat: no-repeat;
  background-position: calc(100% - var(--flix-spacing-2)) center, 0 0, 0 0, 0 0;
  background-size: var(--flix-spacing-4), auto, auto, auto, auto;
}
.flix-select--error .flix-select__field {
  border: 1px solid var(--flix-danger-color);
  background-image: var(--flix-icon-attention-solid), linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-select--error .flix-select__field:hover {
  background-image: var(--flix-icon-attention-solid), var(--flix-hover-layer-color), linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-select--valid .flix-select__field {
  background-image: var(--flix-icon-checkmark-solid);
}
.flix-select--valid .flix-select__field:hover {
  background-image: var(--flix-icon-checkmark-solid), var(--flix-hover-layer-color);
}
.flix-select--active .flix-select__field, .flix-select__field:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-select--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-select--disabled .flix-select__field {
  cursor: not-allowed;
}
.flix-select--multiple.flix-select::after {
  display: none;
}
.flix-select--multiple.flix-select .flix-select__field {
  height: auto;
  padding-right: var(--flix-spacing-2);
}
.flix-select--multiple.flix-select .flix-select__field option {
  margin: var(--flix-spacing-1) 0;
  padding: var(--flix-spacing-half);
  border-radius: var(--flix-input-border-radius);
}
.flix-select--multiple.flix-select .flix-select__field:enabled option:enabled:hover, .flix-select--multiple.flix-select .flix-select__field:enabled option[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-select--multiple.flix-select .flix-select__field:enabled option:active, .flix-select--multiple.flix-select .flix-select__field:enabled option:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-select--multiple.flix-select .flix-select__field:disabled {
  overflow: hidden;
}
.flix-select--multiple.flix-select--valid .flix-select__field, .flix-select--multiple.flix-select--error .flix-select__field {
  padding-right: var(--flix-spacing-7);
  background-position: calc(100% - var(--flix-spacing-4)) var(--flix-spacing-1), 0 0;
}
@media (min-width: 1200px) {
  .flix-select--no-label::after {
    top: calc(10px - (var(--flix-input-height-mobile) - var(--flix-input-height-desktop)) / 2);
  }
}

.flix-skeleton {
  content: "";
  display: block;
  width: 100%;
  height: 18px;
  margin-bottom: var(--flix-spacing-1);
  animation: skeleton-blink 1.6s infinite linear;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-grayscale-30-color);
}
.flix-skeleton--w-xs {
  width: 25%;
}
.flix-skeleton--w-sm {
  width: 33%;
}
.flix-skeleton--w-md {
  width: 50%;
}
.flix-skeleton--w-lg {
  width: 75%;
}
.flix-skeleton--h-xs {
  height: 1px;
  margin-bottom: var(--flix-spacing-2);
}
.flix-skeleton--h-md {
  height: 30px;
  margin-bottom: var(--flix-spacing-2);
}
.flix-skeleton--h-lg {
  height: 44px;
  margin-bottom: var(--flix-spacing-2);
}
@media (min-width: 1200px) {
  .flix-skeleton--h-lg {
    height: 36px;
  }
}
.flix-skeleton--inline {
  display: inline-flex;
  vertical-align: middle;
}
.flix-skeleton--round {
  border-radius: var(--flix-spacing-5);
}
.flix-skeleton--flush-bottom {
  margin-bottom: 0;
}
@keyframes skeleton-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.flix-btn, .flix-skip-link {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  min-width: 142px;
  max-width: 100%;
  height: var(--flix-input-height-mobile);
  padding: 0 var(--flix-spacing-2);
  overflow: hidden;
  column-gap: var(--flix-spacing-half);
  border: 1px solid var(--flix-grayscale-50-color);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-button);
  font-weight: var(--flix-font-weight-semibold);
  line-height: var(--flix-input-height-mobile);
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: background 250ms ease;
}
.flix-btn:enabled:hover, .flix-skip-link:enabled:hover, .flix-btn[href]:hover, [href].flix-skip-link:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-btn:active, .flix-skip-link:active, .flix-btn:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
.flix-btn:focus, .flix-skip-link:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-btn.flix-btn--loading, .flix-btn--loading.flix-skip-link {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
@media (min-width: 600px) {
  .flix-btn, .flix-skip-link {
    width: auto;
  }
}
@media (min-width: 1200px) {
  .flix-btn, .flix-skip-link {
    height: var(--flix-input-height-desktop);
    line-height: var(--flix-input-height-desktop);
  }
}
.flix-btn + .flix-btn, .flix-skip-link + .flix-btn, .flix-btn + .flix-skip-link, .flix-skip-link + .flix-skip-link {
  margin-top: var(--flix-spacing-2);
}
@media (min-width: 600px) {
  .flix-btn + .flix-btn, .flix-skip-link + .flix-btn, .flix-btn + .flix-skip-link, .flix-skip-link + .flix-skip-link {
    margin-top: 0;
    margin-left: var(--flix-spacing-2);
  }
}
.flix-btn--sm {
  width: auto;
  min-width: auto;
  height: var(--flix-spacing-4);
  font-size: var(--flix-font-size-small);
}
.flix-btn--sm.flix-btn--square {
  width: var(--flix-spacing-4);
  min-width: var(--flix-spacing-4);
}
.flix-btn--md {
  width: auto;
  height: var(--flix-spacing-6);
}
.flix-btn--md.flix-btn--square {
  width: var(--flix-spacing-6);
  min-width: var(--flix-spacing-6);
}
.flix-btn--lg {
  width: auto;
  height: var(--flix-spacing-8);
  padding: 0 var(--flix-spacing-4);
}
.flix-btn--lg.flix-btn--square {
  width: var(--flix-spacing-8);
  min-width: var(--flix-spacing-8);
}
.flix-btn--block {
  display: flex;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}
.flix-btn--primary {
  border-color: var(--flix-button-primary-color);
  background: var(--flix-button-primary-color);
  color: var(--flix-button-label-color);
}
.flix-btn--primary.flix-btn--loading::before {
  border-left-color: var(--flix-button-label-color);
}
.flix-btn--secondary {
  border-color: var(--flix-button-secondary-color);
  background: var(--flix-button-secondary-color);
  color: var(--flix-button-label-color);
}
.flix-btn--secondary.flix-btn--loading::before {
  border-left-color: var(--flix-button-label-color);
}
.flix-btn--tertiary {
  border-color: var(--flix-button-secondary-color);
  background: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
}
.flix-btn--tertiary.flix-btn--loading::before {
  border-left-color: var(--flix-content-primary-color);
}
.flix-btn--danger {
  border-color: var(--flix-danger-color);
  background: var(--flix-danger-color);
  color: var(--flix-grayscale-0-color);
}
.flix-btn--danger.flix-btn--loading::before {
  border-left-color: var(--flix-grayscale-0-color);
}
.flix-btn:disabled, .flix-skip-link:disabled, .flix-btn--disabled {
  opacity: var(--flix-disabled-element-opacity);
  cursor: not-allowed;
}
.flix-btn--loading::before {
  content: "";
  display: inline-block;
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
  margin-right: var(--flix-spacing-half);
  margin-left: var(--flix-spacing-half);
  animation: btn-spinner-rotate 1s infinite linear, btn-spinner-grow 250ms;
  border: var(--flix-spacing-half) solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  border-left-color: var(--flix-content-primary-color);
}
@keyframes btn-spinner-grow {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  100% {
    width: var(--flix-spacing-4);
    height: var(--flix-spacing-4);
    opacity: 1;
  }
}
@keyframes btn-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.flix-btn--loading .flix-btn__icon {
  display: none;
}
.flix-btn--link {
  width: auto;
  min-width: auto;
  padding: 0 var(--flix-spacing-half);
  border-color: transparent;
  background: transparent;
  color: var(--flix-content-primary-color);
}
.flix-btn--link + .flix-btn--link {
  margin-top: 0;
  margin-left: var(--flix-spacing-2);
}
.flix-btn--link.flix-btn--loading::before {
  margin-left: 0;
}
.flix-btn--square {
  width: var(--flix-input-height-mobile);
  min-width: var(--flix-input-height-mobile);
  padding: 0;
}
@media (min-width: 1200px) {
  .flix-btn--square {
    width: var(--flix-input-height-desktop);
    min-width: var(--flix-input-height-desktop);
  }
}
.flix-btn--square + .flix-btn--square {
  margin-top: 0;
  margin-left: var(--flix-spacing-2);
}

.flix-skip-link + .flix-skip-link {
  margin-top: 0;
  margin-left: 0;
}

.flix-spinner {
  animation: spinner-rotate 1s infinite linear;
  border: var(--flix-spacing-half) solid var(--flix-ui-primary-light-color);
  border-radius: 50%;
  border-left-color: var(--flix-ui-primary-dark-color);
  display: inline-block;
  width: calc(var(--flix-spacing-12) + var(--flix-spacing-3));
  height: calc(var(--flix-spacing-12) + var(--flix-spacing-3));
}
@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.flix-spinner--sm {
  width: var(--flix-spacing-4);
  height: var(--flix-spacing-4);
}
.flix-spinner--md {
  width: var(--flix-spacing-6);
  height: var(--flix-spacing-6);
}
.flix-spinner--lg {
  width: var(--flix-spacing-8);
  height: var(--flix-spacing-8);
}
.flix-spinner--xl {
  width: var(--flix-spacing-12);
  height: var(--flix-spacing-12);
}
.flix-spinner--xxl {
  width: calc(var(--flix-spacing-12) + var(--flix-spacing-4));
  height: calc(var(--flix-spacing-12) + var(--flix-spacing-4));
}

.flix-switch {
  display: block;
  position: relative;
  line-height: var(--flix-spacing-5);
}
@media (min-width: 600px) {
  .flix-switch {
    display: inline-block;
  }
}
.flix-switch__info:not(:empty) {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-switch__info:not(:empty) ~ .flix-switch__info {
  margin-top: var(--flix-spacing-half);
}
.flix-switch--error .flix-switch__info {
  color: var(--flix-danger-dark-color);
}
.flix-switch__info ~ .flix-switch__info {
  color: var(--flix-content-primary-color);
}
.flix-switch__input {
  position: absolute;
  opacity: 0;
}
.flix-switch__input:enabled + .flix-switch__label:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
.flix-switch__label {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  display: inline-flex;
  position: relative;
  align-items: center;
  min-width: 100%;
  min-height: calc(var(--flix-spacing-5) + var(--flix-spacing-half) * 2);
  margin: calc(-1 * var(--flix-spacing-half));
  padding: var(--flix-spacing-half);
  padding-right: calc(var(--flix-spacing-8) + var(--flix-spacing-2) + var(--flix-spacing-half));
  border-radius: var(--flix-primary-border-radius);
  cursor: pointer;
}
@media (min-width: 600px) {
  .flix-switch__label {
    min-width: 0;
    padding-right: var(--flix-spacing-half);
    padding-left: calc(var(--flix-spacing-8) + var(--flix-spacing-2));
  }
}
.flix-switch__label::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: var(--flix-spacing-8);
  height: var(--flix-spacing-5);
  transform: translateY(-50%);
  transition: transform 0.4s, background-color 0.4s;
  border-radius: var(--flix-spacing-5);
  background-color: var(--flix-input-border-color);
}
@media (min-width: 600px) {
  .flix-switch__label::before {
    right: auto;
    left: var(--flix-spacing-half);
  }
}
.flix-switch__label::after {
  content: "";
  position: absolute;
  top: 50%;
  right: var(--flix-spacing-3);
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-5);
  transform: translateY(-50%);
  transition: transform 0.4s, background 0.4s;
  border: 2px solid var(--flix-input-border-color);
  border-radius: 100%;
  background: var(--flix-grayscale-0-color) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath d='M0,0h1v1H0' fill='transparent'/%3E%3C/svg%3E") no-repeat center;
  background-size: calc(var(--flix-spacing-5) / 2);
}
@media (min-width: 600px) {
  .flix-switch__label::after {
    right: auto;
    left: var(--flix-spacing-half);
  }
}
.flix-switch__input:focus + .flix-switch__label {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-switch__input:checked + .flix-switch__label::after {
  transform: translateY(-50%) translateX(var(--flix-spacing-3));
  border-color: var(--flix-ui-primary-color);
  background: var(--flix-grayscale-0-color) var(--flix-icon-checkmark) no-repeat center;
  background-size: calc(var(--flix-spacing-5) / 2);
}
.flix-switch__input:checked + .flix-switch__label::before {
  background-color: var(--flix-ui-primary-color);
}
.flix-switch--sm {
  min-height: var(--flix-spacing-3);
  line-height: var(--flix-spacing-3);
}
.flix-switch--sm .flix-switch__label {
  padding-right: calc(var(--flix-spacing-5) + var(--flix-spacing-2));
}
@media (min-width: 600px) {
  .flix-switch--sm .flix-switch__label {
    padding-right: var(--flix-spacing-half);
    padding-left: calc(var(--flix-spacing-5) + var(--flix-spacing-2));
  }
}
.flix-switch--sm .flix-switch__label::before {
  width: var(--flix-spacing-5);
  height: var(--flix-spacing-3);
  border-radius: var(--flix-spacing-3);
}
.flix-switch--sm .flix-switch__label::after {
  right: var(--flix-spacing-2);
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
  background-size: calc(var(--flix-spacing-3) / 2);
}
.flix-switch--sm .flix-switch__input:checked + .flix-switch__label::after {
  transform: translateY(-50%) translateX(var(--flix-spacing-2));
  background-size: calc(var(--flix-spacing-3) / 2);
}
.flix-switch--stacked .flix-switch__label {
  padding-bottom: calc(var(--flix-spacing-5) + var(--flix-spacing-2)/2);
  padding-left: var(--flix-spacing-half);
}
.flix-switch--stacked .flix-switch__label::before, .flix-switch--stacked .flix-switch__label::after {
  top: calc(50% + var(--flix-spacing-2));
  bottom: 0;
  left: var(--flix-spacing-half);
}
@media (min-width: 1200px) {
  .flix-switch--stacked .flix-switch__label {
    color: var(--flix-content-secondary-color);
  }
}
.flix-switch--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-switch--disabled .flix-switch__label {
  cursor: not-allowed;
}

.flix-table {
  border-spacing: var(--flix-spacing-4) 0;
  border-collapse: separate;
}
.flix-table caption {
  padding: var(--flix-spacing-2);
  caption-side: bottom;
  border-top: 1px solid var(--flix-line-primary-color);
  color: var(--flix-secondary-content-color);
  text-align: center;
}
.flix-table__wrap {
  display: inline-block;
  margin-bottom: var(--flix-spacing-2);
  padding: var(--flix-spacing-2) 0;
  border: 1px solid var(--flix-line-primary-color);
  border-radius: var(--flix-primary-border-radius);
}
.flix-table caption {
  padding-bottom: 0;
}
.flix-table th,
.flix-table td {
  padding: var(--flix-spacing-2) 0;
  border: solid var(--flix-line-primary-color);
  border-width: 0 0 1px;
  text-align: left;
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
}
.flix-table__header th,
.flix-table__header td, .flix-table__footer th,
.flix-table__footer td {
  padding: var(--flix-spacing-2) 0;
  border: solid var(--flix-line-primary-color);
  border-width: 0 0 1px;
  text-align: left;
  color: var(--flix-heading-font-color);
  font-size: var(--flix-font-size-h4);
  font-weight: var(--flix-font-weight-bold);
  line-height: var(--flix-line-height-h4);
}
.flix-table tr:last-child th,
.flix-table tr:last-child td {
  border-width: 0;
}
.flix-table .flix-table__header tr th, .flix-table .flix-table__header tr td {
  border-width: 0 0 1px;
}
.flix-table .flix-table__footer tr th, .flix-table .flix-table__footer tr td {
  border-width: 1px 0 0;
}

.flix-tag {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: var(--flix-spacing-5);
  padding: 0 var(--flix-spacing-2);
  overflow: hidden;
  column-gap: var(--flix-spacing-half);
  border: 1px solid var(--flix-grayscale-50-color);
  border-radius: var(--flix-spacing-5);
  outline: 0;
  background: var(--flix-grayscale-50-color);
  color: var(--flix-grayscale-100-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-semibold);
  line-height: calc(var(--flix-spacing-5) - 2px);
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}
.flix-tag.flix-tag--outlined {
  background: var(--flix-grayscale-0-color);
  color: var(--flix-grayscale-100-color);
}
.flix-tag.flix-tag--outlined .flix-tag__close {
  position: relative;
}
.flix-tag.flix-tag--outlined .flix-tag__close::before, .flix-tag.flix-tag--outlined .flix-tag__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-grayscale-100-color);
}
.flix-tag.flix-tag--outlined .flix-tag__close::before {
  transform: rotate(45deg);
}
.flix-tag.flix-tag--outlined .flix-tag__close::after {
  transform: rotate(-45deg);
}
.flix-tag--success {
  border-color: var(--flix-success-color);
  background: var(--flix-success-color);
  color: var(--flix-grayscale-0-color);
}
.flix-tag--success.flix-tag--outlined {
  background: var(--flix-grayscale-0-color);
  color: var(--flix-success-dark-color);
}
.flix-tag--success.flix-tag--outlined .flix-tag__close {
  position: relative;
}
.flix-tag--success.flix-tag--outlined .flix-tag__close::before, .flix-tag--success.flix-tag--outlined .flix-tag__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-success-dark-color);
}
.flix-tag--success.flix-tag--outlined .flix-tag__close::before {
  transform: rotate(45deg);
}
.flix-tag--success.flix-tag--outlined .flix-tag__close::after {
  transform: rotate(-45deg);
}
.flix-tag--warning {
  border-color: var(--flix-warning-color);
  background: var(--flix-warning-color);
  color: var(--flix-grayscale-0-color);
}
.flix-tag--warning.flix-tag--outlined {
  background: var(--flix-grayscale-0-color);
  color: var(--flix-warning-dark-color);
}
.flix-tag--warning.flix-tag--outlined .flix-tag__close {
  position: relative;
}
.flix-tag--warning.flix-tag--outlined .flix-tag__close::before, .flix-tag--warning.flix-tag--outlined .flix-tag__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-warning-dark-color);
}
.flix-tag--warning.flix-tag--outlined .flix-tag__close::before {
  transform: rotate(45deg);
}
.flix-tag--warning.flix-tag--outlined .flix-tag__close::after {
  transform: rotate(-45deg);
}
.flix-tag--danger {
  border-color: var(--flix-danger-color);
  background: var(--flix-danger-color);
  color: var(--flix-grayscale-0-color);
}
.flix-tag--danger.flix-tag--outlined {
  background: var(--flix-grayscale-0-color);
  color: var(--flix-danger-dark-color);
}
.flix-tag--danger.flix-tag--outlined .flix-tag__close {
  position: relative;
}
.flix-tag--danger.flix-tag--outlined .flix-tag__close::before, .flix-tag--danger.flix-tag--outlined .flix-tag__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-danger-dark-color);
}
.flix-tag--danger.flix-tag--outlined .flix-tag__close::before {
  transform: rotate(45deg);
}
.flix-tag--danger.flix-tag--outlined .flix-tag__close::after {
  transform: rotate(-45deg);
}
.flix-tag--small {
  height: var(--flix-spacing-3);
  padding: 0 var(--flix-spacing-1);
  font-size: var(--flix-font-size-small);
  line-height: calc(var(--flix-spacing-3) - 2px);
}
.flix-tag--small .flix-tag__icon {
  font-size: 1em;
}
.flix-tag--small .flix-tag__close {
  width: var(--flix-spacing-3);
  height: var(--flix-spacing-3);
}
button.flix-tag,
a.flix-tag {
  cursor: pointer;
}
button.flix-tag:enabled:hover, button.flix-tag[href]:hover,
a.flix-tag:enabled:hover,
a.flix-tag[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
button.flix-tag:active, button.flix-tag:enabled:active,
a.flix-tag:active,
a.flix-tag:enabled:active {
  background-image: var(--flix-pressed-layer-color);
  text-decoration: none;
}
button.flix-tag:focus,
a.flix-tag:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}

.flix-tag:disabled, .flix-tag--disabled {
  opacity: var(--flix-disabled-element-opacity);
  cursor: not-allowed;
}
a.flix-tag--disabled {
  cursor: not-allowed;
}

.flix-tag--success .flix-tag__icon, .flix-tag--warning .flix-tag__icon, .flix-tag--danger .flix-tag__icon {
  color: inherit;
}
.flix-tag__close {
  position: relative;
  position: relative;
  z-index: 1;
}
.flix-tag__close::before, .flix-tag__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-grayscale-100-color);
}
.flix-tag__close::before {
  transform: rotate(45deg);
}
.flix-tag__close::after {
  transform: rotate(-45deg);
}
.flix-tag--success .flix-tag__close, .flix-tag--warning .flix-tag__close, .flix-tag--danger .flix-tag__close {
  position: relative;
}
.flix-tag--success .flix-tag__close::before, .flix-tag--success .flix-tag__close::after, .flix-tag--warning .flix-tag__close::before, .flix-tag--warning .flix-tag__close::after, .flix-tag--danger .flix-tag__close::before, .flix-tag--danger .flix-tag__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-grayscale-0-color);
}
.flix-tag--success .flix-tag__close::before, .flix-tag--warning .flix-tag__close::before, .flix-tag--danger .flix-tag__close::before {
  transform: rotate(45deg);
}
.flix-tag--success .flix-tag__close::after, .flix-tag--warning .flix-tag__close::after, .flix-tag--danger .flix-tag__close::after {
  transform: rotate(-45deg);
}

.flix-text, .flix-text p {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  margin: 0;
}
.flix-text:where(:not(:last-child)), .flix-text p:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-text--small, .flix-text--small p {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  margin: 0;
}
.flix-text--small:where(:not(:last-child)), .flix-text--small p:where(:not(:last-child)) {
  margin-bottom: var(--flix-spacing-2);
}
.flix-textarea {
  position: relative;
}
.flix-textarea__info:not(:empty) {
  color: var(--flix-content-primary-color);
  font-size: var(--flix-font-size-small);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-small);
  display: block;
  margin-top: var(--flix-spacing-1);
}
.flix-textarea__info:not(:empty) ~ .flix-textarea__info {
  margin-top: var(--flix-spacing-half);
}
.flix-textarea--error .flix-textarea__info {
  color: var(--flix-danger-dark-color);
}
.flix-textarea__info ~ .flix-textarea__info {
  color: var(--flix-content-primary-color);
}
.flix-textarea__field {
  position: relative;
  width: 100%;
  min-height: calc(var(--flix-input-height-mobile) * 2 + var(--flix-spacing-4));
  padding: var(--flix-spacing-1) var(--flix-spacing-2);
  overflow: auto;
  border: 1px solid var(--flix-input-border-color);
  border-radius: var(--flix-input-border-radius);
  background-color: var(--flix-box-bg-color);
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  font-weight: var(--flix-font-weight-normal);
  line-height: var(--flix-line-height-primary);
  vertical-align: top;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.flix-textarea__field:enabled:hover, .flix-textarea__field[href]:hover {
  background-image: var(--flix-hover-layer-color);
  text-decoration: none;
}
@media (min-width: 1200px) {
  .flix-textarea__field {
    min-height: calc(var(--flix-input-height-desktop) * 2 + var(--flix-spacing-4) + 24px);
    padding: var(--flix-spacing-1) var(--flix-spacing-2);
  }
}
.flix-textarea__field::placeholder {
  opacity: 1;
  color: var(--flix-content-secondary-color);
  font-style: italic;
}
.flix-textarea--valid .flix-textarea__field {
  padding-right: var(--flix-spacing-8);
}
.flix-textarea--error .flix-textarea__field {
  padding-right: var(--flix-spacing-8);
  border: 1px solid var(--flix-danger-color);
}
.flix-textarea__field:focus, .flix-textarea--active .flix-textarea__field {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-textarea__field[disabled], .flix-textarea--disabled .flix-textarea__field {
  cursor: not-allowed;
}
.flix-textarea--error::after, .flix-textarea--valid::after {
  right: var(--flix-spacing-7);
}
.flix-textarea--error .flix-textarea__field, .flix-textarea--valid .flix-textarea__field {
  padding-right: var(--flix-spacing-6);
  background-repeat: no-repeat;
  background-position: calc(100% - var(--flix-spacing-2)) var(--flix-spacing-1), 0 0, 0 0, 0 0;
  background-size: var(--flix-spacing-4), auto, auto, auto, auto;
}
.flix-textarea--error .flix-textarea__field {
  border: 1px solid var(--flix-danger-color);
  background-image: var(--flix-icon-attention-solid), linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-textarea--error .flix-textarea__field:hover {
  background-image: var(--flix-icon-attention-solid), var(--flix-hover-layer-color), linear-gradient(var(--flix-bg-primary-transparent-color), var(--flix-bg-primary-transparent-color)), linear-gradient(var(--flix-danger-color), var(--flix-danger-color));
}
.flix-textarea--valid .flix-textarea__field {
  background-image: var(--flix-icon-checkmark-solid);
}
.flix-textarea--valid .flix-textarea__field:hover {
  background-image: var(--flix-icon-checkmark-solid), var(--flix-hover-layer-color);
}
.flix-textarea--active .flix-textarea__field, .flix-textarea__field:focus {
  outline: 2px solid var(--flix-focus-outline-color);
  outline-offset: 1px;
  box-shadow: 0 0 0 4px var(--flix-grayscale-0-color);
}
.flix-textarea--disabled {
  opacity: var(--flix-disabled-element-opacity);
}
.flix-textarea--disabled .flix-textarea__field {
  cursor: not-allowed;
}

.flix-balloon, .flix-tooltip {
  z-index: 10;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 15.75rem;
  min-height: 44px;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  text-decoration: none;
  padding: var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-4);
  position: relative;
}
.flix-balloon:not([hidden]), .flix-tooltip:not([hidden]) {
  display: flex;
}
.flix-balloon::before, .flix-tooltip::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-balloon::before, .flix-tooltip::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: var(--flix-spacing-2);
}
.flix-balloon::after, .flix-tooltip::after {
  position: absolute;
  z-index: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-right: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-left: 0;
  right: auto;
  left: var(--flix-spacing-1);
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}
.flix-balloon--content-fit, .flix-tooltip--content-fit {
  min-width: auto;
  min-height: auto;
  white-space: nowrap;
}
.flix-balloon--medium, .flix-tooltip--medium {
  z-index: 10;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 23.25rem;
  min-height: 44px;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  text-decoration: none;
  padding: var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-4);
}
.flix-balloon--medium:not([hidden]), .flix-tooltip--medium:not([hidden]) {
  display: flex;
}
.flix-balloon--medium::before, .flix-tooltip--medium::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-balloon--medium::before, .flix-tooltip--medium::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: var(--flix-spacing-2);
}
.flix-balloon--large, .flix-tooltip--large {
  z-index: 10;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 30.75rem;
  min-height: 44px;
  color: var(--flix-content-primary-color);
  font-family: inherit;
  font-size: var(--flix-font-size-primary);
  text-decoration: none;
  padding: var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-4);
}
.flix-balloon--large:not([hidden]), .flix-tooltip--large:not([hidden]) {
  display: flex;
}
.flix-balloon--large::before, .flix-tooltip--large::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-box-bg-color);
  box-shadow: var(--flix-primary-box-shadow);
}
.flix-balloon--large::before, .flix-tooltip--large::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: var(--flix-spacing-2);
}
.flix-balloon--danger, .flix-tooltip--danger {
  color: var(--flix-bg-primary-color);
}
.flix-balloon--danger::before, .flix-tooltip--danger::before {
  background: var(--flix-danger-color);
}
.flix-balloon--danger::after, .flix-tooltip--danger::after {
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-right: calc(var(--flix-spacing-1) + 1px) solid var(--flix-danger-color);
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-left: 0;
}
.flix-balloon--start::after, .flix-tooltip--start::after {
  top: var(--flix-spacing-3);
  transform: none;
}
@supports (top: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--start::after, .flix-tooltip--start::after {
    top: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--end::after, .flix-tooltip--end::after {
  top: auto;
  bottom: var(--flix-spacing-3);
  transform: none;
}
@supports (bottom: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--end::after, .flix-tooltip--end::after {
    bottom: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--left, .flix-tooltip--left {
  padding: var(--flix-spacing-2) var(--flix-spacing-4) var(--flix-spacing-2) var(--flix-spacing-2);
}
.flix-balloon--left::before, .flix-tooltip--left::before {
  top: 0;
  right: var(--flix-spacing-2);
  bottom: 0;
  left: 0;
}
.flix-balloon--left::after, .flix-tooltip--left::after {
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-right: 0;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-left: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  right: var(--flix-spacing-1);
  left: auto;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}
.flix-balloon--left.flix-balloon--danger::after, .flix-balloon--danger.flix-tooltip--left::after, .flix-balloon--left.flix-tooltip--danger::after, .flix-tooltip--danger.flix-tooltip--left::after {
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-right: 0;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-left: calc(var(--flix-spacing-1) + 1px) solid var(--flix-danger-color);
}
.flix-balloon--left.flix-balloon--start::after, .flix-balloon--start.flix-tooltip--left::after, .flix-balloon--left.flix-tooltip--start::after, .flix-tooltip--start.flix-tooltip--left::after {
  top: var(--flix-spacing-3);
  transform: none;
}
@supports (top: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--left.flix-balloon--start::after, .flix-balloon--start.flix-tooltip--left::after, .flix-balloon--left.flix-tooltip--start::after, .flix-tooltip--start.flix-tooltip--left::after {
    top: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--left.flix-balloon--end::after, .flix-balloon--end.flix-tooltip--left::after, .flix-balloon--left.flix-tooltip--end::after, .flix-tooltip--end.flix-tooltip--left::after {
  top: auto;
  bottom: var(--flix-spacing-3);
  transform: none;
}
@supports (bottom: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--left.flix-balloon--end::after, .flix-balloon--end.flix-tooltip--left::after, .flix-balloon--left.flix-tooltip--end::after, .flix-tooltip--end.flix-tooltip--left::after {
    bottom: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--top, .flix-tooltip--top {
  padding: var(--flix-spacing-2) var(--flix-spacing-2) var(--flix-spacing-4);
}
.flix-balloon--top::before, .flix-tooltip--top::before {
  top: 0;
  right: 0;
  bottom: var(--flix-spacing-2);
  left: 0;
}
.flix-balloon--top::after, .flix-tooltip--top::after {
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: 0;
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
  top: auto;
  bottom: var(--flix-spacing-1);
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}
.flix-balloon--top.flix-balloon--danger::after, .flix-balloon--danger.flix-tooltip--top::after, .flix-balloon--top.flix-tooltip--danger::after, .flix-tooltip--danger.flix-tooltip--top::after {
  content: "";
  width: 0;
  height: 0;
  border-top: calc(var(--flix-spacing-1) + 1px) solid var(--flix-danger-color);
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: 0;
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
}
.flix-balloon--top.flix-balloon--start::after, .flix-balloon--start.flix-tooltip--top::after, .flix-balloon--top.flix-tooltip--start::after, .flix-tooltip--start.flix-tooltip--top::after {
  left: var(--flix-spacing-3);
  transform: none;
}
@supports (left: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--top.flix-balloon--start::after, .flix-balloon--start.flix-tooltip--top::after, .flix-balloon--top.flix-tooltip--start::after, .flix-tooltip--start.flix-tooltip--top::after {
    left: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--top.flix-balloon--end::after, .flix-balloon--end.flix-tooltip--top::after, .flix-balloon--top.flix-tooltip--end::after, .flix-tooltip--end.flix-tooltip--top::after {
  right: var(--flix-spacing-3);
  left: auto;
  transform: none;
}
@supports (right: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--top.flix-balloon--end::after, .flix-balloon--end.flix-tooltip--top::after, .flix-balloon--top.flix-tooltip--end::after, .flix-tooltip--end.flix-tooltip--top::after {
    right: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--bottom, .flix-tooltip--bottom {
  padding: var(--flix-spacing-4) var(--flix-spacing-2) var(--flix-spacing-2);
}
.flix-balloon--bottom::before, .flix-tooltip--bottom::before {
  top: var(--flix-spacing-2);
  right: 0;
  bottom: 0;
  left: 0;
}
.flix-balloon--bottom::after, .flix-tooltip--bottom::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0;
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid var(--flix-box-bg-color);
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
  top: var(--flix-spacing-1);
  bottom: auto;
  right: auto;
  left: 50%;
  transform: translateX(-50%);
}
.flix-balloon--bottom.flix-balloon--danger::after, .flix-balloon--danger.flix-tooltip--bottom::after, .flix-balloon--bottom.flix-tooltip--danger::after, .flix-tooltip--danger.flix-tooltip--bottom::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0;
  border-right: calc(var(--flix-spacing-1) + 1px) solid transparent;
  border-bottom: calc(var(--flix-spacing-1) + 1px) solid var(--flix-danger-color);
  border-left: calc(var(--flix-spacing-1) + 1px) solid transparent;
}
.flix-balloon--bottom.flix-balloon--start::after, .flix-balloon--start.flix-tooltip--bottom::after, .flix-balloon--bottom.flix-tooltip--start::after, .flix-tooltip--start.flix-tooltip--bottom::after {
  left: var(--flix-spacing-3);
  transform: none;
}
@supports (left: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--bottom.flix-balloon--start::after, .flix-balloon--start.flix-tooltip--bottom::after, .flix-balloon--bottom.flix-tooltip--start::after, .flix-tooltip--start.flix-tooltip--bottom::after {
    left: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon--bottom.flix-balloon--end::after, .flix-balloon--end.flix-tooltip--bottom::after, .flix-balloon--bottom.flix-tooltip--end::after, .flix-tooltip--end.flix-tooltip--bottom::after {
  right: var(--flix-spacing-3);
  left: auto;
  transform: none;
}
@supports (right: min(25%, var(--flix-spacing-3))) {
  .flix-balloon--bottom.flix-balloon--end::after, .flix-balloon--end.flix-tooltip--bottom::after, .flix-balloon--bottom.flix-tooltip--end::after, .flix-tooltip--end.flix-tooltip--bottom::after {
    right: min(25%, var(--flix-spacing-3));
  }
}
.flix-balloon__content, .flix-tooltip__content {
  position: relative;
  z-index: 1;
  flex: auto;
  text-align: left;
}
.flix-balloon--content-fit .flix-balloon__content, .flix-balloon--content-fit .flix-tooltip__content, .flix-tooltip--content-fit .flix-balloon__content, .flix-tooltip--content-fit .flix-tooltip__content {
  margin: calc(-1 * var(--flix-spacing-1));
}
.flix-balloon__close, .flix-tooltip__close {
  position: relative;
  position: relative;
  z-index: 1;
  align-self: flex-start;
}
.flix-balloon__close::before, .flix-tooltip__close::before, .flix-balloon__close::after, .flix-tooltip__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-icon-primary-color);
}
.flix-balloon__close::before, .flix-tooltip__close::before {
  transform: rotate(45deg);
}
.flix-balloon__close::after, .flix-tooltip__close::after {
  transform: rotate(-45deg);
}
.flix-balloon--danger .flix-balloon__close, .flix-balloon--danger .flix-tooltip__close, .flix-tooltip--danger .flix-balloon__close, .flix-tooltip--danger .flix-tooltip__close {
  position: relative;
  color: var(--flix-bg-primary-color);
}
.flix-balloon--danger .flix-balloon__close::before, .flix-balloon--danger .flix-tooltip__close::before, .flix-tooltip--danger .flix-balloon__close::before, .flix-tooltip--danger .flix-tooltip__close::before, .flix-balloon--danger .flix-balloon__close::after, .flix-balloon--danger .flix-tooltip__close::after, .flix-tooltip--danger .flix-balloon__close::after, .flix-tooltip--danger .flix-tooltip__close::after {
  content: "";
  display: block;
  position: absolute;
  width: var(--flix-spacing-3);
  height: 2px;
  margin: 0 var(--flix-spacing-1);
  border-radius: var(--flix-primary-border-radius);
  background: var(--flix-bg-primary-color);
}
.flix-balloon--danger .flix-balloon__close::before, .flix-balloon--danger .flix-tooltip__close::before, .flix-tooltip--danger .flix-balloon__close::before, .flix-tooltip--danger .flix-tooltip__close::before {
  transform: rotate(45deg);
}
.flix-balloon--danger .flix-balloon__close::after, .flix-balloon--danger .flix-tooltip__close::after, .flix-tooltip--danger .flix-balloon__close::after, .flix-tooltip--danger .flix-tooltip__close::after {
  transform: rotate(-45deg);
}

.flix-tooltip {
  left: 100%;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  transition: opacity 250ms ease;
  display: none;
  position: absolute;
  z-index: 10;
  opacity: 0;
}
.flix-tooltip--active {
  display: flex;
  justify-content: space-between;
  opacity: 1;
}
.flix-tooltip--start {
  top: calc(-1 * var(--flix-spacing-1));
  bottom: auto;
  transform: none;
}
.flix-tooltip--end {
  top: auto;
  bottom: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-tooltip--left {
  right: 100%;
  left: auto;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}
.flix-tooltip--left.flix-tooltip--start {
  top: calc(-1 * var(--flix-spacing-1));
  bottom: auto;
  transform: none;
}
.flix-tooltip--left.flix-tooltip--end {
  top: auto;
  bottom: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-tooltip--top {
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.flix-tooltip--top.flix-tooltip--start {
  right: auto;
  left: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-tooltip--top.flix-tooltip--end {
  right: calc(-1 * var(--flix-spacing-1));
  left: auto;
  transform: none;
}
.flix-tooltip--bottom {
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}
.flix-tooltip--bottom.flix-tooltip--start {
  right: auto;
  left: calc(-1 * var(--flix-spacing-1));
  transform: none;
}
.flix-tooltip--bottom.flix-tooltip--end {
  right: calc(-1 * var(--flix-spacing-1));
  left: auto;
  transform: none;
}
.flix-tooltip-target {
  position: relative;
}
.flix-tooltip-target--inline {
  display: inline-block;
}

